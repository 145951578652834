/**
 * To get timestamp with leading zero
 * ex:
 *  with: 03, 05, 09, 12
 *  without: 3, 5, 9, 12
 * @returns {String} - return timestamp as string
 */
export const addLeadingZero = (timestamp: number): string => {
  return String(timestamp < 10 ? "0" + String(timestamp) : timestamp);
};

/**
 * To format timestamp in string
 * @returns {String} - return timestamp as string
 */
export const formatDateTime = (timestamp: Date): string => {
  const year = timestamp.getFullYear();
  const month = addLeadingZero(timestamp.getMonth() + 1);
  const date = addLeadingZero(timestamp.getDate());
  const hours = addLeadingZero(timestamp.getHours());
  const minutes = addLeadingZero(timestamp.getMinutes());
  const seconds = addLeadingZero(timestamp.getSeconds());

  return String(
    year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
  );
};
