import React from "react";
import DashboardRouter from "./DashboardRouter";
import ErrorBoundary from "./Shared/error-boundary/errorBoundary";

import "./Shared/common.module.scss";

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <DashboardRouter />
    </ErrorBoundary>
  );
};

export default App;
