import React from "react";
import successNew from "../../assets/images/SuccessNew.png";
import Styles from "./successPopup.module.scss";

function successPopup({ message }: any) {
  return (
    <div>
      <div className="py-3 text-center">
        <img
          src={successNew}
          alt="otp-verification"
          style={{ width: "100px" }}
        />
      </div>
      <div className="py-2 px-4">
        <p className={`mb-0 ${Styles.headingText}`}>{message}</p>
      </div>
    </div>
  );
}

export default successPopup;
