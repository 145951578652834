/**
 * Use this api to get articles: https://api.pichainlabs.com/v1/estamp/articles/GUJARAT
 */

export const stampDutyValues = [
  { label: 10, value: "10" },
  { label: 50, value: "50" },
  { label: 100, value: "100" },
];
export const considerationPrices = [
  { label: 10, value: "10" },
  { label: 50, value: "50" },
  { label: 100, value: "100" },
];

export const BranchData = [
  {
    branch_uuid: "c51c49fc-b485-4a98-ac87-09cffe217f00",
    name: "ANDHRA-PRADESH",
    display: false,
  },
  {
    branch_uuid: "b2aec117-502f-4649-add6-1fbdebf638b7",
    name: "ARUNACHAL-PRADESH",
    display: false,
  },
  {
    branch_uuid: "c86e8962-2163-4f53-9b7d-5956e4fedeb1",
    name: "ASSAM",
    display: false,
  },
  {
    branch_uuid: "afe39dfb-19bb-4bf4-a744-f31c90272322",
    name: "BIHAR",
    display: false,
  },
  {
    branch_uuid: "4861c0c5-262c-4d60-b79d-05dd54e59a6a",
    name: "CHHATTISGARH",
    display: false,
  },
  {
    branch_uuid: "6feb3737-7708-47b2-b035-9743e8a3d7cf",
    name: "GOA",
    display: false,
  },
  {
    branch_uuid: "ac701ef3-fc1b-4ccb-a058-1de671a910dc",
    name: "GUJARAT",
    display: true,
    articles: [
      "3 Adoption - Deed",
      "5(h) Agreement to Sale",
      "16 Cancellation",
      "17 Certificate of Sale",
      "20(a) Conveyance - Immovable Property",
      "20(b) Conveyance - Property of Co-Op Soc.",
      "20(c) Conveyance - Property of N.T.C, G.H.B., G.O.F.A.",
      "20(c) Conveyance - Property of N.T.C, G.H.B., G.O.F.A.",
      "20(d) Conveyance Amalgamation / Reconstruction of Company",
      "6(1) Deposit of Title Deeds",
      "26 Exchange of Property",
      "27(a) Further Charge - With Possession",
      "27(b) Further Charge - Without Possession",
      "28 Gift",
      "30(b) Lease - (Imm. Property) - Premium or Money Adv.",
      "30(c) Lease - (Imm. Property) - Rent + Premium or Money Adv.",
      "30(a) Lease - (Immovable Property) - Rent",
      "30 A Leave and License Agreement",
      "36(a) Mortgage Deed - With Possession",
      "36(b) Mortgage Deed - Without Possession",
      "43 Partitioned",
      "44(3)(a) Partnership - Dissolution",
      "6(2) Pledge, Loan or Hypothecation - Movable Property",
      "45(f) Power of Attorney - For Consideration - Sale of Imm. Property",
      "45 (e) Power of Attorney - More than 5 persons",
      "45 (c) Power of Attorney - Other than Article 45 (a)",
      "45 (a) Power of Attorney - Registration of Document - Single Transaction",
      "45 (b) Power of Attorney - Suits - Small Cause Courts Act.",
      "45 (d) Power of Attorney - Not More than 5 persons",
      "45 (g) Power of Attorney (Development)",
      "45 (h) Power of Attorney (in any other case)",
      "48 Reconveyance of Mortgaged Property",
      "49 Release",
      "52 A(ii) Settlement - Other Case",
      "52 A(i) Settlement - Religious or Charitable purpose",
      "52 B(ii) Settlement - Revocation - Other Case",
      "52 B(i) Settlement - Revocation - Religious or Charitable",
      "55 Surrender of Lease",
      "56(d) Transfer - Trustees or Beneficiary (Without - Consideration)",
      "56(b) Transfer - Interest Secured by Bond",
      "56(c) Transfer - Under the Administrators Gen. Act 1963, Sec. 22",
      "57 Transfer of Lease",
      "58(b) Trust - Revocation",
      "58(a) Trust - Declaration",
      "2 Administration Bond",
      "4 Affidavit",
      "5(g-a) Agreement - Construction / Development / Sale / Transfer (Imm. Property)",
      "5(gb) Agreement - Media Advertisement - Other than Newspapers",
      "5(e) Agreement - Purchase or Sale of Bullion, Spice",
      "5(d) Agreement - Purchase or Sale of Cotton",
      "5(b) Agreement - Purchase or Sale of Govt. Sec.",
      "5(f) Agreement - Purchase or Sale of Oil Seeds",
      "5(c) Agreement - Purchase or Sale of Share, Deb.",
      "5(g) Agreement - Purchase or Sale of Yarn",
      "5(a) Agreement - Sale of Bill of Exchange",
      "5(h) Agreement (not otherwise provided for)",
      "7 Alteration - Article of Association of a Company",
      "8 Alteration - Memorandum of Association of a Company",
      "9(b) Appointment in Execution of Power - Movable / Immovable",
      "9(a) Appointment in Execution of Power - Trustees",
      "10 Appraisement or Valuation",
      "11 Apprenticeship Deed",
      "12 Article of Association of Company",
      "13 Award",
      "14 Bond",
      "15 Bottomry Bond",
      "18 Certificate or Other Document",
      "18 C Clearance List - Bullion or Species",
      "18 B Clearance List - Cotton",
      "18 A(1) Clearance List - Govt. Sec.",
      "18 D Clearance List - Oil Seeds",
      "18 A(2) Clearance List - Shares, Debentures",
      "18 E Clearance List - Yarn",
      "19 Composition Deed",
      "20 (aa) Conveyance - Movable Property",
      "21 Copy or Extract",
      "22 Counter Part or Duplicate",
      "23 Customs Bond or Excise Bond",
      "24 Delivery Order in respect of Goods",
      "25 Divorce",
      "29 Indemnity Bond",
      "30 D Lease - Movable Property",
      "31 Letter of Allotment or Renunciation of Shares",
      "32 Letter of Guarantee",
      "33 Letter of License",
      "34 Marriage Registration",
      "35 Memorandum of Association of Company",
      "37 (b) Mortgage of Crop",
      "37 (a) Mortgage of Crop - Not more than 3 months",
      "38 Notarial Act",
      "41 Note of Protest by the Master of a Ship",
      "40 Note or Entry or Letter Written by Creditor",
      "39 (b) Note or Memorandum - Bullion / Specie",
      "39 (a) Note or Memorandum - Cotton",
      "39 (e) Note or Memorandum - Goods (other than Article 39 a,b,c,d)",
      "39 (g) Note or Memorandum - Govt. Security",
      "39 (c) Note or Memorandum - Oil Seeds",
      "39 (f) Note or Memorandum - Stocks / Debenture",
      "39 (d) Note or Memorandum - Yarn / Non Mineral Oils",
      "42 (b) Order for the Payment of Money - More than 1 year",
      "42 (a) Order for the Payment of Money - Not more than 1 year",
      "44 Partnership",
      "47 Protest by the Master of a Ship",
      "46 Protest of Bill or Note",
      "50 Respondentia Bond",
      "51 Security Bond or Mortgage - Deed",
      "53 Share Warrants",
      "56(a) Transfer of Debenture",
    ],
  },
  {
    branch_uuid: "b7af2b9b-234d-419e-8845-42c5025de446",
    name: "HARYANA",
    display: false,
  },
  {
    branch_uuid: "8cbcddb2-6936-4de7-a40a-e9534a727e62",
    name: "HIMACHAL-PRADESH",
    display: false,
  },
  {
    branch_uuid: "62e89d2c-e655-48d1-bf40-a0f696d72cb0",
    name: "JHARKHAND",
    display: false,
  },
  {
    branch_uuid: "89ad9c1d-17e1-43ba-8403-591726f35c13",
    name: "KARNATAKA",
    display: true,
    articles: [
      "Acknowledgement [1]",
      "Administration Bond [2]",
      "Adoption Deed [3]",
      "Affidavit [4]",
      "Agreement (In any other cases) [5(J)]",
      "Agreement relating to Deposit of Title Deed [6]",
      "Agreement relating to Sale of Immoveable property [5]",
      "Appointment in execution of a power [7]",
      "Appraisement or Valuation [8]",
      "Apprenticeship Deed [9]",
      "Article of Association [10]",
      "Award other than partition Award [11]",
      "Bond [12]",
      "Bottomry Bond [13]",
      "Cancellation of Instruments [14]",
      "Certificate [16]",
      "Certificate of Enrolment as Advocate [17]",
      "Certificate of Sale [15]",
      "Charter Party [18]",
      "Clearance List [18(A)]",
      "Composition Deed [19]",
      "Conveyance [20]",
      "Copy of a extract [21]",
      "Counter part or Duplicate [22]",
      "Customs Bond [23]",
      "Delivery order in respect of Goods [24]",
      "Dissolution(with immoveable property) [40(C)]",
      "Dissolution(without immoveable property) [40(C)]",
      "Divorce [25]",
      "Exchange of Property [26]",
      "Further Charge [27]",
      "Gift [28]",
      "Indemnity Bond [29]",
      "Lease of Immovable Property [30]",
      "Letter of Allotment [31]",
      "Letter of License [32]",
      "License of Immovable and Movable Property [32(A)]",
      "Memorandum of Association of a company [33]",
      "Mortgage Deed [34]",
      "Mortgage of a Crop [35]",
      "Notarial Act [36]",
      "Note of Protest by the Master of a ship [38]",
      "Partition [39]",
      "Partnership:(with immoveable property) [40(A)]",
      "Partnership:(without immoveable property) [40(A)]",
      "Power of Attorney [41]",
      "Protest by the Master of a Ship [43]",
      "Protest of Bill [42]",
      "Re-conveyance of Mortgage [44]",
      "Reconstitution(with immoveable property) [40(B)]",
      "Reconstitution(without immoveable property) [40(B)]",
      "Release [45]",
      "Respodentia Bond [46]",
      "Security Bond or Mortgage Deed [47]",
      "Settlement [48]",
      "Share Warrants [49]",
      "Shipping [50]",
      "Surrender of Lease [51]",
      "Transfer [52]",
      "Transfer of Lease [53]",
      "Transfer of License [53(A)]",
      "Trust [54]",
    ],
  },
  {
    branch_uuid: "f0c4a7ba-aa1f-4ba3-b437-c5c3f27b902c",
    name: "KERALA",
    display: false,
  },
  {
    branch_uuid: "bd62c3b6-1c09-4474-bb23-847ff7b096be",
    name: "MADHYA-PRADESH",
    display: false,
  },
  {
    branch_uuid: "7aa628e9-5b8c-48a7-9fb2-c2961acf1fa3",
    name: "MAHARASHTRA",
    display: true,
    articles: [
      "10-Article of Assocition of a Company",
      "13-Bond",
      "15-Cancellation(not of Conveyance)",
      "16-Certificate of Sale",
      "24-Composition Deed",
      "25(a)-Conveyance of movable property",
      "27-Connterpart or Duplicate",
      "3-Adoption Deed",
      "32-Exchange of Property",
      "33(a)-Further Charge(if org mortgage is with possession)",
      "33(b) (i)-Further Charge(if without possession)",
      "33(b) (ii)-Further Charge(if without possession)",
      "35-Indemnity Bond",
      "36-Lease for term exceeding one year",
      "37-Letter of allotment of Shares",
      "39-Memorandum of Association of a Company",
      "4-Affidavit 40(a)-mortgage deed(with possession)",
      "40(b)-mortgage deed(without possession)",
      "46-Partition",
      "47(1) (a)-Partnership if share contri. is not immovable prop",
      "47(1) (b)-Partnership if share contri. is immovable prop",
      "47(2) (a)-Dissolution of partnership if share is immovable prop",
      "47(2) (b)-Dissolution of partnership in other case",
      "48(a)-POA for admitting execution of deed in registration",
      "48(b)-POA for court purpose",
      "48(c)-POA authorising person/s for one transaction",
      "48(d)-POA authorising a person for more transaction",
      "48(e)-POA authorising person for transaction/s",
      "48(f)-Power of Atterony authorising to shell Immovable property",
      "48(f) (i)-POA with considarration and authorising to shell group",
      "48(f) (ii) (a)-POA without consid to faimly member to shell prop",
      "48(f) (ii) (b)-POA without consid to non faimly member to shell prop",
      "48(g)-POA authorizing to develop",
      "5(g-a) (ii)-Developement Agreement",
      "5 (g-d)-Agreement to transfer of tenancy rights",
      "5(g-e)-Agreement to hire purchase",
      "5(h) (A) (i)-Agreement realting to advertisement on mass media",
      "5(h) (A) (ii)-Agreement realting to right of telecasting",
      "5(h) (A) (iii)-Agreement realting to specific performance",
      "5(h) (A) (iv)-Agreement creation right and having monetary value",
      "5(h) (A) (v)-Agreement realting to Assignment of copyright",
      "5(h) (A) (vi)-Agreement realting to BOT Project",
      "5(h) (B) (vi)-Agreement if not otherwise provided for",
      "51-Reconveyance of mortgaged property",
      "52 (b)-Realse of ancestral property",
      "52 (b)-Realse not of ancestral property",
      "54-Security Bond",
      "55(A) (1)-Settlement for religious/charitable purpose",
      "58(a)-Surrender of Lease,Without Considration purpose",
      "58(b)-Surrender of Lease,With Considration",
      "6(1).1-Agreement to deposite of title deed",
      "6(1).2-Equitable Mortgage",
      "6(2)-hypothecation,pawn,pledge",
      "60-Transfer of Lease",
      "63-Work Contract",
      "A25-Conveyance/Sale/Transfer/Assignment Deed",
      "B25-Agreement to sale/Transfer/Assignment",
      "C25-Deed of Apartment",
      "D25-Cancellation os Sale Deed",
      "63-Works Contract",
      "29-Delivery order",
      "32-Exchange of Property",
      "33(a)-Futher Charge(if org mortgage is with possession)",
      "33(b)(i)-Futher Charge(if with possession)",
      "33(b)(ii)-Futher Charge(if without possession)",
      "36- lease for term exceeding one year",
      "40(a)-mortgage deed(with possession)",
      "40(b)-mortagage deed(ithout Possession)",
      "48(f)-Power of Attorney authorising to sell Immoveble property",
      "52(a)-Release of ancestral property",
      "52(b)-Release not of ancestral property",
      "55(A)(1)-Settlement for religious/charitable purpose",
      "58(a)-Surrender of Lease,Without consideration",
      "58(b)-Surrender of Lease, With Consideration",
      "6(1).1-Agreement to deposit of title deed",
      "60-Transfer of Lease A25-Conveyance/Sale/Transfer/Assignment Deed B25-Agreement to sale/Transfer/Assignment C25-Deed of Apartment D25-Cancellation of Sale Deed",
      "6(1).1-Agreement relating to Deposit of Title Deeds",
      "55(b)-Settlement for other Purpose",
      "12-Arbitration Award",
    ],
  },
  {
    branch_uuid: "6c4d808a-9688-4c5c-a93b-db9389288367",
    name: "MANIPUR",
    display: false,
  },
  {
    branch_uuid: "249be9af-baa9-4807-b9e2-36a306407e8b",
    name: "MEGHALAYA",
    display: false,
  },
  {
    branch_uuid: "b98bd236-33ff-4cef-9d3a-962794807209",
    name: "MIZORAM",
    display: false,
  },
  {
    branch_uuid: "f7904a67-740b-4d73-80b2-4f2a764cba5a",
    name: "NAGALAND",
    display: false,
  },
  {
    branch_uuid: "e0e76968-569e-4f88-b63c-329ea1cc1bb6",
    name: "ODISHA",
    display: false,
  },
  {
    branch_uuid: "6777dcb1-e066-469a-9466-ee0a87b489c5",
    name: "PUNJAB",
    display: false,
  },
  {
    branch_uuid: "d1466d6a-f2ad-4744-a991-2b54bad61c41",
    name: "RAJASTHAN",
    display: false,
  },
  {
    branch_uuid: "1f8ed698-7096-4be3-9260-f6aecc0a8111",
    name: "SIKKIM",
    display: false,
  },
  {
    branch_uuid: "6290c25b-369c-43a3-b68d-afe1f1baf5d8",
    name: "TAMIL-NADU",
    display: false,
  },
  {
    branch_uuid: "c709aebd-45b3-49bd-99a4-c265b80b4c5a",
    name: "TELANGANA",
    display: false,
  },
  {
    branch_uuid: "cbdb6278-f6cf-4f75-8feb-3fdbe89b6e0b",
    name: "TRIPURA",
    display: false,
  },
  {
    branch_uuid: "b35b6a4e-36bf-4599-9cb2-538bb2f9b96a",
    name: "UTTARAKHAND",
    display: false,
  },
  {
    branch_uuid: "5b7b0e05-11d2-4cd7-bca8-2b03d69d5dcc",
    name: "UTTAR-PRADESH",
    display: false,
  },
  {
    branch_uuid: "271d6702-7675-47bd-a8d3-915a0c6792bf",
    name: "WEST-BENGAL",
    display: false,
  },
  {
    branch_uuid: "0f9dba19-6801-43d1-8f15-25088182e4b8",
    name: "UNION-TERRITORIES",
    display: false,
  },
  {
    branch_uuid: "431badb6-c048-4ed8-837f-837c5db8af7d",
    name: "ANDAMAN-AND-NICOBAR-ISLANDS",
    display: false,
  },
  {
    branch_uuid: "0faa0b3a-733c-4023-890b-aa4248f503ea",
    name: "CHANDIGARH",
    display: false,
  },
  {
    branch_uuid: "d6026c5e-6488-4810-ab51-68a26ba737b8",
    name: "DADRA-AND-NAGAR-HAVELI-AND",
    display: false,
  },
  {
    branch_uuid: "c46b1238-7ab6-40ed-ad3f-46891a3ee26c",
    name: "DAMAN-&-DIU",
    display: false,
  },
  {
    branch_uuid: "bf944c1e-c4cf-4f77-9413-24eb4cff1bd0",
    name: "THE-GOVERNMENT-OF-NCT-OF-DELHI",
    display: true,
    articles: [
      "5(c) Rectification Deed- Immovable",
      "6(2)(a) Agreement relating to deposit of title deed",
      "6(2)(b) Agreement relating to deposit of title deed",
      "11(Sch-1A) Assignment of Debt",
      "12 Award",
      "12 Award Immovable",
      "17 Cancellation of Instrument",
      "18 Certificate of Sale",
      "23 Conveyance",
      "23 Sale",
      "23 Decree (Sale Deed/Conveyance)",
      "23-A Sale Agreement",
      "25 Additional Copy of document",
      "31 Exchange of property",
      "32(a) Further Charge",
      "32(b)(i) Further Charge",
      "32(b)(ii) Further Charge",
      "33 Gift",
      "35 Perpetual",
      "35(i) Lease- Rent deed upto 1 year",
      "35(ii) Lease upto 5 years",
      "35(iii) Lease upto 10 years",
      "35(iv) Lease upto 20 years",
      "35(v) Lease upto 30 years",
      "35(vi) Lease upto 100 years",
      "35(vii) Lease upto 200 years",
      "35(ii) Lease with security upto 5 years",
      "35(iii) Lease with security upto 10 years",
      "35(iv) Lease with security upto 20 years",
      "35(v) Lease with security upto 30 years",
      "35(vi) Lease with security upto 100 years",
      "35(vii) Lease with security upto 200 years",
      "40 Mortgage",
      "40(c) Mortgage Collateral",
      "41 Mortgage of a Crop",
      "45 Partition",
      "54 Re-conveyance",
      "54A Record of Transaction (Electronics or Otherwise) effected by a trading member (Broker Note)",
      "55 Relinquishment Deed",
      "55 Release",
      "57 Security Bond",
      "58 Settlement",
      "58 Decree (Settlement)",
      "58 Memorandum of Settlement",
      "61 Surrender of Lease",
      "63 Transfer of Lease",
      "64 Trust",
      "3 Adoption",
      "4 Declaration",
      "4 Affidavit",
      "5 General Agreement",
      "5 Consent Letter",
      "5(c) Rectification Deed- Movable",
      "11 Authority to Adopt",
      "12 Award Movable",
      "13 Bill of Exchange",
      "15 Indemnity Bond",
      "15 Bonds",
      "17 Cancellation of Agreement",
      "19 Certificate or other document (Shares)",
      "23 Conveyance (M&A of Companies under Amalgamation Scheme)",
      "27 Debenture",
      "29 Divorce",
      "46 Partnership",
      "47 Policy of Insurance",
      "48 Power of attorney SPA",
      "48 GPA-Blood Relation",
      "48(c) Power of attorney GPA",
      "48(d) Power of attorney GPA more than five persons jointly and severly",
      "48(e) Power of attorney GPA more than five persons but less than ten persons",
      "48(f) Power of attorney GPA with consideration",
      "48(g) Power of attorney Authenticated GPA",
      "53 Receipt",
      "55 Disclaimer",
      "56 Respondentia Bond",
      "59 Share Warrants",
      "62 Transfer (with or without consideration for Debentures & Company Shares)",
      "64(b) Revocation of Trust",
      "64(b) Dissolution Deed",
      "64(b) Deed of Retirement",
      "Undertaking",
      "Surety Bonds",
      "Bank Guarantee",
      "Guarantee Bond",
      "Car Loan/ Loan Agreement",
      "Transfer Deed",
      "L.C. (Letter Certificate)",
      "Forward Contract",
      "Bond",
      "Others",
      "Will",
      "Will After Death",
      "Will Medical Ground",
      "Will Sealed Cover",
      "Will with Cancellation",
    ],
  },
  {
    branch_uuid: "2d5a7b0d-7c3a-454c-a305-6afe3205070d",
    name: "JAMMU-&-KASHMIR",
    display: false,
  },
  {
    branch_uuid: "aa939a28-990b-401c-b820-f993794a07a7",
    name: "LADAKH",
    display: false,
  },
  {
    branch_uuid: "763f6f39-7397-46a9-b563-959dc7b6e1ab",
    name: "LAKSHADWEEP",
    display: false,
  },
  {
    branch_uuid: "a0aab095-5617-4029-9a79-6d46defd8a39",
    name: "PUDUCHERRY",
    display: false,
  },
  {
    branch_uuid: "28dab947-e744-45ad-828b-d8196c40bbf5",
    name: "MAHARASHTRA-2",
    display: false,
    articles: [
      "10-Article of Assocition of a Company",
      "13-Bond",
      "15-Cancellation(not of Conveyance)",
      "16-Certificate of Sale",
      "24-Composition Deed",
      "25(a)-Conveyance of movable property",
      "27-Connterpart or Duplicate",
      "3-Adoption Deed",
      "32-Exchange of Property",
      "33(a)-Further Charge(if org mortgage is with possession)",
      "33(b) (i)-Further Charge(if without possession)",
      "33(b) (ii)-Further Charge(if without possession)",
      "35-Indemnity Bond",
      "36-Lease for term exceeding one year",
      "37-Letter of allotment of Shares",
      "39-Memorandum of Association of a Company",
      "4-Affidavit 40(a)-mortgage deed(with possession)",
      "40(b)-mortgage deed(without possession)",
      "46-Partition",
      "47(1) (a)-Partnership if share contri. is not immovable prop",
      "47(1) (b)-Partnership if share contri. is immovable prop",
      "47(2) (a)-Dissolution of partnership if share is immovable prop",
      "47(2) (b)-Dissolution of partnership in other case",
      "48(a)-POA for admitting execution of deed in registration",
      "48(b)-POA for court purpose",
      "48(c)-POA authorising person/s for one transaction",
      "48(d)-POA authorising a person for more transaction",
      "48(e)-POA authorising person for transaction/s",
      "48(f)-Power of Atterony authorising to shell Immovable property",
      "48(f) (i)-POA with considarration and authorising to shell group",
      "48(f) (ii) (a)-POA without consid to faimly member to shell prop",
      "48(f) (ii) (b)-POA without consid to non faimly member to shell prop",
      "48(g)-POA authorizing to develop",
      "5(g-a) (ii)-Developement Agreement",
      "5 (g-d)-Agreement to transfer of tenancy rights",
      "5(g-e)-Agreement to hire purchase",
      "5(h) (A) (i)-Agreement realting to advertisement on mass media",
      "5(h) (A) (ii)-Agreement realting to right of telecasting",
      "5(h) (A) (iii)-Agreement realting to specific performance",
      "5(h) (A) (iv)-Agreement creation right and having monetary value",
      "5(h) (A) (v)-Agreement realting to Assignment of copyright",
      "5(h) (A) (vi)-Agreement realting to BOT Project",
      "5(h) (B) (vi)-Agreement if not otherwise provided for",
      "51-Reconveyance of mortgaged property",
      "52 (b)-Realse of ancestral property",
      "52 (b)-Realse not of ancestral property",
      "54-Security Bond",
      "55(A) (1)-Settlement for religious/charitable purpose",
      "58(a)-Surrender of Lease,Without Considration purpose",
      "58(b)-Surrender of Lease,With Considration",
      "6(1).1-Agreement to deposite of title deed",
      "6(1).2-Equitable Mortgage",
      "6(2)-hypothecation,pawn,pledge",
      "60-Transfer of Lease",
      "63-Work Contract",
      "A25-Conveyance/Sale/Transfer/Assignment Deed",
      "B25-Agreement to sale/Transfer/Assignment",
      "C25-Deed of Apartment",
      "D25-Cancellation os Sale Deed",
      "63-Works Contract",
      "29-Delivery order",
      "32-Exchange of Property",
      "33(a)-Futher Charge(if org mortgage is with possession)",
      "33(b)(i)-Futher Charge(if with possession)",
      "33(b)(ii)-Futher Charge(if without possession)",
      "36- lease for term exceeding one year",
      "40(a)-mortgage deed(with possession)",
      "40(b)-mortagage deed(ithout Possession)",
      "48(f)-Power of Attorney authorising to sell Immoveble property",
      "52(a)-Release of ancestral property",
      "52(b)-Release not of ancestral property",
      "55(A)(1)-Settlement for religious/charitable purpose",
      "58(a)-Surrender of Lease,Without consideration",
      "58(b)-Surrender of Lease, With Consideration",
      "6(1).1-Agreement to deposit of title deed",
      "60-Transfer of Lease A25-Conveyance/Sale/Transfer/Assignment Deed B25-Agreement to sale/Transfer/Assignment C25-Deed of Apartment D25-Cancellation of Sale Deed",
      "6(1).1-Agreement relating to Deposit of Title Deeds",
      "55(b)-Settlement for other Purpose",
      "12-Arbitration Award",
    ],
  },
];
