import React from "react";

function arrowRightSVG() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.455 16.4658C6.20667 16.2225 6.18417 15.8408 6.3875 15.5725L6.45417 15.4958L12.0617 10L6.45583 4.50418C6.2075 4.26084 6.185 3.87918 6.38833 3.61084L6.455 3.53417C6.57478 3.41748 6.7319 3.34681 6.89868 3.33461C7.06546 3.32242 7.23119 3.36948 7.36667 3.46751L7.445 3.53417L13.5458 9.51501C13.6642 9.62999 13.7364 9.78432 13.7489 9.94888C13.7613 10.1134 13.7131 10.2769 13.6133 10.4083L13.5467 10.485L7.445 16.4658C7.3125 16.5945 7.13509 16.6664 6.95042 16.6664C6.76574 16.6664 6.58833 16.5945 6.45583 16.4658H6.455Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}

export default arrowRightSVG;
