import React from "react";

function DocsSVGnew(classes: any) {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path
        d="M12.8 6.052C12.7917 5.97851 12.7756 5.9061 12.752 5.836V5.764C12.7135 5.68174 12.6622 5.60613 12.6 5.54L7.8 0.74C7.73387 0.677773 7.65826 0.626465 7.576 0.588H7.504C7.42273 0.541393 7.33298 0.511475 7.24 0.5H2.4C1.76348 0.5 1.15303 0.752856 0.702944 1.20294C0.252856 1.65303 0 2.26348 0 2.9V14.1C0 14.7365 0.252856 15.347 0.702944 15.7971C1.15303 16.2471 1.76348 16.5 2.4 16.5H10.4C11.0365 16.5 11.647 16.2471 12.0971 15.7971C12.5471 15.347 12.8 14.7365 12.8 14.1V6.1C12.8 6.1 12.8 6.1 12.8 6.052ZM8 3.228L10.072 5.3H8.8C8.58783 5.3 8.38434 5.21571 8.23431 5.06569C8.08429 4.91566 8 4.71217 8 4.5V3.228ZM11.2 14.1C11.2 14.3122 11.1157 14.5157 10.9657 14.6657C10.8157 14.8157 10.6122 14.9 10.4 14.9H2.4C2.18783 14.9 1.98434 14.8157 1.83431 14.6657C1.68429 14.5157 1.6 14.3122 1.6 14.1V2.9C1.6 2.68783 1.68429 2.48434 1.83431 2.33431C1.98434 2.18429 2.18783 2.1 2.4 2.1H6.4V4.5C6.4 5.13652 6.65286 5.74697 7.10294 6.19706C7.55303 6.64714 8.16348 6.9 8.8 6.9H11.2V14.1Z"
        fill="#5E5E5E"
      />
    </svg>
  );
}

export default DocsSVGnew;
