// Connecting all auth,onboarding and application components together.
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./components/auth/login/login";
import SignUp from "./components/auth/sign-up/signUp";
import ForgotPassword from "./components/auth/forgot-password/forgotPassword";
import NotFound404 from "./Shared/notFound404";
import EmailVerification from "./components/auth/email-verification/emailVerification";
import CreatePassword from "./components/onboarding/create-password/createPassword";
import AppSettings from "./components/application/manage-apps/app-settings/app-settings";
import ActivationForm from "./components/application/activation/activationForm";
import Application from "./components/application/application";
import AppCreationSuccess from "./components/onboarding/app-creation-success/appCreationSuccess";
import MyAccount from "./components/application/my-account/myAccount";
import Onboarding from "./components/onboarding/onboarding";
import EmailVerificationFailed from "./components/auth/email-verification-failed/emailVerificationFailed";
import ResendEmailSuccess from "./components/auth/email-verification-failed/resend-email-success/resendEmailSuccess";
import OnBoardingSidebarContextProvider from "./utils/onboardingSidebarContext";
import ProtectedRoutes from "./protectedRoutes";
import PasswordResetLinkExpiry from "./components/auth/password-reset-link-expiry/passwordResetLinkExpiry";
import PasswordResetLinkSuccess from "./components/auth/password-reset-link-expiry/password-reset-success/passwordResetLinkSuccess";
import Docs from "./components/application/docs/docs";

class DashboardRouter extends React.Component {
  render() {
    return (
      <OnBoardingSidebarContextProvider>
        <Router>
          <Switch>
            <Route
              path={"/"}
              exact
              component={() => <Redirect to={"/login"} />}
            />
            <Route path={"/login"} component={Login} />
            <Route path={"/sign-up"} component={SignUp} />
            <Route path={"/forgot-password"} component={ForgotPassword} />
            <Route path={"/email-verification"} component={EmailVerification} />
            <Route
              path={"/password-reset-link-expiry"}
              component={PasswordResetLinkExpiry}
            />
            <Route
              path={"/password-reset-link-success"}
              component={PasswordResetLinkSuccess}
            />
            <Route
              path={"/email-verification-failed"}
              component={EmailVerificationFailed}
            />
            <Route
              path={"/resend-email-success"}
              component={ResendEmailSuccess}
            />
            <ProtectedRoutes
              path={"/onboarding/:value"}
              component={Onboarding}
            />
            <Route
              exact
              path={"/onboarding"}
              component={() => <Redirect to="/onboarding/create-org" />}
            />
            <Route path={"/create-password"} component={CreatePassword} />
            <ProtectedRoutes path={"/welcome"} component={AppCreationSuccess} />
            <ProtectedRoutes
              path="/application/manage-apps/:appId/settings"
              component={AppSettings}
            />
            <ProtectedRoutes
              path={"/application/:value"}
              component={Application}
            />
            <Route
              exact
              path={"/application"}
              component={() => <Redirect to="/application/dashboard" />}
            />
            <ProtectedRoutes path="/my-account" component={MyAccount} />
            <ProtectedRoutes
              path="/activation-form"
              component={ActivationForm}
            />
            <ProtectedRoutes component={Docs} path="/doc" />

            <Route component={NotFound404} />
          </Switch>
        </Router>
      </OnBoardingSidebarContextProvider>
    );
  }
}

export default DashboardRouter;
