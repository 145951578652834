export const chartOptions: any = {
  maintainAspectRatio: false,
  responsive: true,
  // animation,
  interaction: {
    mode: "point",
  },
  scales: {
    x: {
      border: {
        color: "#A5A5A5",
        width: 3,
      },
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      border: {
        color: "#A5A5A5",
        width: 3,
      },
      ticks: {
        display: false,
      },
    },
  },
  hover: {
    mode: "index",
    intersect: false,
  },
  layout: {
    padding: {
      left: -30,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  elements: {},
  plugins: {
    title: {
      display: false,
      text: "Cryptocurrency prices",
    },
    legend: {
      display: false,
      position: "bottom",
    },
  },
};
