import React from "react";
import WarningIllustration from "../../../../../../assets/images/Warning.svg";
import Styles from "../../../../../../Shared/popup.module.scss";
import classes from "../../../../billing/downgrade-warning-pop-up/downgradeWarningPopUp.module.scss";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../../../Shared/buttonStyles";
import Button from "../../../../../../Shared/button/button";
import { MEDIUM } from "../../../../../../Shared/buttonSize";

function DisableConfirmationPopUp({ onConfirm, onCancel, isLoading }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        <div className="py-2 text-center">
          <img
            src={WarningIllustration}
            alt="warning-illustration"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 text-center">
          <p className={`mb-0 ${classes.headingText}`}>
            Disable IP whitelisting
          </p>
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${classes.subText}`}>
            Ensure API key security maintained correctly to avoid any misuse.
            Allowing all IPs to transact through your system is not recommended.
          </p>
        </div>
        <div
          className="pt-3 d-flex justify-content-around px-4"
          style={{ margin: "0 auto" }}
        >
          <Button
            hoveredStyle={primaryButtonHoverStyle}
            style={primaryButtonStyle}
            isLoading={isLoading}
            size={MEDIUM}
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              className={`mb-0 ${classes.cancelText}`}
              onClick={() => onCancel()}
            >
              Cancel
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisableConfirmationPopUp;
