import React, { useEffect, useState } from "react";
import CommonStyles from "../shared.module.scss";
import Styles from "./input.module.scss";
import Information from "../svg/information";
import Tooltip from "../tooltip/tooltip";
import CrossSvg from "../svg/cross";

function Input({
  hasError,
  isValid = null,
  tooltipMessage = null,
  showInfo = false,
  requiredAstrict,
  customInputClass,
  name,
  value,
  onChange,
  clear = true,
  isMandatory = false,
  ...props
}: any) {
  const [inputValue, setInputValue] = useState<any>(value || "");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div>
      {props.labelname && (
        <label
          htmlFor={props.labelName}
          className={`${CommonStyles.formLabel} `}
        >
          <div
            className={`d-flex align-items-center ${
              requiredAstrict ? Styles.required : null
            }`}
          >
            {props.labelname}
            {isMandatory && (
              <span className={`${Styles.mandatoryField}`}>*</span>
            )}
            {showInfo ? (
              <div className="px-2">
                <Tooltip placement="right" title={tooltipMessage}>
                  <div
                    className={`d-flex align-items-center justify-content-center ${Styles.InfoIconBack}`}
                  >
                    <Information infoClass={Styles.infoIcon} />
                  </div>
                </Tooltip>
              </div>
            ) : null}
          </div>
        </label>
      )}
      <div className={"position-relative"}>
        <input
          className={`${CommonStyles.commonInputClass} ${
            hasError !== ""
              ? CommonStyles.error
              : isValid
              ? CommonStyles.valid
              : CommonStyles.formControl
          } ${props.noshadow && "shadow-none"} ${customInputClass || ""}`}
          name={name}
          value={value || inputValue}
          onChange={(e) => {
            onChange(e);
            setInputValue(e.target.value);
          }}
          {...props}
          id={"z-input-" + name}
        />
        <div className={Styles.indicator}>
          {inputValue?.length && clear ? (
            <div
              className="px-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                onChange({ target: { value: "" } });
                setInputValue("");
              }}
            >
              <CrossSvg width="10" height="10" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Input;
