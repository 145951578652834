import React from "react";
import Styles from "./loading.module.scss";
import { WHITE } from "../colors";
function Loading({ loadingColor, width = "14px", height = "14px" }: any) {
  const loadingDefault = WHITE;
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100%" }}
    >
      <div
        className={Styles.dot1}
        style={{
          background: loadingColor ? loadingColor : loadingDefault,
          width: width,
          height: height,
        }}
      ></div>
      <div
        className={Styles.dot2}
        style={{
          background: loadingColor ? loadingColor : loadingDefault,
          width: width,
          height: height,
        }}
      ></div>
      <div
        className={Styles.dot3}
        style={{
          background: loadingColor ? loadingColor : loadingDefault,
          width: width,
          height: height,
        }}
      ></div>
      <div
        className={Styles.dot4}
        style={{
          background: loadingColor ? loadingColor : loadingDefault,
          width: width,
          height: height,
        }}
      ></div>
      <div
        className={Styles.dot5}
        style={{
          background: loadingColor ? loadingColor : loadingDefault,
          width: width,
          height: height,
        }}
      ></div>
    </div>
  );
}

export default Loading;
