import React from "react";
import Styles from "./informativeText.module.scss";
function InformativeText(props: any) {
  return (
    <div style={{ width: "400px" }}>
      <p className={`${Styles.brandText} mb-0 py-2`}>{props.headertext}</p>
      <p className={`${Styles.bodyText} mb-0 py-3`}>{props.bodytext}</p>
    </div>
  );
}

export default InformativeText;
