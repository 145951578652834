import React from "react";

function tickSVGnew() {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 59 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1509_771)">
        <path
          d="M48.6161 10.875L23.0804 36.875L10.3126 23.875C8.10275 21.625 4.54248 21.625 2.33266 23.875C0.122838 26.125 0.122838 29.75 2.33266 32L19.1519 49.125C20.2568 50.25 21.73 50.75 23.2032 50.75C24.6764 50.75 26.1496 50.25 27.2545 49.125L56.8416 19C59.0514 16.75 59.0514 13.125 56.8416 10.875C54.509 8.625 50.826 8.625 48.6161 10.875Z"
          fill="url(#paint0_linear_1509_771)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1509_771"
          x1="1"
          y1="30"
          x2="58"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.036" stopColor="#0075F3" />
          <stop offset="0.505208" stopColor="#4A9EF9" />
          <stop offset="1" stopColor="#9ECDFF" />
        </linearGradient>
        <clipPath id="clip0_1509_771">
          <rect width="58.9286" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default tickSVGnew;
