import React from "react";
import EmptyState from "./empty-state/emptyState";
import emptyStateStyle from "../Shared/empty-state/emptyState.module.scss";
import { BACKGROUNDCOLOR } from "./colors";
import Button from "./button/button";
import { LARGE } from "./buttonSize";
import { primaryButtonHoverStyle, primaryButtonStyle } from "./buttonStyles";
import { useHistory } from "react-router";

function NotFound404() {
  const history = useHistory();
  return (
    <div style={{ backgroundColor: BACKGROUNDCOLOR, height: "100vh" }}>
      <EmptyState type="404-not-found">
        <div className={"py-2"}>
          <p className={`${emptyStateStyle.emptyStateHeadingText} mb-0`}>
            404 Error Occurred
          </p>
        </div>
        <div className="py-2">
          <p
            className={`mb-0 py-2 ${emptyStateStyle.emptyStateBodyText}`}
            style={{ fontSize: "18px" }}
          >
            PAGE NOT FOUND
          </p>
          <p className={`mb-0 ${emptyStateStyle.emptyStateBodyText}`}>
            The page you are looking for might have been removed, renamed or
            temporarily unavailable.
          </p>
        </div>
        <div className="py-4 text-center">
          <Button
            size={LARGE}
            style={primaryButtonStyle}
            hoveredStyle={primaryButtonHoverStyle}
            onClick={() => history.replace("/application")}
          >
            Go To Dashboard
          </Button>
        </div>
      </EmptyState>
    </div>
  );
}

export default NotFound404;
