export const DASHBOARD = "Dashboard";
export const REPORTS = "Reports";
export const MYACCOUNT = "My Account";
export const MANAGEAPPS = "Apps";
export const PRODUCTCATALOG = "Products";
export const BILLING = "Billing";
export const DOCS = "Docs";
export const SUPPORT = "Support";
export const SETTINGS = "Settings";
export const BATCHSERVICE = "Batch Service";
export const TRIALCENTER = "Trial Center";
