import React from "react";

function reportsSVGNew(classes: any) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4113 10.073C24.58 4.95674 20.2344 1.15054 15.0532 1.00071C14.8069 0.991056 14.5668 1.07975 14.3859 1.24725C14.205 1.41474 14.0982 1.64729 14.0889 1.89365V1.97699L14.6723 10.704C14.7105 11.2914 15.2156 11.738 15.8033 11.7041L24.5541 11.1207C24.8007 11.1024 25.0298 10.9866 25.1907 10.7989C25.3516 10.6112 25.431 10.367 25.4113 10.1206V10.073Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99308 6.19165C9.54973 6.06289 10.1194 6.34771 10.3503 6.87028C10.4109 6.99319 10.4472 7.12657 10.4575 7.26318C10.5766 8.95381 10.8266 12.6565 10.9695 14.6567C10.9938 15.017 11.1608 15.3527 11.4334 15.5896C11.706 15.8264 12.0618 15.9449 12.422 15.9187L19.7679 15.4663C20.0958 15.4466 20.4173 15.5633 20.6563 15.7887C20.8953 16.0142 21.0305 16.3283 21.0299 16.6569C20.7322 21.0928 17.5451 24.8012 13.2044 25.7624C8.86367 26.7236 4.40895 24.7075 2.26629 20.812C1.62667 19.6908 1.2216 18.4514 1.07571 17.1688C1.01334 16.7752 0.989412 16.3765 1.00427 15.9782C1.01691 11.2411 4.34386 7.15933 8.98118 6.19165"
      />
      <path
        d="M8.99308 6.19165C9.54973 6.06289 10.1194 6.34771 10.3503 6.87028C10.4109 6.99319 10.4472 7.12657 10.4575 7.26318C10.5766 8.95381 10.8266 12.6565 10.9695 14.6567C10.9938 15.017 11.1608 15.3527 11.4334 15.5896C11.706 15.8264 12.0618 15.9449 12.422 15.9187L19.7679 15.4663C20.0958 15.4466 20.4173 15.5633 20.6563 15.7887C20.8953 16.0142 21.0305 16.3283 21.0299 16.6569C20.7322 21.0928 17.5451 24.8012 13.2044 25.7624C8.86367 26.7236 4.40895 24.7075 2.26629 20.812C1.62667 19.6908 1.2216 18.4514 1.07571 17.1688C1.01334 16.7752 0.989412 16.3765 1.00427 15.9782C1.01691 11.2411 4.34386 7.15933 8.98118 6.19165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default reportsSVGNew;
