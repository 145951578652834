import React from "react";
import JSONPretty from "react-json-pretty";
import Styles from "./responseBox.module.scss";

function ResponseBox(props: any) {
  const themes = {
    main: "font-size: 14px",
  };
  const responseBoxRef = React.useRef<any>("");

  async function copyText() {
    const response = responseBoxRef.current.innerText;
    await navigator.clipboard.writeText(response);
    props.toastTimeout(true, "success", "Copied!");
  }

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.headingText}`}>Result</div>
      <hr />
      <div className={`${Styles.responseContainer} d-flex flex-column`}>
        <div className="d-flex justify-content-end">
          {!props.errorResponse && (
            <button
              type="button"
              className={`${Styles.copyButton}`}
              onClick={copyText}
            >
              Copy
            </button>
          )}
        </div>
        <div className="mr-4 mt-2" ref={responseBoxRef}>
          {props.errorResponse ? (
            <p style={{ color: "#D64747" }}>
              {props.errorResponse + " "}
              {props.errorResponse.includes("wallet balance") && (
                <a href="#">sales@zoop.one</a>
              )}
            </p>
          ) : (
            <div className={`${Styles.response}`}>
              <JSONPretty
                id="json-pretty"
                theme={themes}
                data={props.apiResponse}
              />
            </div>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}

export default ResponseBox;
