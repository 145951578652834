import React, { useState } from "react";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import Select from "react-select";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import {
  checkGSTIN,
  checkPAN,
  checkUnregisteredGST,
} from "../../../../../utils/validation";
import { contactInfoParams } from "../../../../../constants/data";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import { getFinancialYears } from "../../../../../utils/getFinancialYears";

function GstinPopup({ handleResponse, name }: any) {
  const serviceName = name.replaceAll(" ", "").toLowerCase();
  const gstNumberRef = React.useRef<any>("");
  const errorObject = {
    gstNumberError: false,
    financialYearError: false,
    contactInfoError: false,
    consentError: false,
  };

  const financialYears = getFinancialYears(2018);

  const checkerFunction = serviceName === "gstpan" ? checkPAN : checkGSTIN;

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [financialYear, setFinancialYear] = useState("");
  const [contactInfo, setContactInfo] = useState(true);

  function isValid() {
    if (
      serviceName === "gstpan" &&
      checkPAN(gstNumberRef.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    } else if (
      serviceName === "gstunregistered" &&
      checkUnregisteredGST(gstNumberRef.current.value.trim().toUpperCase())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    } else if (checkGSTIN(gstNumberRef.current.value.trim().toUpperCase())) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    setError(() => ({
      ...error,
      gstNumberError: true,
    }));
    return setIsDisabled(true);
  }

  function handleCheck() {
    const gstNumber = gstNumberRef.current.value.toUpperCase();
    const consent = isChecked === true ? "Y" : "N";

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    if (serviceName === "gstverificationadvance")
      handleResponse(
        { gstNumber, financialYear, contactInfo, consent },
        setLoading
      );
    else handleResponse({ gstNumber, consent }, setLoading);
  }

  let productName = "";
  if (serviceName === "gstpan") {
    productName = "Business PAN Number";
  } else if (serviceName === "gstunregistered") {
    productName = "Unregister GST Number";
  } else {
    productName = "GSTIN Number";
  }

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              {productName}
            </label>
            <InputField
              id="id-input-gstin"
              onChange={isValid}
              maxLength={serviceName === "gstpan" ? 10 : 15}
              inputRef={gstNumberRef}
              isError={error.gstNumberError}
              purpose="forGst"
            />
            {error.gstNumberError && (
              <ErrorMessage>{`Please enter a valid ${productName}`}</ErrorMessage>
            )}
          </div>
          {serviceName === "gstverificationadvance" && (
            <div className="d-flex">
              <div className="d-flex flex-column mr-5">
                <label className={`${Styles.label} flex-grow-1`}>
                  Financial Year
                </label>
                <Select
                  id="id-input-year"
                  options={financialYears}
                  styles={dropDownStyles.customStylesForFinancialYears}
                  onChange={(e: any) => {
                    setFinancialYear(e.value);
                  }}
                />
                {error.financialYearError && (
                  <ErrorMessage>Please provide financial Year!</ErrorMessage>
                )}
              </div>
              <div className="d-flex flex-column">
                <label className={`${Styles.label} flex-grow-1`}>
                  Contact Info
                </label>
                <Select
                  id="id-input-contact-gstin"
                  options={contactInfoParams}
                  styles={dropDownStyles.customStylesForFinancialYears}
                  onChange={(e: any) => setContactInfo(e.value)}
                />
              </div>
            </div>
          )}
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-gstin"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GstinPopup;
