import React, { ReactElement } from "react";
import Styles from "../dashboard.module.scss";

type WarningProps = {
  children: ReactElement<"p">; // Enforces that children must be a <p> element
};

function WarningBar({ children }: WarningProps) {
  return (
    <div className={`${Styles.warningBar}`}>
      <p className="m-0 p-0">
        <b>Alert: </b>
        {children}
      </p>
    </div>
  );
}

export default WarningBar;
