import React, { useEffect, useState } from "react";
import InputDropdown from "../../../../Shared/input-dropdown/inputDropdown";
import Styles from "../dashboard.module.scss";
import { chartOptions } from "../common";
import { callApi } from "../../../../api/fetch";
import InsightsCard from "../insights-card/insightsCard";
import { durationOptions } from "../../../../constants/data";
import { Chart as ChartJS, registerables, ScriptableContext } from "chart.js";
import { Chart } from "react-chartjs-2";
import CustomDatePicker from "../../../../Shared/custom-date-picker/customDatePicker";
import info from "../../../../assets/images/info.svg";
import moment from "moment";
import { ACCENTCOLOR, SUCCESS } from "../../../../Shared/colors";
ChartJS.register(...registerables);

function ProductsUsage({
  setLatestApprovedProduct,
  preSelectedDatePreference,
}: any) {
  const [productOptions, setProductOptions] = useState<any>([]);
  const org_id = localStorage.getItem("organisation_id");
  const user: any = localStorage.getItem("@user");
  const x_user_id = JSON.parse(user)._id;

  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [displayInfo, setDisplayInfo] = useState(false);
  const [customDurationRange, setCustomDurationRange] = useState({
    to: {
      day: "",
      month: "",
      year: "",
    },
    from: {
      day: "",
      month: "",
      year: "",
    },
  });

  const [selectedDatePreference, setSelectedDatePreference] = useState(
    preSelectedDatePreference
  );
  const [selectedProduct, setSelectedProduct] = useState({
    label: "All Products",
    value: "",
  });

  const [startDate, setStartDate] = useState<any>(
    moment(new Date(moment().subtract(30, "days").calendar())).format(
      "YYYY/MM/DD"
    )
  );
  const [endDate, setEndDate] = useState<any>(moment().format("YY/MM/DD"));
  const [prevStartDate, setPrevStartDate] = useState<any>("NA");
  const [prevEndDate, setPrevEndDate] = useState<any>("NA");

  const [prevTranxnVolume, setPrevTranxnVolume] = useState(0);
  const [currentTranxnVolume, setCurrentTranxnVolume] = useState(0);

  const [chartDataPrev, setChartDataPrev] = useState<any>({
    type: "line",
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#0075F3",
        borderWidth: 2,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(0, 117, 243, 0.9)");
          gradient.addColorStop(0.5, "rgba(0, 117, 243, 0.5)");
          gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
          return gradient;
        },
        fill: true,
        pointRadius: 0,
        label: "Previous Month",
      },
    ],
  });

  const [chartDataCurrent, setChartDataCurrent] = useState<any>({
    type: "line",
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: "#0075F3",
        borderWidth: 2,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, "rgba(0, 117, 243, 0.9)");
          gradient.addColorStop(0.5, "rgba(0, 117, 243, 0.5)");
          gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
          return gradient;
        },
        fill: true,
        pointRadius: 0,
        label: "Current Month",
      },
    ],
  });

  useEffect(() => {
    async function getAllProductsForOrg() {
      const res = await callApi(`/product?subscription_status=subscribed`, {
        method: "GET",
      });

      setProductOptions([
        { label: "All Products", value: "" },
        ...[
          ...res.map((product: any) => ({
            label: product?.name,
            value: product?.arn,
          })),
        ],
      ]);

      const temp = res
        // {KEEP THIS COMMENT} filtering will remove pre-approved products from the list
        // .filter((product: any) => !product.auto_approval)
        .sort((a: any, b: any) => b.updatedAt - a.updatedAt)[0];
      setLatestApprovedProduct(temp?.name);
    }

    try {
      getAllProductsForOrg();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    async function getCurrentTransactions() {
      try {
        let start_date, end_date, prev_start_date, prev_end_date;
        switch (selectedDatePreference) {
          case "Last 7 days": {
            start_date = moment(
              new Date(moment().subtract(7, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            prev_start_date = moment(
              new Date(moment().subtract(14, "days").calendar())
            ).format("YYYY-MM-DD");
            prev_end_date = start_date;
            break;
          }
          case "Last 30 days": {
            start_date = moment(
              new Date(moment().subtract(30, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            prev_start_date = moment(
              new Date(moment().subtract(60, "days").calendar())
            ).format("YYYY-MM-DD");
            prev_end_date = start_date;
            break;
          }
          case "Last 60 days": {
            start_date = moment(
              new Date(moment().subtract(60, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            prev_start_date = moment(
              new Date(moment().subtract(120, "days").calendar())
            ).format("YYYY-MM-DD");
            prev_end_date = start_date;
            break;
          }
          case "Last 120 days": {
            start_date = moment(
              new Date(moment().subtract(120, "days").calendar())
            ).format("YYYY-MM-DD");
            end_date = moment(new Date()).format("YYYY-MM-DD");
            prev_start_date = moment(
              new Date(moment().subtract(240, "days").calendar())
            ).format("YYYY-MM-DD");
            prev_end_date = start_date;
            break;
          }
          case "Custom": {
            if (!customDurationRange.from.day && !customDurationRange.to.day)
              return;

            start_date = `${customDurationRange.from.year}-${customDurationRange.from.month}-${customDurationRange.from.day}`;
            end_date = `${customDurationRange.to.year}-${customDurationRange.to.month}-${customDurationRange.to.day}`;
            const diff = moment(end_date, "YYYY-MM-DD").diff(
              moment(start_date, "YYYY-MM-DD"),
              "days"
            );
            prev_start_date = moment(
              moment(start_date, "YYYY-MM-DD").subtract(diff, "days")
            ).format("YYYY-MM-DD");
            prev_end_date = moment(
              moment(end_date, "YYYY-MM-DD").subtract(diff, "days")
            ).format("YYYY-MM-DD");
            break;
          }
        }

        setStartDate(moment(start_date).format("DD/MM/YY"));
        setEndDate(moment(end_date).format("DD/MM/YY"));
        setPrevStartDate(moment(prev_start_date).format("DD/MM/YY"));
        setPrevEndDate(moment(prev_end_date).format("DD/MM/YY"));

        const selected_product = selectedProduct.value ?? "";

        const [prevDurationData, currentDurationData] = await Promise.all([
          callApi(
            `/organisation/${org_id}?action=get_org_product_usage&${
              selected_product && `arn=${selected_product}&`
            }start_date=${prev_start_date}&end_date=${prev_end_date}`,
            {
              method: "GET",
              init: {
                headers: {
                  "x-user-id": x_user_id,
                },
              },
            }
          ),
          callApi(
            `/organisation/${org_id}?action=get_org_product_usage&${
              selected_product && `arn=${selected_product}&`
            }start_date=${start_date}&end_date=${end_date}`,
            {
              method: "GET",
              init: {
                headers: {
                  "x-user-id": x_user_id,
                },
              },
            }
          ),
        ]);

        const currentVolume = currentDurationData.reduce(
          (curr: any, prev: any) => (curr += prev.transaction_count),
          0
        );
        const prevVolume = prevDurationData.reduce(
          (curr: any, prev: any) => (curr += prev.transaction_count),
          0
        );
        setCurrentTranxnVolume(currentVolume);
        setPrevTranxnVolume(prevVolume);

        setChartDataPrev({
          type: "line",
          labels: currentDurationData.map((tracxn: any) => tracxn.date),
          datasets: [
            {
              data: prevDurationData.map(
                (tracxn: any) => tracxn.transaction_count
              ),
              borderColor: SUCCESS,
              borderWidth: 2,
              backgroundColor: (context: ScriptableContext<"line">) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                gradient.addColorStop(0, "rgba(15, 157, 88, 0.7)");
                gradient.addColorStop(0.5, "rgba(15, 157, 88, 0.5)");
                gradient.addColorStop(1, "rgba(255,255,255, 0)");
                return gradient;
              },
              fill: true,
              pointRadius: 2,
            },
          ],
        });

        setChartDataCurrent({
          type: "line",
          labels: currentDurationData.map((tracxn: any) => tracxn.date),
          datasets: [
            {
              data: currentDurationData.map(
                (tracxn: any) => tracxn.transaction_count
              ),
              borderColor: ACCENTCOLOR,
              borderWidth: 2,
              backgroundColor: (context: ScriptableContext<"line">) => {
                const ctx = context.chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                gradient.addColorStop(0, "rgba(0, 117, 243, 1)");
                gradient.addColorStop(0.5, "rgba(0, 117, 243, 0.5)");
                gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
                return gradient;
              },
              fill: true,
              pointRadius: 2,
            },
          ],
        });
      } catch (error) {
        console.log(error);
      }
    }

    try {
      if (selectedDatePreference === "Custom" && showCustomDatePicker) return;
      getCurrentTransactions();
    } catch (error) {
      console.log(error);
    }
  }, [selectedDatePreference, selectedProduct, customDurationRange]);

  return (
    <>
      {showCustomDatePicker && (
        <CustomDatePicker
          setShowCustomDatePicker={setShowCustomDatePicker}
          maxDateDifference={120}
          setCustomDurationRange={(data: any) => {
            setCustomDurationRange(data);
            setShowCustomDatePicker(false);
          }}
        />
      )}
      <div className={`${Styles.card} d-flex flex-column pt-3 pl-4 pr-4 mb-2`}>
        <div className={Styles.cardContainer}>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column mr-5">
              <div className="d-flex align-items-center">
                <p className={Styles.cardHeader}>Product Usage</p>
                <img
                  src={info}
                  alt="Info"
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "3px",
                    marginBottom: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDisplayInfo(true);
                    setTimeout(() => setDisplayInfo(false), 3000);
                  }}
                />
                <>
                  {displayInfo && (
                    <i className={Styles.infoText}>
                      Data was updated approx. 2hr back
                    </i>
                  )}
                </>
              </div>
              <div className="d-flex mb-2">
                <div className="d-flex flex-column flex-grow-1 mr-3 mb-2">
                  <p className={Styles.dropDownHeader}>Duration</p>
                  <InputDropdown
                    label="Duration"
                    style={{ width: "160px" }}
                    no_shadow="true"
                    forDashboard={true}
                    placeholder={preSelectedDatePreference}
                    optionsArray={durationOptions}
                    click={(value: any) => {
                      setSelectedDatePreference(value);
                      if (value === "Custom") {
                        setShowCustomDatePicker(true);
                      }
                    }}
                    resetSelection={() => {}}
                    id="z-duration-product-report-usage"
                  />
                </div>
                <div className="d-flex flex-column flex-grow-1 mr-3">
                  <p className={Styles.dropDownHeader}>Product</p>
                  <InputDropdown
                    forDashboard={true}
                    style={{ width: "160px" }}
                    label="Product"
                    no_shadow="true"
                    optionsArray={productOptions}
                    placeholder={"All Products"}
                    id="z-duration-product-usage"
                    click={(value: any) => {
                      setSelectedProduct(value);
                    }}
                  />
                </div>
              </div>
              <div className={`${Styles.barContainer} d-flex flex-column`}>
                <div className={`${Styles.box} ${Styles.overlay}`}>
                  <Chart
                    data={chartDataCurrent}
                    options={chartOptions}
                    type={"line"}
                  />
                </div>
                <div className={`${Styles.box}`}>
                  <Chart
                    data={chartDataPrev}
                    options={chartOptions}
                    type={"line"}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <div className="d-flex justify-content-around align-items-center">
                  <div className={`mr-2 ${Styles.legendPointerPrev}`}></div>
                  <div
                    className={Styles.dropDownHeader}
                  >{`${prevStartDate} - ${prevEndDate}`}</div>
                </div>
                <div className="d-flex justify-content-around align-items-center">
                  <div className={`mr-2 ${Styles.legendPointerCurr}`}></div>
                  <div
                    className={Styles.dropDownHeader}
                  >{`${startDate} - ${endDate}`}</div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <InsightsCard
                currentTranxnVolume={currentTranxnVolume}
                prevTranxnVolume={prevTranxnVolume}
                productName={selectedProduct.label}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsUsage;
