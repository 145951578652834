//In service LevelSettings if user wants to add new or remove existing subscribed
//  services from the app they are currently using.
import React, { useEffect, useCallback } from "react";
import Styles from "../app-info/appInfo.module.scss";
import CloseSvg from "../../../../Shared/svg/close";
import EmptyService from "../../../../assets/images/noServicesSubscribe.svg";
import Classes from "../../../../Shared/popup.module.scss";
import Button from "../../../../Shared/button/button";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";
import { callApi } from "../../../../api/fetch";
import Loading from "../../../../Shared/loading/loading";
import { ACCENTCOLOR } from "../../../../Shared/colors";
import { MEDIUM } from "../../../../Shared/buttonSize";

function AddServicePopUp({ onSubmit, onCancel, enabledService }: any) {
  const [toggleUpdateService, setToggleUpdateService] = React.useState(false);
  const [orgSubscribedService, setOrgSubscribedService] = React.useState([]);
  const [appSubscribedServices, setAppSubscribedServices] = React.useState(
    enabledService
  );
  const [loading, setLoading] = React.useState(false);
  const getSubscribedProducts = useCallback(async () => {
    // this array is used to get each id of the product that are already present in the App
    const appIdArray: any = [];
    setLoading(true);
    try {
      const data = await callApi("/product?subscription_status=subscribed", {
        method: "GET",
      });
      enabledService.forEach((service: any) => {
        appIdArray.push(service._id);
      });
      // filtering the subscribed service with those already present in the App
      const filteredOrgServices = data.filter((orgService: any) => {
        return !appIdArray.includes(orgService._id);
      });
      setOrgSubscribedService(filteredOrgServices);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [enabledService]);

  useEffect(() => {
    getSubscribedProducts();
  }, [getSubscribedProducts]);

  function handelCancel(event: any) {
    event.preventDefault();
    onCancel();
    return;
  }
  function handleAddService(event: any) {
    event.preventDefault();
    onSubmit(appSubscribedServices);
    return;
  }
  return (
    <div>
      <div
        className={`d-flex align-items-center justify-content-center ${Classes.overlay}`}
        style={{ overflow: "auto" }}
      >
        <div className={`${Classes.popUpContent}`} style={{ width: "50%" }}>
          {loading ? (
            <div
              style={{ height: "400px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : (
            <div>
              <div className={`p-4 ${Styles.cardHeader} bg-white-color`}>
                <p className={"card_title_bold_text px-2 mb-0"}>
                  Manage Services
                </p>
              </div>
              <div className="p-3">
                <div className="row p-3">
                  <div
                    className={"col-md-6 " + Styles.borderRightManageServics}
                  >
                    <div
                      style={{ textAlign: "center" }}
                      className={`align-items-center ${Styles.headingText}`}
                    >
                      Subscribed Products
                    </div>
                    {orgSubscribedService.length === 0 ? (
                      <div
                        className={`${Styles.emptyStateDiv} d-flex align-items-center justify-content-center`}
                        style={{
                          height: "100%",
                          minHeight: "250px",
                          maxHeight: "450px",
                        }}
                      >
                        <div>
                          <img
                            src={EmptyService}
                            alt="Loading..."
                            width="100px"
                            height="100px"
                          />
                          <p
                            className={`${Styles.emptyStateText} mb-0 pt-2`}
                            style={{ fontSize: "12px" }}
                          >
                            Oops!
                            <br />
                            No more Subscribed Products!
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="py-4"
                        style={{
                          height: "100%",
                          minHeight: "250px",
                          maxHeight: "450px",
                          overflow: "auto",
                        }}
                      >
                        <div className="d-flex flex-wrap">
                          {orgSubscribedService.map((service: any) => {
                            return (
                              <div className="p-1" key={service._id}>
                                <div
                                  className={`${Styles.serviceLevelSettings} p-2`}
                                  style={{
                                    textAlign: "left",
                                    cursor: "pointer",
                                  }}
                                  title={`Enable ${service.name} service to the app!`}
                                  onClick={() => {
                                    const array: any = [
                                      ...appSubscribedServices,
                                      service,
                                    ];
                                    setOrgSubscribedService(
                                      orgSubscribedService.filter(
                                        (orgService: any) => {
                                          return service._id !== orgService._id;
                                        }
                                      )
                                    );
                                    setAppSubscribedServices(() => array);
                                    setToggleUpdateService(true);
                                  }}
                                >
                                  <p
                                    className={`px-1 mb-0 ${Styles.serviceName}`}
                                  >
                                    {service.name}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div
                      style={{ textAlign: "center" }}
                      className={`align-items-center ${Styles.headingText}`}
                    >
                      Products added to App
                    </div>
                    {appSubscribedServices.length === 0 ? (
                      <div
                        className={`${Styles.emptyStateDiv} d-flex align-items-center justify-content-center`}
                        style={{
                          height: "100%",
                          minHeight: "250px",
                          maxHeight: "450px",
                        }}
                      >
                        <div>
                          <img
                            src={EmptyService}
                            alt="Loading..."
                            width="100px"
                            height="100px"
                          />
                          <p
                            className={`${Styles.emptyStateText} mb-0 text-center pt-2`}
                            style={{ fontSize: "12px" }}
                          >
                            Click on the tabs to
                            <br />
                            add products to this app
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="py-4"
                        style={{
                          height: "100%",
                          minHeight: "250px",
                          maxHeight: "450px",
                          overflow: "auto",
                        }}
                      >
                        <div className="d-flex flex-wrap">
                          {appSubscribedServices.map((service: any) => {
                            return (
                              <div className="p-1" key={service._id}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    cursor: "pointer",
                                  }}
                                  className={`${Styles.serviceLevelSettings} p-2`}
                                  title={`Disable ${service.name} service from the app!`}
                                  onClick={() => {
                                    const array: any = [
                                      ...orgSubscribedService,
                                      service,
                                    ];
                                    setAppSubscribedServices(
                                      appSubscribedServices.filter(
                                        (appService: any) => {
                                          return service._id !== appService._id;
                                        }
                                      )
                                    );
                                    setOrgSubscribedService(() => array);
                                    setToggleUpdateService(true);
                                  }}
                                >
                                  <p
                                    className={`mb-0 px-1 ${Styles.serviceName}`}
                                  >
                                    {service.name}
                                    <span className="pl-2">
                                      <CloseSvg
                                        width="7"
                                        height="7"
                                        classes={Styles.svgColor}
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center py-3">
                  <div>
                    <Button
                      size={MEDIUM}
                      onClick={handelCancel}
                      hoveredStyle={primaryButtonHoverStyle}
                      style={primaryButtonStyle}
                      type="submit"
                      className="bg-lite-accent-color"
                      btnTextClass="primary-color"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div style={{ marginLeft: "15px" }}>
                    <Button
                      hoveredStyle={primaryButtonHoverStyle}
                      disabled={!toggleUpdateService}
                      style={primaryButtonStyle}
                      onClick={handleAddService}
                      size={MEDIUM}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddServicePopUp;
