import React from "react";
import Styles from "./inputField.module.scss";

function InputField({
  inputRef,
  isError,
  purpose,
  onChange,
  text = "upperCase",
  ...props
}: any) {
  const [userInput, setUserInput] = React.useState<string>("");

  const handleChange = (evtObj: any) => {
    const inputValue = evtObj.target.value;
    setUserInput(inputValue);
    inputRef.current.value = inputValue;
    onChange();
  };

  const classObject: any = {
    forPan: Styles.inputFieldPan,
    forPassport: Styles.inputFieldPassport,
    forGst: Styles.inputFieldGst,
    forRc: Styles.inputFieldRc,
    forBankVerification: Styles.inputFieldBankVerification,
    forDl: Styles.inputFieldDl,
    forVoter: Styles.inputFieldVoter,
    forIfsc: Styles.inputFieldIfsc,
    forOkyc: Styles.inputFieldOkyc,
    forOtp: Styles.inputFieldOtp,
  };

  return (
    <input
      value={userInput}
      required
      ref={inputRef}
      className={`${classObject[purpose]} ${
        text == "upperCase" ? Styles.inputFieldUpperCase : null
      }`}
      autoComplete={"off"}
      style={isError ? { border: "1px solid red" } : {}}
      type="text"
      {...props}
      onChange={handleChange}
    />
  );
}

export default InputField;
