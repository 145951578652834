import React from "react";
import { SECONDARYCOLOR } from "../colors";

function ArrowDown({
  color = SECONDARYCOLOR,
  width = "12",
  height = "12",
  style,
}: any) {
  return (
    // <svg
    //   style={{ fill: color }}
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width}
    //   height={height}
    //   viewBox="0 0 10.904 6.733"
    // >
    //   <path
    //     id="ic_keyboard_arrow_down_24px"
    //     d="M1.281 0l4.171 4.162L9.623 0 10.9 1.281 5.452 6.733 0 1.281z"
    //   />
    // </svg>
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...(style ? { ...style, fill: color } : { fill: color }) }}
    >
      <path
        d="M6.25575 7.37775C6.2595 7.37325 6.261 7.36725 6.26475 7.36275L8.883 4.47C9.02925 4.30725 9.02925 4.04325 8.883 3.8805C8.8815 3.879 8.88 3.87825 8.8785 3.8775C8.84597 3.83809 8.80521 3.80626 8.7591 3.78424C8.71298 3.76222 8.66261 3.75053 8.6115 3.75H3.37425C3.32221 3.75084 3.27099 3.7631 3.22421 3.78591C3.17742 3.80872 3.13622 3.84152 3.1035 3.882L3.102 3.8805C3.03123 3.96239 2.99228 4.06701 2.99228 4.17525C2.99228 4.28349 3.03123 4.38811 3.102 4.47L5.72625 7.37775C5.75892 7.41605 5.79951 7.4468 5.84522 7.46789C5.89093 7.48897 5.94067 7.49989 5.991 7.49989C6.04134 7.49989 6.09108 7.48897 6.13679 7.46789C6.1825 7.4468 6.22308 7.41605 6.25575 7.37775Z"
        fill="#5E5E5E"
      />
    </svg>
  );
}

export default ArrowDown;
