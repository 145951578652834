const customStylesForCountry = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "300px",
    border: "1px solid #ebebeb",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "auto",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForCountryError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "300px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForDays = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "80px",
    border: "1px solid #ebebeb",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    background: "#ffff",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForDaysError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "80px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    background: "#ffff",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForMonths = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "100px",
    border: "1px solid #ebebeb",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    background: "#ffff",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForMonthsError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "100px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    background: "#ffff",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForYears = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "104px",
    border: "1px solid #ebebeb",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    background: "#ffff",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForYearsError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "104px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    background: "#ffff",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForFinancialYears = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "252px",
    border: "1px solid #ebebeb",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    background: "#ffff",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForTaxMode = {
  control: (base: any) => ({
    ...base,
    height: "42px",
    border: "1px solid #ebebeb",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    width: "300px",
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "auto",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForTaxModeError = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "42px",
    width: "300px",
    border: "1px solid red",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "auto",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const customStylesForDashboard = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "12px",
    outline: "none",
  }),
  control: () => ({
    height: "40px",
    width: "100px",
    display: "flex",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    fontFamily: "'Poppins' sans-serif",
    backgroundColor: "white",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "auto",
    borderRadius: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export default {
  customStylesForCountry,
  customStylesForMonths,
  customStylesForYears,
  customStylesForDays,
  customStylesForCountryError,
  customStylesForMonthsError,
  customStylesForYearsError,
  customStylesForDaysError,
  customStylesForFinancialYears,
  customStylesForTaxMode,
  customStylesForTaxModeError,
  customStylesForDashboard,
};
