import React from "react";

function dashboardSVGNew(classes: any) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path d="M8.30465 23.4776V19.644C8.30463 18.6726 9.09457 17.8834 10.073 17.8772H13.6657C14.6486 17.8772 15.4453 18.6683 15.4453 19.644V23.4665C15.4453 24.3091 16.13 24.9938 16.9786 25H19.4298C20.5745 25.0029 21.6734 24.5535 22.4839 23.7508C23.2944 22.9482 23.75 21.8584 23.75 20.7219V9.83229C23.75 8.91422 23.34 8.04338 22.6307 7.45436L14.3037 0.842834C12.8481 -0.313596 10.7692 -0.276239 9.35672 0.931729L1.20876 7.45436C0.465925 8.02601 0.0219403 8.89944 0 9.83229V20.7108C0 23.0796 1.93422 25 4.32021 25H6.71535C7.12395 25.0029 7.51685 24.8438 7.80683 24.558C8.09681 24.2722 8.25989 23.8833 8.25988 23.4776H8.30465Z" />
    </svg>
  );
}

export default dashboardSVGNew;
