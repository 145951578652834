import React, { useEffect, useState } from "react";
import Styles from "./emailVerification.module.scss";
import AuthSidebar from "../authSidebar/authSidebar";
import Illustration from "../../../assets/images/Verify_email_dashboard_illustration.svg";
import InformativeText from "../informativeText/informativeText";
import { callApi } from "../../../api/fetch";
import Loading from "../../../Shared/loading/loading";
import { ACCENTCOLOR } from "../../../Shared/colors";
import Toast from "../../../Shared/toast/toast";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import { useTitle } from "../../../Shared/useTitle";

function EmailVerification() {
  const counter = Number(localStorage.getItem("resend_link_counter")) || 0;
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [error, setError] = useState("");
  const capitalizeFirstChar = (string: any) => {
    if (typeof string !== "string") return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    if (counter >= 3) {
      setError(
        "You have exhausted the number of retries for email verification"
      );
    }
  }, [counter]);
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }
  const firstname = capitalizeFirstChar(localStorage.getItem("firstname"));
  async function handleResendVerificationLink() {
    setLoading(true);
    try {
      const data: any = await callApi("/user/verification-link", {
        method: "POST",
        data: {
          email: localStorage.getItem("email"),
          success_redirect: `${window.location.origin}/create-password`,
          failure_redirect: `${window.location.origin}/email-verification-failed`,
        },
      });
      setLoading(false);
      toastTimeout(true, "success", data);
    } catch (err) {
      setLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }
  useTitle("Zoop Customer Platform | Email Verification");
  return (
    <div className="container-fluid p-0">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className="row no-gutters">
        <div className="col-xl-5 d-none d-xl-block">
          <AuthSidebar
            img={<img src={Illustration} alt="illustration" width="250px" />}
            info={<InformativeText headertext="One Identity Platform." />}
          />
        </div>
        <div className="col-xl-7 col-lg-12">
          <div className={`${Styles.authActionsBackground}`}>
            <div
              style={{ height: "100%" }}
              className="d-flex align-items-center justify-content-center"
            >
              <div>
                <p className={`${Styles.welcomeMessage} mb-0 text-center`}>
                  Hi {firstname}
                </p>
                <div className="py-4 text-center">
                  <p className={`${Styles.bodyText} mb-1`}>
                    We have sent you an email on{" "}
                    <span className={`${Styles.email}`}>
                      {localStorage.getItem("email")}
                    </span>
                  </p>
                  <p className={`${Styles.bodyText} mb-1`}>
                    Verify by clicking on the link in the email and become a
                    part of our ZOOP.ONE family
                  </p>
                </div>
                {error ? (
                  <div className="py-4 d-flex justify-content-center">
                    <ErrorMessage>{error}</ErrorMessage>
                  </div>
                ) : (
                  <div className="py-4">
                    <p
                      className={`${Styles.bodyText} mb-1`}
                      style={{ fontSize: "12px" }}
                    >
                      Not received the verification email?
                    </p>
                    {loading ? (
                      <Loading
                        loadingColor={ACCENTCOLOR}
                        width="10px"
                        height="10px"
                      />
                    ) : (
                      <p
                        className={`${Styles.linkText} mb-0`}
                        onClick={() => {
                          if (counter >= 3) {
                            setError(
                              "You have exhausted the number of retries for email verification"
                            );
                            return;
                          }
                          localStorage.setItem(
                            "resend_link_counter",
                            (Number(counter) + 1).toString()
                          );
                          handleResendVerificationLink();
                        }}
                      >
                        Resend Email
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
