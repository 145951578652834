import axios from "axios";

type DefaultApiParams = {
  data?: any;
  headers?: any;
  method: string;
  init?: RequestInit;
};
export async function callApiWithDefaults(
  url: string,
  params: DefaultApiParams
) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios({
      url: url,
      ...params,
      validateStatus: () => true,
    });
    return response;
  } catch (err) {
    throw err;
  }
}

type ApiParams = {
  data?: any;
  method: string;
  headers?: Headers;
  init?: RequestInit;
};
export async function callApi(path: string, params: ApiParams) {
  // eslint-disable-next-line no-useless-catch
  try {
    const token = localStorage.getItem("token")?.split('"')[1];
    const resObj: any = await callApiWithDefaults(
      `${process.env.REACT_APP_SERVER_URI}${path}`,
      {
        ...params,
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
          ...params?.init,
          ...params?.headers,
          ...params?.init?.headers,
        },
      }
    );

    const res = resObj.data;
    if (res.err) {
      if (typeof res.err === "string") {
        if (res.err.includes("deactivated") || resObj.status === 403) {
          localStorage.clear();
          if (window.location.pathname !== "/login") {
            window.location.pathname = "/login";
          }
        }
        throw new Error(res.err);
      }
      if ([503, 504].includes(res.err?.status)) return res.err;
      throw new Error(res.err.message ?? "Something went wrong!");
    }

    return res.data;
  } catch (err) {
    throw err;
  }
}

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URI;

// All the POST apis will use this function.
export function callAxiosPostApi(url: string, ...params: any) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      // eslint-disable-next-line
      const token = localStorage.getItem("token")?.split('"')[1];
      const response = await axios.post(url, ...params, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      });
      return resolve(response.data);
    } catch (err) {
      return reject((err as any)?.response?.data || err);
    }
  });
}
