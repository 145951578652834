//Main component that sumup having all the product cards their status and other functionalities.
import React, { useEffect } from "react";
import Styles from "./productCatalog.module.scss";
import ServiceCard from "./service-card/serviceCard";
import FilterTabs from "./filter-tabs/filterTabs";
import { ALL } from "../../../constants/filters";
import Loading from "../../../Shared/loading/loading";
import { ACCENTCOLOR } from "../../../Shared/colors";
import SearchImage from "../../../assets/images/search.png";
import { callApi } from "../../../api/fetch";
import PendingApproval from "./pending-approval/pendingApproval";
import Toast from "../../../Shared/toast/toast";
import TrialActivatedPopUp from "./trial-activated-pop-up/trialActivatedPopUp";
import Dropdown from "../../../Shared/dropdown/dropdown";
import emptyStateStyle from "../../../Shared/empty-state/emptyState.module.scss";
import EmptyState from "../../../Shared/empty-state/emptyState";
import { noContent } from "../../../constants/emptyStatesVariables";
import { useTitle } from "../../../Shared/useTitle";
import { customEvent } from "../../../utils/ga4";
function ProductCatalog() {
  const org_id = localStorage.getItem("organisation_id");
  const [dropdownFilter, setDropdownFilter] = React.useState("");
  const [selectedProductType, setSelectedProductType] = React.useState("");
  const [products, setProducts] = React.useState<any>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [togglePendingStatePopUp, setTogglePendingStatePopUp] = React.useState(
    false
  );
  const [productCategories, setProductCategories] = React.useState(["All"]);
  const [
    toggleTrialTransactionPopUp,
    setToggleTrialTransactionPopUp,
  ] = React.useState({
    trial_transaction: 0,
    showPopUp: false,
  });
  const [cardLoading, setCardLoading] = React.useState({
    serviceName: "",
    isLoading: false,
  });
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const statusArray = [
    "All Products",
    "Not Subscribed",
    "Already Subscribed",
    "Pending Approval",
  ];
  const filteredProducts = products.filter((response: any) => {
    return filterConditions(response);
  });
  const emptyState = (
    <div
      className={`${Styles.emptyState} d-flex align-items-center justify-content-center`}
    >
      <EmptyState type={noContent}>
        <div className={"py-3"}>
          <p className={`${emptyStateStyle.emptyStateHeadingText} mb-0`}>
            No Products found
          </p>
        </div>
      </EmptyState>
    </div>
  );

  useEffect(() => {
    async function getProducts() {
      setLoading(true);
      try {
        const response = await callApi("/product", {
          method: "GET",
        });
        const categories = response.map((product: any) => product.category);
        setProductCategories((productCategories) =>
          productCategories.concat(
            categories.filter(
              (category: string, index: number) =>
                categories.indexOf(category) === index
            )
          )
        );
        setProducts(response);
        setLoading(false);
        setCardLoading((cardLoading) => ({
          ...cardLoading,
          serviceName: "",
          isLoading: false,
        }));
      } catch (err) {
        setLoading(false);
        setCardLoading((cardLoading) => ({
          ...cardLoading,
          serviceName: "",
          isLoading: false,
        }));
        toastTimeout(true, "error", (err as Error).message);
        // TODO: add sentry error
      }
    }
    getProducts();
  }, []);

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  function updateProducts(updatedProduct: any) {
    setProducts(
      products.map((product: any) => {
        if (product.name === updatedProduct.product.name) {
          return {
            ...product,
            subscription_status: updatedProduct.status,
          };
        }
        return product;
      })
    );
  }

  async function handleSubscribeService(
    productName: string,
    id: string,
    category: string
  ) {
    setCardLoading((cardLoading) => ({
      ...cardLoading,
      serviceName: productName,
      isLoading: true,
    }));
    try {
      const response = await callApi(
        `/organisation/${org_id}?action=org_product_subscribe  `,
        {
          method: "PUT",
          data: {
            productIds: [id],
          },
        }
      );
      setCardLoading((cardLoading) => ({
        ...cardLoading,
        serviceName: "",
        isLoading: false,
      }));
      setToggleTrialTransactionPopUp((toggleTrialTransactionPopUp) => ({
        ...toggleTrialTransactionPopUp,
        trial_transaction: response[0].test_credit,
      }));
      updateProducts(response[0]);
      if (response[0].product.auto_approval) {
        setToggleTrialTransactionPopUp((toggleTrialTransactionPopUp) => ({
          ...toggleTrialTransactionPopUp,
          showPopUp: true,
        }));
        customEvent(
          `${category}_Product`,
          "Product_subscribed",
          `${productName} subscribed`
        );
        return;
      }
      if (!response[0].product.auto_approval) {
        setTogglePendingStatePopUp(true);
        setToast((toast) => ({
          ...toast,
          show: false,
        }));
        customEvent(
          `${category}_Product`,
          "Product_Subscribed",
          `${productName} subscribed`
        );
        return;
      }
    } catch (err) {
      setCardLoading((cardLoading) => ({
        ...cardLoading,
        serviceName: "",
        isLoading: false,
      }));
      toastTimeout(true, "error", (err as Error).message);
    }
  }

  async function unSubscribeService(
    productName: string,
    id: string,
    category: string
  ) {
    setCardLoading((cardLoading) => ({
      ...cardLoading,
      serviceName: productName,
      isLoading: true,
    }));
    try {
      const response = await callApi(
        `/organisation/${org_id}?action=org_product_unsubscribe  `,
        {
          method: "PUT",
          data: {
            productIds: [id],
          },
        }
      );
      updateProducts(response[0]);
      setCardLoading((cardLoading) => ({
        ...cardLoading,
        serviceName: "",
        isLoading: false,
      }));
      toastTimeout(true, "success", "Product unsubscribed successfully");
      customEvent(
        `${category}_Product`,
        "Product_UnSubscribed",
        `${productName} unsubscribed`
      );
    } catch (err) {
      setCardLoading((cardLoading) => ({
        ...cardLoading,
        serviceName: "",
        isLoading: false,
      }));
      toastTimeout(true, "error", (err as Error).message);
    }
    return;
  }

  function toggleTab(value: string) {
    if (value === ALL) {
      return setSelectedProductType("");
    } else if (selectedProductType === value) {
      return setSelectedProductType("");
    }
    setSelectedProductType(value);
    return;
  }

  function filterConditions(response: any) {
    if (dropdownFilter !== "") {
      if (dropdownFilter === "Pending Approval") {
        return (
          response.category.includes(selectedProductType) &&
          response.name
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) &&
          response.subscription_status === "PENDING_APPROVAL"
        );
      } else if (dropdownFilter === "Already Subscribed") {
        return (
          response.category.includes(selectedProductType) &&
          response.name
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) &&
          response.subscription_status === "SUBSCRIBED"
        );
      } else if (dropdownFilter === "Not Subscribed") {
        return (
          response.category.includes(selectedProductType) &&
          response.name
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) &&
          response.subscription_status === "UNSUBSCRIBED"
        );
      }
    }
    return (
      response.category.includes(selectedProductType) &&
      response.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }
  useTitle("Zoop Customer Platform | Products ");
  return (
    <div>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      {loading ? (
        <div
          style={{
            height: "80vh",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div className="container-fluid p-0">
          <div className="p-3">
            <div className="px-3 py-2 d-flex align-items-center">
              <div className="ml-auto" style={{ width: "400px" }}>
                <div
                  className={`${Styles.inputWrapper} d-flex align-items-center pl-2`}
                >
                  <div className="px-2">
                    <img
                      src={SearchImage}
                      alt="search"
                      width="20px"
                      style={{ marginBottom: "3px" }}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      id="z-product-catalog"
                      className={Styles.formControl}
                      placeholder="Search product here"
                      value={searchTerm}
                      onChange={(event: any) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between pl-3 mb-4">
            <div
              className={`px-3 d-flex align-items-end justify-content-start ${
                Styles.filterTabContainer || ""
              }`}
            >
              {productCategories.map((category: any) => {
                return (
                  <div key={category} className="mr-4">
                    <FilterTabs
                      filteredString={selectedProductType}
                      category={category}
                      click={(value: any) => toggleTab(value)}
                      hideCross={category === ALL}
                    />
                  </div>
                );
              })}
            </div>
            <div className={Styles.dropdownContainer}>
              <div className="px-2">
                <Dropdown
                  default_value="All Products"
                  optionsArray={statusArray}
                  click={(value: any) => {
                    if (value === "All Products") {
                      setDropdownFilter("");
                      return;
                    }
                    setDropdownFilter(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row no-gutters pb-3 mx-2">
              {filteredProducts.length !== 0
                ? filteredProducts.map((product: any) => {
                    return (
                      <div
                        key={product.name}
                        className="col-xl-3 col-md-6"
                        style={{ padding: "9px" }}
                      >
                        <ServiceCard
                          isLoading={cardLoading}
                          productDetails={product}
                          click={(value: string) =>
                            value === "subscribe"
                              ? handleSubscribeService(
                                  product.name,
                                  product._id,
                                  product.category
                                )
                              : unSubscribeService(
                                  product.name,
                                  product._id,
                                  product.category
                                )
                          }
                        />
                      </div>
                    );
                  })
                : emptyState}
            </div>
          </div>
        </div>
      )}
      {togglePendingStatePopUp ? (
        <PendingApproval onConfirm={() => setTogglePendingStatePopUp(false)} />
      ) : null}
      {toggleTrialTransactionPopUp.showPopUp ? (
        <TrialActivatedPopUp
          trial_transaction={toggleTrialTransactionPopUp.trial_transaction}
          onConfirm={() => {
            setToggleTrialTransactionPopUp((toggleTrialTransactionPopUp) => ({
              ...toggleTrialTransactionPopUp,
              showPopUp: false,
            }));
          }}
        />
      ) : null}
    </div>
  );
}

export default ProductCatalog;
