//User wanted to add the key to app displaying via popup on clicking "add key"
import React from "react";
import validator from "validator";
import Styles from "../../../../Shared/popup.module.scss";
import WarningIllustration from "../../../../assets/images/Warning.svg";
import Classes from "./addApiKey.module.scss";
import Input from "../../../../Shared/input/input";
import { isValidName } from "../../../../utils/validation";
import ErrorMessage from "../../../../Shared/error-message/errorMessage";
import Button from "../../../../Shared/button/button";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../Shared/buttonStyles";
import { callApi } from "../../../../api/fetch";
import { MEDIUM } from "../../../../Shared/buttonSize";
import { monthName } from "../../../../utils/getMonth";

function AddAppKey({ onCancel, onSubmit, appId, apiKey = null }: any) {
  const [appKey, setAppKey] = React.useState("");
  const [error, setError] = React.useState("");
  const [validKeyName, setValidKeyName] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(null);
  const apiKeyRef = React.useRef<any>(null);
  const [copied, setCopied] = React.useState(false);

  function checkAppKey() {
    if (validator.isEmpty(appKey, { ignore_whitespace: true })) {
      setValidKeyName(false);
      setError("Key name cannot be empty");
      return false;
    }
    if (isValidName(appKey, true)) {
      setValidKeyName(false);
      setError("Key name should contain alphanumeric char only");
      return false;
    }
    if (validator.isNumeric(appKey[0], { no_symbols: true })) {
      setValidKeyName(false);
      setError("Key name should not start with a number");
      return false;
    }
    if (appKey.length > 25) {
      setValidKeyName(false);
      setError("Key name should be less than 25 character");
      return false;
    }
    return true;
  }

  function handleAppKeyCancel(event: any) {
    event.preventDefault();
    onCancel();
    return;
  }

  function handleCopyApiKey() {
    const range = new Range();

    range.setStart(apiKeyRef.current, 0);
    range.setEnd(apiKeyRef.current, 1);

    // apply the selection
    window.document.getSelection()?.removeAllRanges();
    window.document.getSelection()?.addRange(range);

    window.document.execCommand("copy");

    setCopied(true);
  }

  async function handleAppKey(event: any) {
    event.preventDefault();

    if (loading || !checkAppKey()) return;

    try {
      setLoading(true);
      const res = await callApi(`/app/${appId}?action=generate_app_key`, {
        method: "PUT",
        data: {
          keyName: appKey,
        },
      });
      setLoading(false);

      setData(res);
      const updatedKeys = res.keys.map((key: any) => {
        const createdDate = new Date(Number(key.createdAt));
        const updatedDate = new Date(Number(key.updatedAt));
        return {
          ...key,
          createdAt: `${createdDate.getDate()}-${monthName(
            createdDate.getMonth()
          )}-${createdDate.getFullYear()}`,
          updatedAt: `${updatedDate.getDate()}-${monthName(
            updatedDate.getMonth()
          )}-${updatedDate.getFullYear()}`,
        };
      });
      onSubmit(updatedKeys);
    } catch (err) {
      setLoading(false);
      setError((err as Error).message);
    }
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      {apiKey || (data && data.apiKey) ? (
        <div
          className={`p-4 ${Styles.popUpContent}`}
          style={{ width: "500px" }}
        >
          <div className="py-2 text-center">
            <img
              src={WarningIllustration}
              alt="warning-illustration"
              style={{ width: "100px" }}
            />
          </div>
          <div className="py-2">
            <p className={`mb-0 ${Classes.headingText}`}>API Key</p>
          </div>
          <div className="py-2">
            <p className={`mb-0 ${Classes.key}`} ref={apiKeyRef}>
              {apiKey || data.apiKey}
            </p>
          </div>
          <div className="py-3 px-4">
            <p className={`mb-0 ${Classes.subtext}`}>
              Please copy the generated API key to save the same. <br /> You
              will not be able to retrieve this later.
            </p>
          </div>
          <div className="py-3 text-center" style={{ margin: "0 auto" }}>
            <Button
              size={MEDIUM}
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              type="Button"
              onClick={handleCopyApiKey}
            >
              {copied ? "Copied" : "Copy Key"}
            </Button>
            <div className="py-3 d-flex justify-content-center">
              <p
                className={`mb-0 d-inline-flex ${Classes.closeText}`}
                onClick={handleAppKeyCancel}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={`p-4 ${Styles.popUpContent}`}>
          <form onSubmit={handleAppKey}>
            <div className="form-group">
              <p className="title_semibold_text mb-3">App Key</p>

              <Input
                type="text"
                name="appKey"
                id="appKey"
                autoComplete="off"
                maxLength={25}
                isValid={validKeyName}
                placeholder="Name your key"
                hasError={error}
                // labelname="App Key"
                onChange={(event: any) => {
                  const value = event.target.value;
                  setAppKey(value.trim());
                  setError("");
                  setValidKeyName(true);
                }}
                onBlur={checkAppKey}
                customInputClass="border-radius-10"
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
            <div className="d-flex align-items-center justify-content-between py-2">
              <div className="pr-2 w-100">
                <Button
                  size={MEDIUM}
                  onClick={handleAppKeyCancel}
                  hoveredStyle={primaryButtonHoverStyle}
                  style={primaryButtonStyle}
                  type="button"
                  className="bg-lite-accent-color w-100"
                  btnTextClass="primary-color"
                >
                  Cancel
                </Button>
              </div>
              <div className="w-100">
                <Button
                  size={MEDIUM}
                  isLoading={loading}
                  hoveredStyle={primaryButtonHoverStyle}
                  style={primaryButtonStyle}
                  type="submit"
                  className="w-100"
                >
                  Add key
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default AddAppKey;
