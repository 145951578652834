import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Styles from "./topBarNew.module.scss";
import DocsSVGnew from "../svg/DocsSVGnew";
import QuestionMarkSVG from "../svg/questionMarkSVG";
import ChangePassword from "../../components/application/my-account/change-password/changePassword";
import { SECONDARYCOLOR, WHITE } from "../colors";
import ArrowDown from "../svg/arrowDown";
import Sidebar from "../sidebar/sidebar";
import Toast from "../toast/toast";
import { pageNameMap } from "../../constants/data";
import ArrowUp from "../svg/arrowUp";

function TopBar({ userDetails }: any) {
  const route: any = window.location.href.split("/");
  const [pageName, setPageName] = useState(pageNameMap[route.at(-1)]);
  const userObj: any = localStorage.getItem("@user");
  const user = JSON.parse(userObj);
  const history = useHistory();
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [toggleCollapsedView, setToggleCollapsedView] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }
  function handleChangePassword(data: string) {
    setShowChangePassword(false);
    toastTimeout(true, "success", data);
  }
  function handleLogout() {
    localStorage.clear();
    history.replace("/login");
  }

  useEffect(() => {
    setPageName(pageNameMap[route.at(-1)]);
  }, [route]);

  const handelBackClick = () => {
    route.includes("manage-apps") && history.push("/application/manage-apps");
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className={"mt-3 " + Styles.navPageName}>
        {pageName ? (
          <p className="heading_text">{pageName}</p>
        ) : (
          <>
            {route.at(-2) === "manage-apps" ? (
              <div
                className="d-flex align-items-center"
                onClick={handelBackClick}
                style={{ cursor: "pointer" }}
              >
                <div className={`px-1 ${Styles.rotate}`}>
                  <ArrowUp color={SECONDARYCOLOR} />
                </div>
                <p className={`mb-0 ${Styles.backText}`}>back</p>
              </div>
            ) : null}
          </>
        )}
      </div>

      <div>
        <div
          className={`${Styles.topNavBackground} d-flex align-items-center sticky-top`}
          style={{ zIndex: 2 }}
        >
          {toast.show && (
            <Toast
              type={toast.type}
              message={toast.message}
              click={() =>
                setToast((toast: any) => ({
                  ...toast,
                  show: false,
                }))
              }
            />
          )}
          <div className={`px-1 ${Styles.showOnMobileDevices}`}>
            <div
              className={`${Styles.arrowWrapper} d-flex align-items-center justify-content-center`}
              onClick={() => setToggleSidebar(!toggleSideBar)}
            >
              <div className={Styles.rotateArrowRight}>
                <ArrowDown color={SECONDARYCOLOR} width="8" height="5" />
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="d-flex align-items-center">
              <div className="px-2 mt-2">
                <Link to="/doc" className="d-flex mt-1">
                  <DocsSVGnew classes={Styles.SVGColor} />
                  <p className={`${Styles.docText} ml-2`}>Documentation</p>
                </Link>
              </div>
              {/* TODO: will be use later when notification func will be added  */}
              {/* <div className="px-2 mb-1">
                <div>
                  <AlertSVG classes={Styles.SVGNotification} />
                </div>
              </div> */}
              <div className="px-2 mb-1">
                <a
                  href="https://zoopone.freshdesk.com/support/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <QuestionMarkSVG classes={Styles.SVGColor} />
                </a>
              </div>

              <div className="pl-2 pr-3">
                <div className="dropdown">
                  <div
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div
                      className={`${Styles.userSectionBackground} d-flex justify-content-center align-items-center`}
                    >
                      <p className={`mb-0 ${Styles.initial}`}>{user.name[0]}</p>
                    </div>
                  </div>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    style={{
                      borderRadius: "8px",
                      width: "250px",
                      backgroundColor: WHITE,
                      border: 0,
                      boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.16)",
                      zIndex: 1,
                    }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="p-3">
                      <div className="d-flex align-items-center">
                        <div className="px-2">
                          <div
                            className={`${Styles.userSectionBackground} d-flex justify-content-center align-items-center`}
                            style={{ cursor: "default" }}
                          >
                            <p className={`mb-0 ${Styles.initial}`}>
                              {user.name[0]}
                            </p>
                          </div>
                        </div>
                        <div className="px-2">
                          <p className={`mb-0 ${Styles.userName}`}>
                            {userDetails ? userDetails.name : user.name}
                          </p>
                          <p className={`mb-0 ${Styles.userEmail}`}>
                            {user.email}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className={Styles.divider} />
                    <div
                      onClick={() => history.push("/my-account")}
                      className={`p-3 d-flex align-items-center justify-content-center ${Styles.businessInfoBack}`}
                    >
                      <p className={`mb-0 ${Styles.businessInfo}`}>
                        Business Info
                      </p>
                    </div>
                    <hr className={Styles.divider} />
                    {/* Commented the KYC details page */}
                    {/* <div
                  onClick={() => history.push("/activation-form")}
                  className={`p-3 d-flex align-items-center justify-content-center ${Styles.businessInfoBack}`}
                >
                  <p className={`mb-0 ${Styles.businessInfo}`}>KYC Details</p>
                </div> 
                <hr className={Styles.divider} /> */}
                    <div
                      onClick={() => setShowChangePassword(true)}
                      className={`p-3 d-flex align-items-center justify-content-center ${Styles.changePasswordBack}`}
                    >
                      <p className={`mb-0 ${Styles.changePassword}`}>
                        Change Password
                      </p>
                    </div>
                    <hr className={Styles.divider} />
                    <div
                      onClick={handleLogout}
                      className={`p-3 d-flex align-items-center justify-content-center ${Styles.logOutBack}`}
                    >
                      <p className={`mb-0 ${Styles.logOut}`}>Logout</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showChangePassword ? (
            <ChangePassword
              onSubmit={(data: string) => handleChangePassword(data)}
              onCancel={() => setShowChangePassword(false)}
            />
          ) : null}
          {toggleSideBar && (
            <div
              className={Styles.sidebarWrapper}
              style={{ width: toggleCollapsedView ? "80px" : "250px" }}
            >
              <Sidebar
                collapsedView={toggleCollapsedView}
                toggleCollapseSideBar={() =>
                  setToggleCollapsedView(!toggleCollapsedView)
                }
                toggleSideBar={(value: boolean) => setToggleSidebar(value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopBar;
