import React from "react";
import Styles from "./errorMessage.module.scss";

function ErrorMessage({ ...props }: any) {
  return (
    <p
      className={`${Styles.errorText} pt-2 pb-2 pr-2 mb-0`}
      data-testid={props.testId}
    >
      {props.children}
    </p>
  );
}

export default ErrorMessage;
