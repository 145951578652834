//Edit information regarding the Business or tech POC if any
import React from "react";
import Styles from "../../../../Shared/popup.module.scss";
import {
  checkIsEmpty,
  validPhoneNumber,
  validEmail,
  isValidName,
  isvalidEmailFirstChar,
  isNumeric,
} from "../../../../utils/validation";
import { publicEmail } from "../../../../constants/publicEmail";
import Input from "../../../../Shared/input/input";
import ErrorMessage from "../../../../Shared/error-message/errorMessage";
import Button from "../../../../Shared/button/button";
import {
  primaryButtonStyle,
  cancelButtonStyle,
  cancelButtonHoverStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../Shared/buttonSize";

function PocEdit({ onSubmit, onCancel, pocInfo, orgCountryCode }: any) {
  const [pocDetails, setPocDetails] = React.useState(pocInfo);
  const [error, setError] = React.useState({
    nameError: "",
    emailError: "",
    designationError: "",
    phoneNumberError: "",
  });
  const [validData, setValidData] = React.useState({
    name: false,
    email: false,
    designation: false,
    phone: false,
  });
  function checkName() {
    if (checkIsEmpty(pocDetails.name)) {
      setError((error) => ({
        ...error,
        nameError: "Name cannot be Empty",
      }));
      setValidData((validData) => ({
        ...validData,
        name: false,
      }));
      return false;
    }
    if (isValidName(pocDetails.name, false)) {
      setError((error) => ({
        ...error,
        nameError: "should contain alphabets only!",
      }));
      setValidData((validData) => ({
        ...validData,
        name: false,
      }));
      return false;
    }
    return true;
  }
  function checkEmail() {
    if (checkIsEmpty(pocDetails.email)) {
      setError((error) => ({
        ...error,
        emailError: "Email cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        email: false,
      }));
      return false;
    }
    if (!validEmail(pocDetails.email)) {
      setError((error) => ({
        ...error,
        emailError: "Please enter a valid email",
      }));
      setValidData((validData) => ({
        ...validData,
        email: false,
      }));
      return false;
    }
    if (isvalidEmailFirstChar(pocDetails.email)) {
      setError((error) => ({
        ...error,
        emailError: "Email Cannot start with a number",
      }));
      setValidData((validData) => ({
        ...validData,
        email: false,
      }));
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = pocDetails.email.split("@");
    if (publicEmail.includes(domain.toLocaleLowerCase())) {
      setError((error) => ({
        ...error,
        emailError: "Only work emails are allowed",
      }));
      setValidData((validData) => ({
        ...validData,
        email: false,
      }));
      return false;
    }
    return true;
  }
  function checkDesignation() {
    if (checkIsEmpty(pocDetails.designation)) {
      setError((error) => ({
        ...error,
        designationError: "Designation cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        designation: false,
      }));
      return false;
    }
    if (isValidName(pocDetails.designation, false)) {
      setError((error) => ({
        ...error,
        designationError: "should contain alphabets only!",
      }));
      setValidData((validData) => ({
        ...validData,
        designation: false,
      }));
      return false;
    }
    return true;
  }
  function checkPhoneNumber() {
    if (orgCountryCode !== "IN") {
      if (!isNumeric(pocDetails.phone) && pocDetails.phone !== "") {
        setError((error) => ({
          ...error,
          phoneNumberError: "Please enter a valid phone number",
        }));
        setValidData((validData) => ({
          ...validData,
          phone: false,
        }));
        return false;
      }
      return true;
    }
    if (checkIsEmpty(pocDetails.phone)) {
      setError((error) => ({
        ...error,
        phoneNumberError: "Phone number cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        phone: false,
      }));
      return false;
    }
    if (!validPhoneNumber(pocDetails.phone)) {
      setError((error) => ({
        ...error,
        phoneNumberError: "Please enter a valid phone number",
      }));
      setValidData((validData) => ({
        ...validData,
        phone: false,
      }));
      return false;
    }
    return true;
  }
  function handelCancel(event: any) {
    event.preventDefault();
    onCancel();
    return;
  }
  function handelFormSubmit(event: any) {
    event.preventDefault();
    const allCheckPassed = [
      checkName(),
      checkEmail(),
      checkDesignation(),
      checkPhoneNumber(),
    ].every(Boolean);
    if (!allCheckPassed) {
      return;
    }
    onSubmit({
      name: pocDetails.name.trim(),
      email: pocDetails.email.trim(),
      designation: pocDetails.designation.trim(),
      phone: pocDetails.phone.trim(),
    });
    return;
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`}>
        <form onSubmit={handelFormSubmit}>
          <div>
            <Input
              type="text"
              name="name"
              id="name-poc"
              autoComplete="off"
              value={pocDetails.name}
              hasError={error.nameError}
              isValid={validData.name}
              placeholder="Your Name"
              labelname="Name"
              maxLength={40}
              requiredAstrict
              onChange={(event: any) => {
                const value = event.target.value;
                setPocDetails((pocDetails: any) => ({
                  ...pocDetails,
                  name: value,
                }));
                setError((error) => ({
                  ...error,
                  nameError: "",
                }));
                setValidData((validData) => ({
                  ...validData,
                  name: true,
                }));
              }}
              onBlur={checkName}
            />
            <ErrorMessage>{error.nameError}</ErrorMessage>
          </div>
          <div>
            <Input
              type="email"
              name="email"
              id="email-poc"
              value={pocDetails.email}
              autoComplete="off"
              hasError={error.emailError}
              isValid={validData.email}
              placeholder="Enter Email"
              labelname="Email"
              requiredAstrict
              onChange={(event: any) => {
                const value = event.target.value;
                setPocDetails((pocDetails: any) => ({
                  ...pocDetails,
                  email: value,
                }));
                setError((error) => ({
                  ...error,
                  emailError: "",
                }));
                setValidData((validData) => ({
                  ...validData,
                  email: true,
                }));
              }}
              onBlur={checkEmail}
            />
            <ErrorMessage>{error.emailError}</ErrorMessage>
          </div>
          <div>
            <Input
              type="text"
              name="designation"
              id="designation-poc"
              autoComplete="off"
              value={pocDetails.designation}
              hasError={error.designationError}
              isValid={validData.designation}
              placeholder="Your Designation"
              maxLength={25}
              labelname="Designation"
              requiredAstrict
              onChange={(event: any) => {
                const value = event.target.value;
                setPocDetails((pocDetails: any) => ({
                  ...pocDetails,
                  designation: value,
                }));
                setError((error) => ({
                  ...error,
                  designationError: "",
                }));
                setValidData((validData) => ({
                  ...validData,
                  designation: true,
                }));
              }}
              onBlur={checkDesignation}
            />
            <ErrorMessage>{error.designationError}</ErrorMessage>
          </div>
          <div>
            <Input
              type="text"
              name="phoneNumber"
              id="phoneNumber-poc"
              value={pocDetails.phone}
              autoComplete="off"
              hasError={error.phoneNumberError}
              isValid={validData.phone}
              placeholder="Your Phone Number"
              labelname="Phone Number"
              requiredAstrict={orgCountryCode === "IN"}
              onChange={(event: any) => {
                const value = event.target.value;
                setPocDetails((pocDetails: any) => ({
                  ...pocDetails,
                  phone: value,
                }));
                setError((error) => ({
                  ...error,
                  phoneNumberError: "",
                }));
                setValidData((validData) => ({
                  ...validData,
                  phone: true,
                }));
              }}
              onBlur={checkPhoneNumber}
            />
            <ErrorMessage>{error.phoneNumberError}</ErrorMessage>
          </div>
          <div className="py-3 d-flex align-items-center justify-content-around">
            <div className="px-2">
              <Button
                size={MEDIUM}
                hoveredStyle={cancelButtonHoverStyle}
                style={cancelButtonStyle}
                onClick={handelCancel}
                type="button"
              >
                Cancel
              </Button>
            </div>
            <div className="px-2">
              <Button
                size={MEDIUM}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                onClick={handelFormSubmit}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PocEdit;
