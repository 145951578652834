import React, { useEffect, useRef } from "react";
import otpVerificationNew from "../../assets/images/otpVerificationNew.png";
import Button from "../button/button";
import { MEDIUM } from "../buttonSize";
import Styles from "./zpinMainPopup.module.scss";
import ZpinIntroBox from "./zpin-intro-box/zpinIntroBox";

import {
  submitButtonHoverStylePopup,
  submitButtonStylePopup,
} from "../buttonStyles";
import ErrorMessage from "../error-message/errorMessage";
import { checkIsEmpty, isValidOtp } from "../../utils/validation";
import ZpinCreateOrUpdate from "./zpin-create-or-update/zpinCreateOrUpdate";
import HaveZpin from "./have-zpin/haveZpin";

function ZpinMainPopup({
  zpinExists,
  toggleResetOtp,
  setToggleResetOtp,
  isLoading,
  onVerifyOtp,
  onCancel,
  resendOtp,
  handleZpinAuth,
  updateZpinStatus,
}: any) {
  const registeredEmail = localStorage.getItem("email");
  const [invalidOTP, setInvalidOTP] = React.useState(false);
  const lengthOfOtp = 6;
  const [otp, setOtp] = React.useState("");
  const inputs: any = Array(lengthOfOtp)
    .fill(null)
    .map((_, index) => index);
  let currentActiveInput = 0;
  const inputRef = useRef<any>([]);
  const [error, setError] = React.useState("");
  // const [isValid, setIsValid] = React.useState(true);
  const [validOtp, setValidOtp] = React.useState(true);
  const [resendOTPCount, setResendOTPCount] = React.useState(0);
  const [timer, setTimer] = React.useState(0);
  const [showZpinBox, setShowZpinBox] = React.useState(true);
  const [createZpin, setCreateZpin] = React.useState(false);
  const [forUpdateFlow, setForUpdateFlow] = React.useState(false);
  const [forOtpFlow, setForOtpFlow] = React.useState(false);
  const [haveZpin, setHaveZpin] = React.useState(zpinExists);
  const [zPinPopup, setZpinPopup] = React.useState(false);
  const [otpAuthPopup, setOtpAuthPopup] = React.useState(!zpinExists);

  useEffect(() => {
    // reset the otp in case of any error occurred
    if (toggleResetOtp) {
      resetAllInputs();
    }
    //call api to check if the zpin is already created
  }, [toggleResetOtp]);

  function validateOtp(e: any) {
    e.preventDefault();
    if (checkIsEmpty(otp)) {
      setError("Otp cannot be empty");
      return setInvalidOTP(true);
    }
    setToggleResetOtp(false);
    onVerifyOtp(null, otp, setInvalidOTPError);
  }

  function setInvalidOTPError() {
    setInvalidOTP(true);
    setError("OTP is invalid!, please re-enter");
    resetAllInputs();
    setTimeout(() => {
      setInvalidOTP(false);
      setError("");
    }, 2000);
  }

  function handleOtp(otpValue: string, index: number) {
    // To check whether OTP is numeric or not
    if (!isValidOtp(otpValue) && otpValue !== "") {
      inputRef.current[index].value = "";
      setError("OTP should be numeric");
      return setInvalidOTP(true);
    }
    // To check whether the user is removing the entered OTP.
    if (otpValue === "") {
      currentActiveInput = index - 1;
      const currentInput = inputRef.current[currentActiveInput];
      // To check whether the focused input is not less than 0
      if (currentActiveInput < 0) {
        return;
      }
      // To check whether the user is clearing the last input
      // if clears the last input than we remove two values else one
      if (otp.length === lengthOfOtp) {
        // const nextInput = inputRef.current[currentActiveInput + 1];
        const str = otp.substr(0, otp.length - 2);
        setOtp(str);
      } else {
        const str = otp.substr(0, otp.length - 1);
        setOtp(str);
      }
      // Remove disable state.
      currentInput.disabled = false;
      currentInput.value = "";
    } else {
      // Only accepts 6 digits in otp
      if (otp.length > lengthOfOtp - 1) {
        return;
      }
      setOtp((otp: string) => `${otp + otpValue}`);
      const currentInput = inputRef.current[index];
      // Add disable state once input is filled.
      otp.length < lengthOfOtp - 1 && (currentInput.disabled = true);
      currentActiveInput = index + 1;
    }
    // To check the verify button enable and disable
    if (otp.length === lengthOfOtp - 1) {
      setValidOtp(true);
    } else {
      setValidOtp(false);
    }
    setError("");
    const nextInput = inputRef.current[currentActiveInput];
    // To Check whether the focus does not exceed the number of input
    // else throw error of index out of bound
    if (currentActiveInput < lengthOfOtp && currentActiveInput >= 0) {
      nextInput.focus();
    }
  }

  // reset all input values in case the user selects reset password or any error occurred
  function resetAllInputs() {
    let i = 0;
    while (i < lengthOfOtp) {
      const currentInput = inputRef.current[i];
      currentInput.value = "";
      currentInput.disabled = false;
      i++;
    }
    setOtp("");
    setValidOtp(false);
    inputRef.current[currentActiveInput].focus();
  }

  function handleResendOtp() {
    resetAllInputs();
    // Call API to handle Resend OTP;
    resendOtp();
    setResendOTPCount(resendOTPCount + 1);
    // Resend Otp to be enabled in
    let resendOTPInSec = 30;
    setTimer(resendOTPInSec);
    const otpCount = setInterval(() => {
      resendOTPInSec -= 1;
      setTimer(resendOTPInSec);
      if (resendOTPInSec === 0) {
        clearInterval(otpCount);
      }
    }, 1000);
    if (resendOTPCount >= 2) {
      setError("You have exhausted the resend OTP limit");
    }
  }

  function handleZpinFlow() {
    setCreateZpin(true);
    setOtpAuthPopup(false);
    setZpinPopup(true);
  }

  function handleZpinCancel() {
    setShowZpinBox(false);
    onCancel();
  }

  function updateZpinFlow() {
    setForUpdateFlow(true);
    setHaveZpin(false);
    setZpinPopup(true);
  }

  function useOtpFlowInstead(invalidZpinCase: boolean = false) {
    if (invalidZpinCase) {
      setShowZpinBox(false);
      setOtpAuthPopup(true);
      setHaveZpin(false);
    } else {
      setZpinPopup(true);
      setHaveZpin(false);
      setForOtpFlow(true);
    }
    resendOtp();
  }

  function handleZpinAuthHave(
    zpin: string,
    otp: string,
    setInvalidZpinError: any
  ) {
    handleZpinAuth(zpin, null, setInvalidZpinError);
  }

  function afterSuccessfulCreation() {
    setZpinPopup(false);
    setHaveZpin(true);
    updateZpinStatus();
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "500px" }}>
        {otpAuthPopup && (
          <form>
            <div className="py-3 text-center">
              <img
                src={otpVerificationNew}
                alt="otp-verification"
                style={{ width: "100px" }}
              />
            </div>
            <div className="py-2 px-4">
              <p className={`mb-0 ${Styles.headingText}`}>OTP Authentication</p>
            </div>
            <div className="px-4">
              <p className={`mb-0 ${Styles.subText}`}>
                An OTP has been sent to <span>{registeredEmail}</span>
              </p>
            </div>
            <div style={{ width: "300px", margin: "0 auto" }}>
              <div className="py-2 d-flex align-items-center justify-content-center">
                {inputs.map((input: any, index: number) => {
                  return (
                    <div key={input} className="px-1">
                      <input
                        ref={(input) => (inputRef.current[index] = input)}
                        onClick={() => {
                          setInvalidOTP(false);
                          setError("");
                        }}
                        id={`${index}`}
                        type="text"
                        className={`${Styles.commonInputClass} ${
                          Styles.formControl
                        } ${invalidOTP ? Styles.wrongOtp : ""}`}
                        maxLength={1}
                        autoComplete="off"
                        autoFocus={currentActiveInput === index}
                        style={{ width: "40px", top: 50, left: 50 }}
                        onKeyUp={(event: any) => {
                          const otpValue = event.target.value;
                          handleOtp(otpValue, index);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="px-1 d-flex flex-column align-items-center">
                {error && <ErrorMessage forPopup={true}>{error}</ErrorMessage>}
                {resendOTPCount <= 2 && (
                  <div className={`ml-auto mr-auto ${error ? "px-2" : "p-2"}`}>
                    {timer > 0 ? (
                      <p className={`mb-0 ${Styles.subText}`}>
                        Resend OTP in {timer}:00s
                      </p>
                    ) : (
                      <p
                        className={`mb-0 ${Styles.resendOtpText}`}
                        onClick={handleResendOtp}
                      >
                        Resend OTP
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center px-4 py-2">
              <div className="px-2">
                <Button
                  type="submit"
                  hoveredStyle={submitButtonHoverStylePopup}
                  style={submitButtonStylePopup}
                  isLoading={isLoading}
                  disabled={!validOtp}
                  onClick={(e: any) => {
                    setTimer(0);
                    validateOtp(e);
                  }}
                  size={MEDIUM}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div></div>
            {showZpinBox && <ZpinIntroBox handleZpinFlow={handleZpinFlow} />}
            <div className="py-4 d-flex justify-content-center">
              <div className="d-inline-flex">
                <p
                  className={`mb-0 ${Styles.cancelText}`}
                  onClick={() => {
                    setTimer(0);
                    onCancel();
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          </form>
        )}
        {haveZpin && (
          <HaveZpin
            updateZpinFlow={updateZpinFlow}
            handleZpinAuthHave={handleZpinAuthHave}
            onCancel={onCancel}
            isLoading={isLoading}
            otpFlowInstead={useOtpFlowInstead}
          />
        )}

        {zPinPopup && (
          <ZpinCreateOrUpdate
            forUpdate={forUpdateFlow}
            forOtp={forOtpFlow}
            afterSuccessfulCreation={afterSuccessfulCreation}
            handleZpinAuth={(
              zpin: string,
              otp: string,
              setInvalidZpinError: any
            ) => handleZpinAuth(zpin, otp, setInvalidZpinError)}
            onCancel={() => handleZpinCancel()}
            revertBackToOTP={() => setShowZpinBox(false)}
            resendOtp={resendOtp}
            onVerifyOtp={onVerifyOtp}
          />
        )}
      </div>
    </div>
  );
}

export default ZpinMainPopup;
