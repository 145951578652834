// Component rendered when user skipped onboarding and on dashboard this component is rendered.
import React, { useEffect } from "react";
import Styles from "./checkList.module.scss";
import { ONBOARDING_STATUS } from "../../../../constants/onboardingStatuses";
import ChecklistItem from "./checklist-item/checklistItem";
import Button from "../../../../Shared/button/button";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../Shared/buttonStyles";
import { COMPRESSED } from "../../../../Shared/buttonSize";
import { useHistory } from "react-router-dom";
import KycDetailsPopUp from "../../billing/kyc-details-pop-up/kycDetailsPopUp";
import Toast from "../../../../Shared/toast/toast";
import { callApi } from "../../../../api/fetch";
import { SUCCESS } from "../../../../Shared/colors";
import warningIconSvg from "../../../../assets/images/warning-icon.svg";
import { useTitle } from "../../../../Shared/useTitle";

function CheckList({ onboardingStatus, countryCode }: any) {
  const test_app_id = localStorage.getItem("test_app_id");
  const history = useHistory();
  const [requestRegistered, setRequestRegistered] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [
    toggleAccountActivationPopUp,
    setToggleAccountActivationPopUp,
  ] = React.useState(false);

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  useEffect(() => {
    if (
      onboardingStatus === ONBOARDING_STATUS.SERVICES_SUBSCRIBED ||
      onboardingStatus === ONBOARDING_STATUS.SERVICES_ADDED
    ) {
      setCurrentStep(2);
    }
    if (onboardingStatus === ONBOARDING_STATUS.ONBOARDING_COMPLETED) {
      setCurrentStep(3);
    }
    if (onboardingStatus === ONBOARDING_STATUS.ACTIVATION_REQUESTED) {
      setRequestRegistered(true);
      setCurrentStep(3);
    }
    if (onboardingStatus === ONBOARDING_STATUS.ACCOUNT_ACTIVATED) {
      setCurrentStep(4);
    }
    if (onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED) {
      setRequestRegistered(false);
      setCurrentStep(5);
    }
  }, [onboardingStatus]);

  async function requestAccountActivation() {
    setLoading(true);
    try {
      await callApi("/events?action=account_activation_request", {
        method: "POST",
      });
      setLoading(false);
      setRequestRegistered(true);
      setToggleAccountActivationPopUp(false);
    } catch (err) {
      setLoading(false);
      setToggleAccountActivationPopUp(false);
      toastTimeout(true, "error", (err as Error).message);
    }
  }
  useTitle("Zoop Customer Platform | Dashboard Checklist");
  return (
    <div className="container-fluid">
      <div className="row px-3">
        <div className="col-md-12 col-xl-8 p-3">
          <div
            className={`${Styles.card} ${Styles.cardAnimation}`}
            style={{ height: "auto" }}
          >
            <div className={`p-3 ${Styles.cardHeader}`}>
              <p className={`mb-0 px-2 ${Styles.headingText}`}>Account Setup</p>
            </div>
            <div className="px-3 pt-3 pb-4">
              {/* Subscribe products  */}
              <ChecklistItem
                isStepCompleted={currentStep > 1}
                highlightCurrentStep={currentStep === 1}
                listHeader="Subscribe to Products"
                stepNumber={1}
              >
                <div className="pt-3 d-flex align-items-start">
                  <div
                    style={{
                      flexBasis: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={Styles.progress_line}
                      style={{
                        background: currentStep > 1 ? SUCCESS : "#ddd",
                        height: currentStep === 1 ? "100px" : "60px",
                      }}
                    ></div>
                  </div>
                  <div style={{ flexBasis: "90%" }}>
                    <p className={`mb-0 ${Styles.step_text}`}>
                      Choose the product(s) you wish to test with us and get
                      credits free for your initial testing.
                    </p>
                    {currentStep === 1 && (
                      <div className="d-flex align-items-center">
                        <div className="pt-3 pb-2">
                          <Button
                            style={primaryButtonStyle}
                            hoveredStyle={primaryButtonHoverStyle}
                            size={COMPRESSED}
                            onClick={() =>
                              history.push("/application/product-catalog")
                            }
                            id="z-billing-btn-product-catalog"
                          >
                            Product Catalog
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ChecklistItem>
              {/* Generate API Key  */}
              <ChecklistItem
                isStepCompleted={currentStep > 2}
                highlightCurrentStep={currentStep === 2}
                listHeader="Generate Test API"
                stepNumber={2}
              >
                <div className="pt-3 d-flex align-items-start">
                  <div
                    style={{
                      flexBasis: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={Styles.progress_line}
                      style={{
                        background: currentStep > 2 ? SUCCESS : "#ddd",
                        height: currentStep === 2 ? "100px" : "60px",
                      }}
                    ></div>
                  </div>
                  <div style={{ flexBasis: "90%" }}>
                    <p className={`mb-0 ${Styles.step_text}`}>
                      You need to generate an API key to integrate and access
                      our APIs on{" "}
                      <span style={{ color: "#202020", fontWeight: "bold" }}>
                        Production
                      </span>
                      . Use add key to generate a new key to integrate with the{" "}
                      <span style={{ color: "#202020", fontWeight: "bold" }}>
                        Test
                      </span>{" "}
                      App.
                    </p>
                    {currentStep === 2 && (
                      <div className="d-flex align-items-center">
                        <div className="pt-3 pb-2">
                          <Button
                            style={primaryButtonStyle}
                            hoveredStyle={primaryButtonHoverStyle}
                            size={COMPRESSED}
                            onClick={() =>
                              history.push(
                                `/application/manage-apps/${test_app_id}`
                              )
                            }
                            id="z-billing-btn-manage-app"
                          >
                            Manage App
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ChecklistItem>
              {/* Account Activation Requested  */}
              <ChecklistItem
                isStepCompleted={currentStep > 3}
                highlightCurrentStep={currentStep === 3}
                listHeader="Activate Production"
                stepNumber={3}
              >
                <div className="pt-3 d-flex align-items-start">
                  <div
                    style={{
                      flexBasis: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={Styles.progress_line}
                      style={{
                        background: currentStep > 3 ? SUCCESS : "#ddd",
                        height: currentStep === 3 ? "100px" : "60px",
                      }}
                    ></div>
                  </div>
                  <div style={{ flexBasis: "90%" }}>
                    <p className={`mb-0 ${Styles.step_text}`}>
                      After Testing the selected API, you are required to
                      request for{" "}
                      <span style={{ color: "#202020", fontWeight: "bold" }}>
                        Production access
                      </span>{" "}
                      so that can be verified to ensure compliance with
                      regulatory bodies.
                    </p>
                    {currentStep === 3 && (
                      <div className="d-flex align-items-center">
                        <div className="pt-3 pb-2">
                          {requestRegistered ? (
                            <p
                              className={`mb-0 ${Styles.activation_requested}`}
                            >
                              Activation requested
                            </p>
                          ) : (
                            <Button
                              style={primaryButtonStyle}
                              hoveredStyle={primaryButtonHoverStyle}
                              size={COMPRESSED}
                              onClick={() =>
                                setToggleAccountActivationPopUp(true)
                              }
                              id="z-billing-btn-activation-production"
                            >
                              Activate Production
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ChecklistItem>
            </div>
          </div>
        </div>
        <div className="col-xl-4 py-3 d-none d-xl-block">
          <div
            className={`${Styles.onboarding_card} ${Styles.cardAnimation} d-flex align-items-center justify-content-center`}
          >
            <div className={Styles.text_wrapper}>
              <div className="py-3 text-center">
                <img
                  src={warningIconSvg}
                  alt="warning"
                  className={Styles.warning_image}
                />
              </div>
              <div className="py-2">
                <p className={`mb-0 ${Styles.onboarding_text}`}>
                  Your dashboard is locked{" "}
                </p>
                <p className={Styles.onboarding_text_secondary}>
                  Complete this step to get in action
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggleAccountActivationPopUp && (
        <KycDetailsPopUp
          isLoading={loading}
          customerType={countryCode === "IN" ? "national" : "international"}
          onCancel={() => setToggleAccountActivationPopUp(false)}
          onSendOverEmail={() => requestAccountActivation()}
        />
      )}
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
    </div>
  );
}

export default CheckList;
