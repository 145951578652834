import React from "react";

function subscriptionSVG(classes: any) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 55 55"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path
        d="M22.9167 6.875V48.125C22.9167 49.9484 22.1923 51.697 20.903 52.9864C19.6137 54.2757 17.865 55 16.0417 55H6.875C5.05164 55 3.30295 54.2757 2.01364 52.9864C0.724329 51.697 0 49.9484 0 48.125V6.875C0 5.05164 0.724329 3.30295 2.01364 2.01364C3.30295 0.724329 5.05164 0 6.875 0H16.0417C17.865 0 19.6137 0.724329 20.903 2.01364C22.1923 3.30295 22.9167 5.05164 22.9167 6.875ZM48.125 35.9792H34.375C32.5516 35.9792 30.803 36.7035 29.5136 37.9928C28.2243 39.2821 27.5 41.0308 27.5 42.8542V48.125C27.5 49.9484 28.2243 51.697 29.5136 52.9864C30.803 54.2757 32.5516 55 34.375 55H48.125C49.9484 55 51.697 54.2757 52.9864 52.9864C54.2757 51.697 55 49.9484 55 48.125V42.8542C55 41.0308 54.2757 39.2821 52.9864 37.9928C51.697 36.7035 49.9484 35.9792 48.125 35.9792ZM48.125 0H34.375C32.5516 0 30.803 0.724329 29.5136 2.01364C28.2243 3.30295 27.5 5.05164 27.5 6.875V24.5208C27.5 26.3442 28.2243 28.0929 29.5136 29.3822C30.803 30.6715 32.5516 31.3958 34.375 31.3958H48.125C49.9484 31.3958 51.697 30.6715 52.9864 29.3822C54.2757 28.0929 55 26.3442 55 24.5208V6.875C55 5.05164 54.2757 3.30295 52.9864 2.01364C51.697 0.724329 49.9484 0 48.125 0Z"
        fill="url(#paint0_linear_1845_1236)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1845_1236"
          x1="2.5"
          y1="2"
          x2="53"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0075F3" />
          <stop offset="0.494792" stopColor="#66B0FF" />
          <stop offset="1" stopColor="#9ECDFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default subscriptionSVG;
