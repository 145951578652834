import React from "react";

function trialCenterSVGNew(classes: any) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <circle cx="16" cy="8" r="5" />
      <circle cx="7" cy="15" r="4" />
      <circle cx="16.5" cy="18.5" r="2.5" />
    </svg>
  );
}

export default trialCenterSVGNew;
