export const getYears = (startingYear: number) => {
  const currentYear = new Date().getFullYear();
  const startYear = startingYear;
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const yearOptions = years.map((year) => ({
    label: year.toString(),
    value: year,
  }));

  return yearOptions;
};
