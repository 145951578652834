import React from "react";
import style from "./chip.module.scss";

function Chip({ message, className, type }: any) {
  let chipTypeStyle = style.success;

  if (type === "error") {
    chipTypeStyle = style.error;
  }

  if (type === "processing") {
    chipTypeStyle = style.processing;
  }

  return (
    <div className={style.chip + " " + chipTypeStyle + " " + className}>
      {message}
    </div>
  );
}

export default Chip;
