import React from "react";
import Button from "../../../../Shared/button/button";
import { MEDIUM } from "../../../../Shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../Shared/buttonStyles";
import ErrorMessage from "../../../../Shared/error-message/errorMessage";
import Input from "../../../../Shared/input/input";
import Styles from "../../../../Shared/popup.module.scss";
import { checkIsEmpty } from "../../../../utils/validation";
import InfoMessage from "../../../../Shared/info-message/info";
import { callApi } from "../../../../api/fetch";
import {
  MAX_PAYMENT_AMOUNT_ALLOWED,
  MIN_PAYMENT_AMOUNT_ALLOWED,
} from "../../../../utils/constants";

function AddPaymentPopUp({ onCancel, toastTimeout }: any) {
  const [error, setError] = React.useState("");
  const [addPaymentAmount, setAddPaymentAmount] = React.useState("");
  const [validPaymentAmount, setValidPaymentAmount] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const numberRegexp = /[^\0-9]/;

  function checkAmountBalance() {
    if (checkIsEmpty(addPaymentAmount)) {
      setError("Amount can not be empty");
      setValidPaymentAmount(false);
      return false;
    }
    if (numberRegexp.test(addPaymentAmount)) {
      setError("Enter a valid Amount");
      setValidPaymentAmount(false);
      return false;
    }
    if (Number(addPaymentAmount) < Number(MIN_PAYMENT_AMOUNT_ALLOWED)) {
      setError("Amount should not be less than 1000");
      setValidPaymentAmount(false);
      return false;
    }

    if (Number(addPaymentAmount) > Number(MAX_PAYMENT_AMOUNT_ALLOWED)) {
      setError("Amount should not be more than 50000");
      setValidPaymentAmount(false);
      return false;
    }
    return true;
  }

  function onSubmit(e: any) {
    e.preventDefault();
    if (!checkAmountBalance()) {
      return;
    }

    setLoading(true);
    generatePaymentLink();
  }

  const generatePaymentLink = async () => {
    // setOverAllLoading(true);
    try {
      //   const organisationId = localStorage.getItem("organisation_id");
      const response = await callApi(`/payment/create`, {
        method: "POST",
        data: {
          amount: Number(addPaymentAmount),
        },
      });

      if (response && response?.link_url) {
        openInNewTab(response?.link_url);
      }

      setLoading(false);
      onCancel && onCancel();
    } catch (err) {
      setLoading(false);
      toastTimeout && toastTimeout(true, "error", (err as Error).message);
    }
  };

  function openInNewTab(url: string) {
    window.open(url, "_blank")?.focus();
  }

  const validateOnChange = (value: string) => {
    if (Number(value) < Number(MIN_PAYMENT_AMOUNT_ALLOWED)) {
      setError("Amount should not be less than 1000");
      setValidPaymentAmount(false);
      return false;
    }

    if (Number(value) > Number(MAX_PAYMENT_AMOUNT_ALLOWED)) {
      setError("Amount should not be more than 50000");
      setValidPaymentAmount(false);
      return false;
    }
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`}>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <p className="title_semibold_text  mb-4">Add balance</p>
            <Input
              type="number"
              name="add_wallet_balace"
              id="add_wallet_balace_billing"
              max={MAX_PAYMENT_AMOUNT_ALLOWED}
              min={MIN_PAYMENT_AMOUNT_ALLOWED}
              autoComplete="off"
              placeholder="Enter Amount"
              hasError={error}
              isValid={validPaymentAmount}
              onChange={(event: any) => {
                let updatedStr = "";
                const value = event.target.value;
                const stringArr = value.split(",");
                stringArr.map((val: string) => (updatedStr += val));
                setAddPaymentAmount(updatedStr.trim());
                setError("");
                validateOnChange(updatedStr.trim());
                setValidPaymentAmount(true);
              }}
              onBlur={checkAmountBalance}
              customInputClass={Styles.customAlertClass}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </div>
          <div className="mt-4">
            <InfoMessage>
              Note: Add
              <span className="font-weight-bold"> up to ₹50,000 </span> to your
              wallet. For higher amounts, contact <u>accounts@zoop.one</u>
            </InfoMessage>
          </div>
          <div className="pt-3 d-flex align-items-center justify-content-between">
            <div className="pr-2 w-100">
              <Button
                size={MEDIUM}
                onClick={() => onCancel && onCancel()}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                type="button"
                className="bg-tranparent w-100"
                btnTextClass="primary-color"
                id="z-billing-btn-enablelater"
              >
                Cancel
              </Button>
            </div>
            <div className="pl-2  w-100">
              <Button
                size={MEDIUM}
                // onClick={onSubmit}
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                type="submit"
                className="w-100"
                id="z-billing-btn-update"
                disabled={!validPaymentAmount || loading}
                isLoading={loading}
              >
                Proceed
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddPaymentPopUp;
