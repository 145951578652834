import validator from "validator";

const specialCharRegexp = /[!@#$%^&*(),.?":{}|<>]/;
const lowercaseRegexp = /[a-z]/;
const uppercaseRegexp = /[A-Z]/;
const alphaNumericRegexp = /^[a-zA-Z0-9 ]+$/;
const alphabetRegExp = /^[a-zA-Z ]+$/;
const numberRegexp = /\d/;
const onlyNumberRegexp = /^[0-9]*$/;

// Gstin regexp link https://stackoverflow.com/a/45095795
const GSTINRegexp = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
const UnregisteredGSTINRegexp = /^\d{12}[A-Z]{3}$/;
const GSTINRegexpForeign = /^[0-9A-Z]{2}17[A-Z]{3}[0-9A-Z]{8}/;

// Pan regexp link https://www.regextester.com/100102
const PanRegexp = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;

const bankAccountRegex = /^\d{9,18}$/;
const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const dlRegex = /^[a-zA-Z0-9]{10,20}$/;

const customerFileNumberRegex = /^([a-zA-Z0-9]{12,15})$/;
const passportRegex = /^(?!^0+$)[a-zA-Z0-9]{8,20}$/;
const nameRegex = /[A-Za-z]$/;
const vehicleNumberRegex = /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{1,4}$/;
const vehicleRegistrationNumberRegex = /^[a-zA-Z0-9]{7,12}$/;
const alternVehilceNumberRegex = /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,3}[0-9]{1,4}$/;
const bharatVehicleNumberRegex = /^[0-9]{2}BH[0-9]{1,4}[A-Z]{1,2}$/;
const chassisNumberRegex = /[A-Z0-9]{5}/;
const voterIdRegex = /^([a-zA-Z]){3}([0-9]){7}?$/;
const rcChassisRegex = /^.{1,23}$/;
const aadhaarRegex = /^[0-9]{12}$/;
const UANRegexp = /^[0-9]{12}$/;
const udyogAadhaarRegex = /^(?:([a-zA-Z]{2}[0-9]{2}[a-zA-Z]{1}[0-9]{7})|(UDYAM-[a-zA-Z]{2}-\d{2}-\d{7}))$/i;
const otpRegex = /^[0-9]{6}$/;
const mobileOtpRegex = /^[0-9]{4,6}$/;
const cinRegex = /^(?:([L|U]{1}[0-9]{5}[A-Za-z]{2}[0-9]{4}[A-Za-z]{3}[0-9]{6})|([A-Z]{3}-\d{4})|([F][A-Z]{3}-\d{4})|([F]\d{5}))$/;

const fssaiRegexp = /^[0-9]{14}$/;
const emailOtpRegex = /^[\w-]+(\.[\w-]+)*@(ac.in|edu.in|res.in|zoop.one|)$/;
const pincodeRegix = /^[1-9][0-9]{5}$/;

const tanRegex = /^[a-zA-Z]{4}\d{5}[a-zA-Z]$/;
const aadhaarNumberLastFourDigit = /^[0-9]{4}$/;
const upiIdRegex = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z._]{3,49}$/;

export const bypassCaptchaEmail = [
  "anagha.sonare+1@zoop.one",
  "anagha.sonare@zoop.one",
  "mayuri+9@zoop.one",
  "mayuri+10@zoop.one",
  "mayuri+11@zoop.one",
];

function passwordMinLength(password: string): boolean {
  return validator.isLength(password, { min: 8 });
}
function passwordHasSpecialCharacter(password: string): boolean {
  return specialCharRegexp.test(password);
}
function passwordHasLoweCase(password: string): boolean {
  return lowercaseRegexp.test(password);
}
function passwordHasUppercase(password: string): boolean {
  return uppercaseRegexp.test(password);
}
function passwordHasNumber(password: string): boolean {
  return numberRegexp.test(password);
}
export function isNumberOnly(number: string): boolean {
  return onlyNumberRegexp.test(number);
}
export function validEmail(email: string) {
  return validator.isEmail(email);
}

export function validPincode(pinCode: string) {
  if (pinCode.length < 6 || pinCode.length > 6) {
    return false;
  }

  return pincodeRegix.test(pinCode);
}

export function validEmailOtp(email: string, lowerCaseOnly: boolean = true) {
  return emailOtpRegex.test(lowerCaseOnly ? email : email.toLowerCase());
}
export function isvalidEmailFirstChar(email: string) {
  return numberRegexp.test(email[0]);
}
export function validRcChassisNumber(chassisNumber: string) {
  return rcChassisRegex.test(chassisNumber);
}
export function validBankAccountNumber(bankAccountNumber: string): boolean {
  return bankAccountRegex.test(bankAccountNumber);
}

export function validIFSCNumber(ifscNumber: string): boolean {
  return ifscRegex.test(ifscNumber);
}

export function validDLNumber(dlNumber: string): boolean {
  return dlRegex.test(dlNumber);
}

export function validAadhaarNumberLastFourDigit(number: string): boolean {
  return aadhaarNumberLastFourDigit.test(number);
}

export function validPassportNumber(passportNumber: string): boolean {
  return passportRegex.test(passportNumber);
}

export function validCustomerFileNumber(customerFileNumber: string): boolean {
  return customerFileNumberRegex.test(customerFileNumber);
}

export function validName(name: string): boolean {
  return nameRegex.test(name);
}

export function validVehicleRegNumber(vehicleRegNumber: string): boolean {
  return (
    vehicleNumberRegex.test(vehicleRegNumber) ||
    alternVehilceNumberRegex.test(vehicleRegNumber) ||
    bharatVehicleNumberRegex.test(vehicleRegNumber) ||
    vehicleRegistrationNumberRegex.test(vehicleRegNumber)
  );
}

export function validChassisNumber(chassisNumber: string): boolean {
  return chassisNumberRegex.test(chassisNumber);
}

export function validVoterNumber(epicNumber: string): boolean {
  return voterIdRegex.test(epicNumber);
}

export function validAadhaarNumber(aadhaarNumber: string): boolean {
  return aadhaarRegex.test(aadhaarNumber);
}

export function validUdyogAadhaarNumber(udyogAadhaarNumber: string): boolean {
  return udyogAadhaarRegex.test(udyogAadhaarNumber);
}

export function validTanNumber(tanNumber: string): boolean {
  return tanRegex.test(tanNumber);
}

export function validOtpNumber(otp: string): boolean {
  return otpRegex.test(otp);
}

export function validMobileOtpNumber(otp: string): boolean {
  return mobileOtpRegex.test(otp);
}

export function validCinNumber(cinNumber: string): boolean {
  return cinRegex.test(cinNumber);
}

export function isPasswordValid(password: string) {
  const checks = {
    hasMinLength: false,
    hasSpecialChar: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
  };

  if (passwordMinLength(password)) {
    checks.hasMinLength = true;
  }
  if (passwordHasSpecialCharacter(password)) {
    checks.hasSpecialChar = true;
  }
  if (passwordHasLoweCase(password)) {
    checks.hasLowerCase = true;
  }
  if (passwordHasUppercase(password)) {
    checks.hasUpperCase = true;
  }
  if (passwordHasNumber(password)) {
    checks.hasNumber = true;
  }

  return checks;
}

export function checkUAN(UANNumber: string): boolean {
  return UANRegexp.test(UANNumber);
}

export function checkPAN(PANNumber: string): boolean {
  return PanRegexp.test(PANNumber);
}

export function checkFSSAI(FSSAINumber: string): boolean {
  return fssaiRegexp.test(FSSAINumber);
}
export function checkUpiId(upiId: string): boolean {
  return upiIdRegex.test(upiId);
}

export function checkGSTIN(GSTINNnumber: string): boolean {
  return (
    GSTINRegexp.test(GSTINNnumber) || GSTINRegexpForeign.test(GSTINNnumber)
  );
}

export function checkUnregisteredGST(UnregisteredGSTNnumber: string): boolean {
  return UnregisteredGSTINRegexp.test(UnregisteredGSTNnumber);
}

export function validPhoneNumber(phoneNumber: string): boolean {
  return validator.isMobilePhone(phoneNumber, "en-IN");
}
export function checkIsEmpty(validationString: string): boolean {
  return validator.isEmpty(validationString, { ignore_whitespace: true });
}
export function isValidOtp(otp: string): boolean {
  return validator.isNumeric(otp, { no_symbols: true });
}

export function isValidZpin(zpin: string): boolean {
  return validator.isNumeric(zpin, { no_symbols: true });
}

export function isNumeric(phoneNumber: string): boolean {
  return validator.isNumeric(phoneNumber, { no_symbols: true });
}

export function isValidName(
  name: string,
  includeNumericChar: boolean
): boolean {
  if (includeNumericChar) {
    return !alphaNumericRegexp.test(name) || specialCharRegexp.test(name);
  }
  return !alphabetRegExp.test(name) || specialCharRegexp.test(name);
}

export function isValidIp(ip: string): boolean {
  return validator.isIP(ip);
}

export function validNameCheck(name: any) {
  const pattern = /[^a-zA-Z0-9\s]/g;

  if (pattern.test(name)) {
    return false;
  }

  if (!name.replace(/\s/g, "").length) {
    return false;
  }

  if (name.length < 2) {
    return false;
  }
  return true;
}
