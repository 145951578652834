export const getFinancialYears = (startingYear: number) => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const startYear = startingYear;
  const endYear = nextYear;
  const financialYears = [];

  for (let year = startYear; year < endYear; year++) {
    const label = `${year}-${(year + 1).toString().slice(-2)}`;
    financialYears.push({ value: label, label: label });
  }

  return financialYears.sort((a, b) => a.value.localeCompare(b.value));
};
