import React from "react";
import Styles from "./info.module.scss";

function InfoMessage({ message, clasName, children }: any) {
  return (
    <div
      className={`d-flex align-items-center py-3 px-3 ${Styles.info_back} ${
        clasName ? clasName : ""
      }`}
    >
      <div className="px-1 d-flex align-items-center">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 15C9.80222 15 9.60888 14.9414 9.44443 14.8315C9.27999 14.7216 9.15181 14.5654 9.07613 14.3827C9.00044 14.2 8.98063 13.9989 9.01922 13.8049C9.05781 13.6109 9.15305 13.4327 9.2929 13.2929C9.43275 13.153 9.61093 13.0578 9.80491 13.0192C9.9989 12.9806 10.2 13.0004 10.3827 13.0761C10.5654 13.1518 10.7216 13.28 10.8315 13.4444C10.9414 13.6089 11 13.8022 11 14C11 14.2652 10.8946 14.5196 10.7071 14.7071C10.5196 14.8946 10.2652 15 10 15ZM11 11C11 11.2652 10.8946 11.5196 10.7071 11.7071C10.5196 11.8946 10.2652 12 10 12C9.73479 12 9.48043 11.8946 9.2929 11.7071C9.10536 11.5196 9 11.2652 9 11V6C9 5.73478 9.10536 5.48043 9.2929 5.29289C9.48043 5.10536 9.73479 5 10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6V11Z"
            fill="#0075F3"
          />
        </svg>
      </div>
      <div className="px-2">
        <p className={`mb-0 ${Styles.info_text}`}>{children || message}</p>
      </div>
    </div>
  );
}

export default InfoMessage;
