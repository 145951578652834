import React from "react";
import { SUCCESS } from "../colors";

function TickSvg({
  color = SUCCESS,
  width = "20",
  height = "20",
  margin = "0px",
}: any) {
  return (
    <svg
      style={{ fill: color, marginBottom: margin }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.958 20.958"
    >
      <g
        id="Group_39"
        data-name="Group 39"
        transform="translate(-1139.001 -273)"
      >
        <path
          id="Union_8"
          d="M3.98 17.933a1.978 1.978 0 1 1 0-3.956h3.9V-.086A1.912 1.912 0 0 1 9.8-2a1.913 1.913 0 0 1 1.908 1.914v17.415a.478.478 0 0 1-.386.469.492.492 0 0 1-.34.136z"
          data-name="Union 8"
          transform="rotate(45 245.595 1524.995)"
        />
      </g>
    </svg>
  );
}

export default TickSvg;

export function TickSvgFill({ color = SUCCESS }: any) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.0002 0.333252C13.7038 0.333252 10.4815 1.31073 7.74067 3.14209C4.99985 4.97345 2.86364 7.57643 1.60218 10.6219C0.340717 13.6673 0.0106619 17.0184 0.653749 20.2514C1.29684 23.4844 2.88418 26.4542 5.21506 28.785C7.54593 31.1159 10.5156 32.7033 13.7487 33.3463C16.9817 33.9894 20.3328 33.6594 23.3782 32.3979C26.4237 31.1365 29.0266 29.0002 30.858 26.2594C32.6894 23.5186 33.6668 20.2963 33.6668 16.9999C33.6668 14.8112 33.2357 12.644 32.3982 10.6219C31.5606 8.59977 30.3329 6.76245 28.7853 5.21481C27.2376 3.66716 25.4003 2.4395 23.3782 1.60193C21.3561 0.764348 19.1889 0.333252 17.0002 0.333252ZM24.8502 13.1833L16.5168 21.5166C16.3619 21.6728 16.1776 21.7968 15.9745 21.8814C15.7714 21.966 15.5535 22.0096 15.3335 22.0096C15.1135 22.0096 14.8956 21.966 14.6925 21.8814C14.4894 21.7968 14.3051 21.6728 14.1502 21.5166L10.8168 18.1833C10.503 17.8694 10.3267 17.4438 10.3267 16.9999C10.3267 16.5561 10.503 16.1304 10.8168 15.8166C11.1307 15.5027 11.5563 15.3264 12.0002 15.3264C12.444 15.3264 12.8697 15.5027 13.1835 15.8166L15.3335 17.9833L22.4835 10.8166C22.7973 10.5027 23.223 10.3264 23.6668 10.3264C24.1107 10.3264 24.5363 10.5027 24.8502 10.8166C25.164 11.1304 25.3403 11.5561 25.3403 11.9999C25.3403 12.4438 25.164 12.8694 24.8502 13.1833Z" />
    </svg>
  );
}
