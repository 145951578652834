//Welcome tranistion to head towards application if in any case user skips onboarding.
import React from "react";
import Styles from "./transitionPopUp.module.scss";
import Loading from "../../../Shared/loading/loading";
import { ACCENTCOLOR } from "../../../Shared/colors";

function TransitionPopUp({ headingText, ...props }: any) {
  return (
    <div className={`d-flex justify-content-center ${Styles.overlay}`}>
      <div
        className={`p-4 ${Styles.popUpContent} d-flex align-items-center justify-content-center`}
      >
        <div>
          <div className="py-5">
            <p className={`mb-0 text-center ${Styles.headingText}`}>
              {headingText}
            </p>
          </div>
          <Loading loadingColor={ACCENTCOLOR} />
          <div className="py-5 px-4">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default TransitionPopUp;
