import React from "react";
import { SECONDARYCOLOR } from "../colors";

const ArrowGroup = ({
  color = SECONDARYCOLOR,
  width = "28",
  height = "16",
  style,
  single,
}: any) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={single ? "0 0 23 16" : "0 0 28 16"}
      style={{ ...(style ? { ...style, fill: color } : { fill: color }) }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {single ? (
        <path
          d="M14.0768 7.65895C14.0688 7.65395 14.058 7.65195 14.0499 7.64695L8.8643 4.15595C8.57255 3.96095 8.0993 3.96095 7.80755 4.15595C7.80486 4.15795 7.80351 4.15995 7.80217 4.16195C7.73152 4.20534 7.67447 4.25968 7.63499 4.32117C7.59551 4.38266 7.57456 4.44982 7.57361 4.51795L7.57361 11.501C7.57512 11.5703 7.59709 11.6386 7.63798 11.701C7.67886 11.7634 7.73767 11.8183 7.81024 11.862L7.80755 11.864C7.95434 11.9583 8.1419 12.0103 8.33593 12.0103C8.52995 12.0103 8.71751 11.9583 8.8643 11.864L14.0768 8.36495C14.1455 8.32139 14.2006 8.26728 14.2384 8.20633C14.2762 8.14539 14.2958 8.07907 14.2958 8.01195C14.2958 7.94484 14.2762 7.87852 14.2384 7.81758C14.2006 7.75663 14.1455 7.70251 14.0768 7.65895Z"
          fill="#0075F3"
        />
      ) : (
        <>
          <path
            d="M13.5884 7.65895C13.5804 7.65395 13.5696 7.65195 13.5615 7.64695L8.3759 4.15595C8.08415 3.96095 7.61089 3.96095 7.31914 4.15595C7.31645 4.15795 7.31511 4.15995 7.31377 4.16195C7.24312 4.20534 7.18607 4.25968 7.14659 4.32117C7.10711 4.38266 7.08616 4.44982 7.08521 4.51795L7.08521 11.501C7.08671 11.5703 7.10869 11.6386 7.14957 11.701C7.19046 11.7634 7.24927 11.8183 7.32183 11.862L7.31914 11.864C7.46594 11.9583 7.65349 12.0103 7.84752 12.0103C8.04155 12.0103 8.2291 11.9583 8.3759 11.864L13.5884 8.36495C13.6571 8.32139 13.7122 8.26728 13.75 8.20633C13.7878 8.14539 13.8074 8.07907 13.8074 8.01195C13.8074 7.94484 13.7878 7.87852 13.75 7.81758C13.7122 7.75663 13.6571 7.70251 13.5884 7.65895Z"
            fill="#0075F3"
          />
          <path
            d="M18.9663 7.65895C18.9582 7.65395 18.9475 7.65195 18.9394 7.64695L13.7538 4.15595C13.462 3.96095 12.9888 3.96095 12.697 4.15595C12.6943 4.15795 12.693 4.15995 12.6916 4.16195C12.621 4.20534 12.5639 4.25968 12.5245 4.32117C12.485 4.38266 12.464 4.44982 12.4631 4.51795V11.501C12.4646 11.5703 12.4866 11.6386 12.5274 11.701C12.5683 11.7634 12.6271 11.8183 12.6997 11.862L12.697 11.864C12.8438 11.9583 13.0314 12.0103 13.2254 12.0103C13.4194 12.0103 13.607 11.9583 13.7538 11.864L18.9663 8.36495C19.0349 8.32139 19.0901 8.26728 19.1279 8.20633C19.1657 8.14539 19.1853 8.07907 19.1853 8.01195C19.1853 7.94484 19.1657 7.87852 19.1279 7.81758C19.0901 7.75663 19.0349 7.70251 18.9663 7.65895Z"
            fill="#0075F3"
          />
        </>
      )}
    </svg>
  );
};

export default ArrowGroup;
