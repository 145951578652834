import React from "react";
import { SECONDARYCOLOR } from "../colors";

function CrossSvg({ color = SECONDARYCOLOR, width = "7", height = "7" }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25.468 25.471"
      style={{ fill: color }}
    >
      <path
        id="Union_10"
        d="M9580.667 18462.982a3.675 3.675 0 0 1 0-5.2l6.457-6.455-6.457-6.455a3.68 3.68 0 0 1 5.2-5.205l6.456 6.459 6.454-6.453a3.676 3.676 0 0 1 5.2 5.2l-6.454 6.453 6.454 6.457a3.676 3.676 0 0 1-5.2 5.2l-6.456-6.455-6.454 6.455a3.674 3.674 0 0 1-5.2 0z"
        data-name="Union 10"
        transform="translate(-9579.59 -18438.592)"
      />
    </svg>
  );
}

export default CrossSvg;
