import React from "react";
import Styles from "./progressBar.module.scss";
import TickSvg from "../svg/tickSvg";
import { WHITE } from "../colors";

function ProgressBar(props: any) {
  const progressRef = React.useRef(null);
  const {
    size,
    strokeWidth,
    progress,
    successStroke,
    progressBarStroke,
    stepNumber,
    stepCompleted,
    highlight,
  } = props;
  const [offset, setOffset] = React.useState(progress);
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  React.useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
    const ref: any = progressRef?.current;
    ref.style = "transition: stroke-dashoffset 1s";
  }, [progress, circumference]);
  return (
    <div style={{ position: "relative", top: 0, left: 0 }}>
      <svg
        className={Styles.circle_bg}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          fill="transparent"
          stroke={highlight ? WHITE : progressBarStroke}
          strokeWidth={strokeWidth}
          r={radius}
          cx={center}
          cy={center}
        />
        <circle
          fill="transparent"
          ref={progressRef}
          stroke={successStroke}
          strokeWidth={strokeWidth}
          r={radius}
          cx={center}
          cy={center}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
      {stepCompleted ? (
        <div style={{ position: "absolute", top: "10px", left: "12px" }}>
          <TickSvg width="25" />
        </div>
      ) : (
        <div
          className={Styles.stepNumber}
          style={{
            color: highlight ? WHITE : progressBarStroke,
          }}
        >
          <p className="mb-0">{stepNumber}</p>
        </div>
      )}
    </div>
  );
}

export default ProgressBar;
