import React, { useEffect } from "react";
import Select from "react-select";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import { days, dobYears, months } from "../../../../../constants/data";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import { checkPAN, validNameCheck } from "../../../../../utils/validation";

function PanServicePopup({ handleResponse, name }: any) {
  const serviceName = name.replaceAll(" ", "").toLowerCase();
  const dateRef = React.useRef<any>();
  const monthRef = React.useRef<any>();
  const yearRef = React.useRef<any>();

  const [day, setDay] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");

  const errorObject = {
    panNumberError: false,
    consentError: false,
    dobDayError: false,
    dobMonthError: false,
    dobYearError: false,
    nameToMatchError: false,
    userNameError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [panDetails, setPanDetails] = React.useState(false);
  const nameMatchRef = React.useRef<any>("");
  const userNameref = React.useRef<any>("");
  const [isNameMatchEnabled, setIsNameMatchEnabled] = React.useState(false);

  const panNumberRef = React.useRef<any>("");

  useEffect(() => {
    if (isNameMatchEnabled) {
      setIsDisabled(true);
    }
    if (!isNameMatchEnabled) {
      if (panNumberRef.current.value) {
        setIsDisabled(false);
      }
    }
  }, [isNameMatchEnabled]);

  function isValidNameMatch() {
    setError({ ...errorObject });
    if (
      checkPAN(panNumberRef.current.value.trim().toUpperCase()) &&
      validNameCheck(nameMatchRef.current.value)
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!checkPAN(panNumberRef.current.value.trim().toUpperCase())) {
      setError(() => ({
        ...error,
        panNumberError: true,
      }));
    }
    if (!validNameCheck(nameMatchRef.current.value)) {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
    }
    return setIsDisabled(true);
  }

  function isValid() {
    if (checkPAN(panNumberRef.current.value.trim().toUpperCase())) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    setError(() => ({
      ...error,
      panNumberError: true,
    }));

    return setIsDisabled(true);
  }

  function handleCheck() {
    const panNumber = panNumberRef.current.value.toUpperCase();
    const userName = userNameref.current.value;
    const consent = isChecked === true ? "Y" : "N";
    if (isNameMatchEnabled && nameMatchRef.current.value === "") {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
      return setIsDisabled(true);
    }

    if (serviceName === "pandemographic") {
      if (userNameref.current.value === "") {
        setError(() => ({
          ...error,
          userNameError: true,
        }));
        return setIsDisabled(true);
      }
      if (!day) {
        setError(() => ({
          ...error,
          dobDayError: true,
        }));
        return false;
      }
      if (!month) {
        setError(() => ({
          ...error,
          dobMonthError: true,
        }));
        return false;
      }
      if (!year) {
        setError(() => ({
          ...error,
          dobYearError: true,
        }));
        return false;
      }
    }

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    if (serviceName === "pandemographic") {
      const dob = `${day}-${month}-${year}`;
      handleResponse({ panNumber, dob, consent, userName }, setLoading);
    } else if (serviceName === "panmicro") {
      handleResponse({ panNumber, consent, panDetails }, setLoading);
    } else if (
      nameMatchRef &&
      nameMatchRef.current &&
      nameMatchRef.current.value
    ) {
      const panHolderName = nameMatchRef.current.value;
      handleResponse({ panNumber, panHolderName, consent }, setLoading);
    } else handleResponse({ panNumber, consent }, setLoading);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>PAN Number</label>
            <InputField
              id="id-input-pan"
              onChange={isValid}
              maxLength={10}
              inputRef={panNumberRef}
              isError={error.panNumberError}
              purpose="forPan"
            />
            {error.panNumberError && (
              <ErrorMessage>Please enter a valid Pan number</ErrorMessage>
            )}
          </div>
          {serviceName === "pandemographic" && (
            <>
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  User name
                </label>
                <InputField
                  text="lowercase"
                  id="id-input-user"
                  onChange={isValid}
                  inputRef={userNameref}
                  isError={error.userNameError}
                  purpose="forPan"
                />
                {error.userNameError && (
                  <ErrorMessage>Please enter user name</ErrorMessage>
                )}
              </div>
              <label className={`${Styles.label}`}>DOB</label>
              <div className="d-flex mr-1">
                <div className="mr-2 d-flex">
                  <div className="d-flex flex-column">
                    <Select
                      id="id-input-pan-day"
                      ref={dateRef}
                      onChange={(e: any) => setDay(e.value)}
                      options={days}
                      styles={
                        !error.dobDayError
                          ? dropDownStyles.customStylesForDays
                          : dropDownStyles.customStylesForDaysError
                      }
                      placeholder="DD"
                    />
                  </div>
                  <div className="ml-2 d-flex flex-column">
                    <Select
                      id="id-input-pan-month"
                      onChange={(e: any) => setMonth(e.value)}
                      ref={monthRef}
                      options={months}
                      styles={
                        !error.dobMonthError
                          ? dropDownStyles.customStylesForMonths
                          : dropDownStyles.customStylesForMonthsError
                      }
                      placeholder="MMM"
                    />
                  </div>
                  <div className="ml-2 d-flex flex-column">
                    <Select
                      id="id-input-pan-year"
                      onChange={(e: any) => setYear(e.value)}
                      ref={yearRef}
                      placeholder="YYYY"
                      options={dobYears}
                      styles={
                        !error.dobYearError
                          ? dropDownStyles.customStylesForYears
                          : dropDownStyles.customStylesForYearsError
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {(serviceName === "panpro" ||
            serviceName === "panlite" ||
            serviceName === "panadvance") && (
            <div className="pl-4 pb-2 pt-4 d-flex flex-column">
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(e: any) => setIsNameMatchEnabled(e.target.checked)}
                />
                <p className={`${Styles.ackText}`}>is name match required ??</p>
              </div>
            </div>
          )}

          {serviceName === "panmicro" && (
            <div className="pl-4 pb-2 pt-4 d-flex flex-column">
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(e: any) => setPanDetails(e.target.checked)}
                />
                <p className={`${Styles.ackText}`}>is pan details required?</p>
              </div>
            </div>
          )}
          {(serviceName === "panpro" ||
            serviceName === "panlite" ||
            serviceName === "panadvance") &&
            isNameMatchEnabled && (
              <div className="d-flex flex-column mb-4 ">
                <label className={`${Styles.label} flex-grow-1`}>
                  Name To Match
                </label>
                <InputField
                  onChange={isValidNameMatch}
                  inputRef={nameMatchRef}
                  isError={error.nameToMatchError}
                  purpose="forPassport"
                  text="lowerCase"
                />
                {error.nameToMatchError && (
                  <ErrorMessage>Please enter a valid Name</ErrorMessage>
                )}
              </div>
            )}

          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isNameMatchEnabled ? isValidNameMatch() : isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-pan"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PanServicePopup;
