//Main screen rendered when user wants to see myAccount Info containing
// all the information regarding organisation.
import React, { useEffect } from "react";
import Styles from "./myAccount.module.scss";
import PocEdit from "./poc-edit/pocEdit";
import { callApi } from "../../../api/fetch";
import { Organisation, Poc } from "../../../constants/org";
import Loading from "../../../Shared/loading/loading";
import { ACCENTCOLOR, BLACKCOLOR } from "../../../Shared/colors";
import ArrowUp from "../../../Shared/svg/arrowUp";
import { useHistory } from "react-router-dom";
import Toast from "../../../Shared/toast/toast";
import noKamAssignedIllustration from "../../../assets/images/noAccountManagerAssigned.svg";
import noBmAssignedIllustration from "../../../assets/images/noBusinessManagerAssigned.svg";
import { useTitle } from "../../../Shared/useTitle";
import TopBar from "../../../Shared/top-bar/topBarNew";
import DarkLogoSVG from "../../../Shared/svg/darkLogoSVG";

function MyAccount() {
  const history = useHistory();
  const [toggleTechPOCModal, setToggleTechPOCModal] = React.useState(false);
  const [toggleBusinessPOCModal, setToggleBusinessPOCModal] = React.useState(
    false
  );
  const [org, setOrg] = React.useState<null | Organisation>();
  const [kycDetails, setKycDetails] = React.useState({
    gstin: "",
  });
  const [fullPageLoad, setFullPageLoad] = React.useState(false);
  const [editTechPocCardLoading, setEditTechPocCardLoading] = React.useState(
    false
  );
  const [
    editBusinessPocCardLoading,
    setEditBusinessPocCardLoading,
  ] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const kamEmptyState = (
    <div
      style={{ height: "150px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="text-center">
        <img
          src={noKamAssignedIllustration}
          alt="no_kam_assigned"
          style={{ width: "100px" }}
        />
        <h6 className="mb-0 pt-3 px-2">No Kam Assigned</h6>
      </div>
    </div>
  );

  const BmEmptyState = (
    <div
      style={{ height: "150px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="text-center">
        <img
          src={noBmAssignedIllustration}
          alt="no_bm_assigned"
          style={{ width: "100px" }}
        />
        <h6 className="mb-0 pt-3 px-2">No BM Assigned</h6>
      </div>
    </div>
  );

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }
  useEffect(() => {
    async function getOrgDetails() {
      setFullPageLoad(true);
      try {
        const data = await callApi(
          `/organisation/${localStorage.getItem(
            "organisation_id"
          )}?action=get_org_details`,
          {
            method: "GET",
          }
        );
        setFullPageLoad(false);
        setOrg({
          ...data,
          bm: data.business_manager,
        });
        setKycDetails(data.kyc);
      } catch (err) {
        setFullPageLoad(false);
        toastTimeout(true, "error", (err as Error).message);
      }
    }
    getOrgDetails();
  }, []);
  async function handelTechPOCEdit(value: Poc) {
    setToggleTechPOCModal(false);
    setEditTechPocCardLoading(true);
    try {
      const data = await callApi(
        `/organisation/${localStorage.getItem(
          "organisation_id"
        )}?action=org_techpoc_update`,
        {
          method: "PUT",
          data: {
            technical_poc: value,
          },
        }
      );
      setEditTechPocCardLoading(false);
      setOrg((org: any) => ({
        ...org,
        technical_poc: data.technical_poc,
      }));
    } catch (err) {
      setEditTechPocCardLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
    return;
  }
  async function handelBusinessPOCEdit(value: Poc) {
    setToggleBusinessPOCModal(false);
    setEditBusinessPocCardLoading(true);
    try {
      const data = await callApi(
        `/organisation/${localStorage.getItem(
          "organisation_id"
        )}?action=org_businesspoc_update`,
        {
          method: "PUT",
          data: {
            business_poc: value,
          },
        }
      );
      setEditBusinessPocCardLoading(false);
      setOrg((org: any) => ({
        ...org,
        business_poc: data.business_poc,
      }));
    } catch (err) {
      setEditBusinessPocCardLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
    return;
  }
  function handelTechPocEditCancel() {
    setToggleTechPOCModal(false);
    return;
  }
  function handelBusinessPocEditCancel() {
    setToggleBusinessPOCModal(false);
    return;
  }
  useTitle("Zoop Customer Platform | My Account Info");
  return (
    <div>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className={`${Styles.background}`}>
        <div className="pl-4 d-flex align-items-center justify-content-between pl-5">
          <DarkLogoSVG />
          <TopBar />
        </div>
        {fullPageLoad ? (
          <div style={{ height: "calc(100vh - 70px)" }}>
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <div className={"container-fluid px-4"}>
            <div className={"p-3"}>
              <div className="d-flex align-items-center py-3">
                <div
                  className="d-flex align-items-center"
                  onClick={() => history.push("/application")}
                  style={{ cursor: "pointer" }}
                >
                  <div className={`px-1 ${Styles.rotate}`}>
                    <ArrowUp color={BLACKCOLOR} />
                  </div>
                  <p className={"mb-0 px-2 heading_text"}>
                    Business Information
                  </p>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-xl-12 p-3">
                  <div className={`${Styles.card} ${Styles.card2Animation}`}>
                    <div className={`p-3 ${Styles.cardHeader}`}>
                      <p className={"mb-0 px-2 card_title_bold_text"}>
                        Business Information
                      </p>
                    </div>
                    <div className="p-3">
                      <div className="d-flex align-items-start py-2 my-1">
                        <div className={`${Styles.keyWidth} px-2`}>
                          <p className={`mb-0 ${Styles.key}`}>Business Name:</p>
                        </div>
                        <div className="px-2">
                          <p className={`mb-0 ${Styles.value}`}>{org?.name}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start py-2 my-1">
                        <div className={`${Styles.keyWidth} px-2`}>
                          <p className={`mb-0 ${Styles.key}`}>Category:</p>
                        </div>
                        <div className="px-2">
                          <p className={`mb-0 ${Styles.value}`}>
                            {org?.category}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start py-2 my-1">
                        <div className={`${Styles.keyWidth} px-2`}>
                          <p className={`mb-0 ${Styles.key}`}>Country Code:</p>
                        </div>
                        <div className="px-2">
                          <p className={`mb-0 ${Styles.value}`}>
                            {org?.country_code}
                          </p>
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-start py-2 my-1">
                        <div className={`${Styles.keyWidth} px-2`}>
                          <p className={`mb-0 ${Styles.key}`}>
                            Company Address:
                          </p>
                        </div>
                        <div className="px-2">
                          <p className={`mb-0 ${Styles.value}`}>
                            Creaticity Mall, Opposite Golf Course,
                          </p>
                          <p className={`mb-0 ${Styles.value}`}>
                            Shastrinagar, Yerawada, Pune,
                          </p>
                          <p className={`mb-0 ${Styles.value}`}>
                            Maharashtra 411006
                          </p>
                        </div>
                      </div> */}
                      {kycDetails.gstin && (
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>GSTIN:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {kycDetails.gstin ?? "NA"}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="d-flex align-items-start py-2 my-1">
                        <div className={`${Styles.keyWidth} px-2`}>
                          <p className={`mb-0 ${Styles.key}`}>Trade Name:</p>
                        </div>
                        <div className="px-2">
                          <p className={`mb-0 ${Styles.value}`}>
                            {org?.trade_name || "NA"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-xl-6 p-3">
                  <div className={`${Styles.card} ${Styles.card1Animation}`}>
                    <div className={`p-3 ${Styles.cardHeader}`}>
                      <p className={"mb-0 px-2 card_title_bold_text"}>
                        Account Manager
                      </p>
                    </div>
                    <div className="p-3">
                      {org?.kam.kam_name ? (
                        <div>
                          {" "}
                          <div className="d-flex align-items-start py-2 my-1">
                            <div className={`${Styles.keyWidth} px-2`}>
                              <p className={`mb-0 ${Styles.key}`}>Name:</p>
                            </div>
                            <div className="px-2">
                              <p className={`mb-0 ${Styles.value}`}>
                                {org?.kam.kam_name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-start py-2 my-1">
                            <div className={`${Styles.keyWidth} px-2`}>
                              <p className={`mb-0 ${Styles.key}`}>Email:</p>
                            </div>
                            <div className="px-2">
                              <p className={`mb-0 ${Styles.value}`}>
                                {org?.kam.kam_email}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-start py-2 my-1">
                            <div className={`${Styles.keyWidth} px-2`}>
                              <p className={`mb-0 ${Styles.key}`}>Contact:</p>
                            </div>
                            <div className={`${Styles.valueWidth} px-2`}>
                              <p className={`mb-0 ${Styles.value}`}>
                                {org?.kam.kam_phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        kamEmptyState
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 p-3">
                  <div className={`${Styles.card} ${Styles.card1Animation}`}>
                    <div className={`p-3 ${Styles.cardHeader}`}>
                      <p className={"mb-0 px-2  card_title_bold_text"}>
                        Business Manager
                      </p>
                    </div>
                    <div className="p-3">
                      {org?.bm?.bm_name ? (
                        <div>
                          {" "}
                          <div className="d-flex align-items-start py-2 my-1">
                            <div className={`${Styles.keyWidth} px-2`}>
                              <p className={`mb-0 ${Styles.key}`}>Name:</p>
                            </div>
                            <div className="px-2">
                              <p className={`mb-0 ${Styles.value}`}>
                                {org?.bm?.bm_name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-start py-2 my-1">
                            <div className={`${Styles.keyWidth} px-2`}>
                              <p className={`mb-0 ${Styles.key}`}>Email:</p>
                            </div>
                            <div className="px-2">
                              <p className={`mb-0 ${Styles.value}`}>
                                {org?.bm?.bm_email}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-start py-2 my-1">
                            <div className={`${Styles.keyWidth} px-2`}>
                              <p className={`mb-0 ${Styles.key}`}>Contact:</p>
                            </div>
                            <div className={`${Styles.valueWidth} px-2`}>
                              <p className={`mb-0 ${Styles.value}`}>
                                {org?.bm?.bm_phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        BmEmptyState
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-xl-6 p-3">
                  {editTechPocCardLoading ? (
                    <div
                      className={`${Styles.loadingCard} d-flex align-items-center justify-content-center`}
                    >
                      <Loading loadingColor={ACCENTCOLOR} />
                    </div>
                  ) : (
                    <div className={`${Styles.card} ${Styles.card3Animation}`}>
                      <div className={`p-3 ${Styles.cardHeader}`}>
                        <div className="d-flex align-items-center">
                          <p className={"mb-0 px-2  card_title_bold_text"}>
                            Technical Owner Details
                          </p>
                          <div className="ml-auto px-2">
                            <p
                              className={`${Styles.editTextStyle} mb-0`}
                              onClick={() => setToggleTechPOCModal(true)}
                            >
                              Edit
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Name:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.technical_poc.name}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Email:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.technical_poc.email}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Contact:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.technical_poc.phone}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Designation:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.technical_poc.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xl-6 p-3">
                  {editBusinessPocCardLoading ? (
                    <div
                      className={`${Styles.loadingCard} d-flex align-items-center justify-content-center`}
                    >
                      <Loading loadingColor={ACCENTCOLOR} />
                    </div>
                  ) : (
                    <div className={`${Styles.card} ${Styles.card4Animation}`}>
                      <div className={`p-3 ${Styles.cardHeader}`}>
                        <div className="d-flex align-items-center">
                          <p className={"mb-0 px-2  card_title_bold_text"}>
                            Business Owner Details
                          </p>
                          <div className="ml-auto px-2">
                            <p
                              className={`${Styles.editTextStyle} mb-0`}
                              onClick={() => setToggleBusinessPOCModal(true)}
                            >
                              Edit
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Name:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.business_poc.name}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Email:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.business_poc.email}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Contact:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.business_poc.phone}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-start py-2 my-1">
                          <div className={`${Styles.keyWidth} px-2`}>
                            <p className={`mb-0 ${Styles.key}`}>Designation:</p>
                          </div>
                          <div className="px-2">
                            <p className={`mb-0 ${Styles.value}`}>
                              {org?.business_poc.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {toggleTechPOCModal ? (
        <PocEdit
          pocInfo={org?.technical_poc}
          orgCountryCode={org?.country_code}
          onSubmit={(value: any) => handelTechPOCEdit(value)}
          onCancel={handelTechPocEditCancel}
        />
      ) : null}
      {toggleBusinessPOCModal ? (
        <PocEdit
          pocInfo={org?.business_poc}
          orgCountryCode={org?.country_code}
          onSubmit={(value: any) => handelBusinessPOCEdit(value)}
          onCancel={handelBusinessPocEditCancel}
        />
      ) : null}
    </div>
  );
}

export default MyAccount;
