import React from "react";

function Information({ infoClass }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={infoClass}
      width="7.805"
      height="10.415"
      viewBox="0 0 7.805 13.415"
    >
      <defs>
        <style>.prefix__cls-1</style>
      </defs>
      <g id="prefix__information" transform="translate(-8.586 -6.269)">
        <g id="prefix__Group_440" data-name="Group 440">
          <g id="prefix__Group_439" data-name="Group 439">
            <path
              id="prefix__Path_1380"
              d="M179.9 128.5a3.907 3.907 0 0 0-3.9 3.9.976.976 0 0 0 1.951 0 1.951 1.951 0 1 1 1.951 1.951.976.976 0 0 0-.976.976v2.439a.976.976 0 0 0 1.951 0v-1.587a3.9 3.9 0 0 0-.976-7.682z"
              className="prefix__cls-1"
              data-name="Path 1380"
              transform="translate(-167.414 -122.231)"
            />
            <circle
              id="prefix__Ellipse_237"
              cx="1.22"
              cy="1.22"
              r="1.22"
              className="prefix__cls-1"
              data-name="Ellipse 237"
              transform="translate(11.269 17.245)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Information;
