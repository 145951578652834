import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

import styles from "./batch-service.module.scss";
import Input from "../../../Shared/input/input";
import InputDropdown from "../../../Shared/input-dropdown/inputDropdown";
import Button from "../../../Shared/button/button";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import Toast from "../../../Shared/toast/toast";

import { MEDIUM } from "../../../Shared/buttonSize";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { callAxiosPostApi } from "../../../api/fetch";
import { isNumeric, isValidName } from "../../../utils/validation";

// svg - images
import { ReactComponent as CrossSvg } from "../../../assets/images/cross-modal.svg";
import { ReactComponent as FileUploadSvg } from "../../../assets/images/fileupload.svg";

interface Props {
  downtime?: boolean;
  show: boolean;
  setModalShow?: (...args: any[]) => void;
  batchTypes: Array<any>;
  getTaskData: (...args: any[]) => void;
}

interface FormData {
  batchName?: string;
  TPM?: number | any;
  batchType?: string | undefined;
  batchFile?: any;
  response_type?: any;
}

function AddBatchModal({ downtime, batchTypes, ...props }: Props) {
  const [showModal, setModalShow] = useState<boolean>(false);
  const responseCategory = ["INSURANCE", "FINANCE", "NONE"];
  const allowedProductForResponseType: any = [
    "IN_VEHICLE_RC_ADVANCE",
    "IN_VEHICLE_RC_REVERSE",
  ];
  const [formData, setFormData] = useState<FormData>({});
  const [error, setError] = useState<FormData>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const fileRef = useRef<any>(null);

  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });

  // To activate the toast
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 5000);
    return;
  }

  // Call on hide of modal
  const onHide = () => {
    setFormData({});
    setModalShow(false);
    props.setModalShow && props.setModalShow(false);
  };

  useEffect(() => {
    setModalShow(props.show);
  }, [props.show]);

  // To handle the drag-n-drop events
  function allowDrop(ev: any) {
    ev.preventDefault();
    const element = document.getElementById("fileSelectEle");
    element?.classList?.add("d-none");
  }

  // Call on file drop into ele
  function onFileDrop(ev: any) {
    ev.preventDefault();
    const data = ev?.dataTransfer?.files?.length
      ? ev.dataTransfer.files[0]
      : "";

    setFormData((prev: any) => ({
      ...prev,
      batchFile: data,
    }));
  }

  // Call on drag leave from ele
  function onDragLeave() {
    const element = document.getElementById("fileSelectEle");
    element?.classList?.remove("d-none");
  }

  // To remove the selected file
  function onFileRemove() {
    setFormData((prev: any) => ({
      ...prev,
      batchFile: "",
    }));
  }

  // To validate the batch name
  const checkBatchName = () => {
    if (!formData?.batchName?.length) {
      setError((error) => ({
        ...error,
        batchName: "Batch name cannot be empty",
      }));
      return false;
    }
    if (isValidName(formData?.batchName, true)) {
      setError((error) => ({
        ...error,
        batchName: "Enter a valid name",
      }));
      return false;
    }
    if (isNumeric(formData?.batchName[0])) {
      setError((error) => ({
        ...error,
        batchName: "Batch name should not start with a number",
      }));
      return false;
    }
    return true;
  };

  // To validate the Batch Type
  function checkBatchType() {
    if (!formData?.batchType || !formData?.batchType?.length) {
      setError((error) => ({
        ...error,
        batchType: "Select Batch Type",
      }));
      return false;
    }
    return true;
  }

  // To validate the Batch Type
  function checkResponseType() {
    if (!formData?.response_type) {
      setError((error) => ({
        ...error,
        response_type: "Select Response Type",
      }));
      return false;
    }
    return true;
  }

  // To validate the Batch File
  function checkFileUpload() {
    if (!formData?.batchFile?.name) {
      setError((error) => ({
        ...error,
        batchFile: "Select Batch File",
      }));
      return false;
    }
    if (!formData?.batchFile?.type.includes("csv")) {
      setError((error) => ({
        ...error,
        batchFile: "Select CSV File Only",
      }));
      return false;
    }

    const fileSize = formData?.batchFile?.size / 1024 / 1024; // in MiB
    if (fileSize > 50) {
      setError((error) => ({
        ...error,
        batchFile: "File size should not exceed 50mb",
      }));
      return false;
    }

    return true;
  }

  // Call on submit on form
  const onFormSubmit = async () => {
    const validate = [
      checkBatchName(),
      checkBatchType(),
      checkFileUpload(),
    ].every(Boolean);
    if (!validate) {
      return;
    }
    setLoading(true);
    try {
      const org_id = localStorage.getItem("organisation_id");
      const user_email = localStorage.getItem("email");
      const newFormData = new FormData();

      newFormData.append("user_email", user_email || "");

      newFormData.append("batch_type", formData.batchType || "");
      newFormData.append("batch_name", formData.batchName || "");
      newFormData.append("tpm", formData.TPM || "");
      newFormData.append("upload_file", formData?.batchFile || "");
      newFormData.append("org_id", org_id || "");
      newFormData.append(
        "response_type",
        allowedProductForResponseType.includes(formData.batchType)
          ? formData.batchType === allowedProductForResponseType[1]
            ? responseCategory[1]
            : responseCategory[0]
          : responseCategory[2]
      );
      // newFormData.append("org_name", value.name || "");

      const data: any = await callAxiosPostApi(
        "/productBatchService/createTask",
        newFormData
      );
      if (data) {
        onHide();
        if (data?.success === true) {
          toastTimeout(true, "success", data.message || "Batch created");
          props.getTaskData && props.getTaskData();
          setFormData({});
        } else {
          toastTimeout(true, "error", data.message || "Something went wrong");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toastTimeout(true, "error", "Error while Creating Task");
    }
  };

  const downloadSampleLink = batchTypes.length
    ? batchTypes.filter((item) => item?.value === formData?.batchType)[0]
        ?.sample_link
    : "";

  return (
    <>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}

      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={onHide}
        className={"add-batch-modal"}
      >
        <div>
          <div className={styles.crossBtn} onClick={onHide}>
            <CrossSvg></CrossSvg>
          </div>
          <div className="justify-content-center align-items-center p-0 d-flex">
            <p className={"card_title_bold_text"}> Add Batch </p>
          </div>
        </div>
        <Modal.Body className={styles.modalBody}>
          {batchTypes.length ? (
            <>
              <div>
                <Input
                  noshadow="true"
                  type="text"
                  name="batchName"
                  id="batchName"
                  autoComplete="off"
                  placeholder="Batch Name"
                  labelname="Batch Name"
                  error={error?.batchName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    setFormData((prev: any) => ({
                      ...prev,
                      batchName: value,
                    }));
                    setError((error) => ({
                      ...error,
                      batchName: "",
                    }));
                  }}
                  customInputClass={"border-radius-10"}
                />
                {error.batchName && (
                  <ErrorMessage>{error.batchName}</ErrorMessage>
                )}
              </div>
              <div className="mt-4">
                <InputDropdown
                  no_shadow="true"
                  hasError={error.batchType}
                  isValid={true}
                  labelname="Product Type"
                  optionsArray={batchTypes.map((item) => item?.name)}
                  click={(value: string) => {
                    const option = batchTypes.filter(
                      (item) => item?.name === value
                    );
                    setFormData((prev: any) => ({
                      ...prev,
                      batchType: option[0]?.value,
                    }));
                    setError((error) => ({
                      ...error,
                      batchType: "",
                    }));
                  }}
                  className="border-radius-10"
                  id={"z-batch-product-type"}
                />
                {error.batchType && (
                  <ErrorMessage>{error.batchType}</ErrorMessage>
                )}
              </div>

              {formData.batchFile ? (
                <>
                  <div className={styles.fileUploaded}>
                    <p className={"mb-0 " + styles.uploadFile}>File Uploaded</p>
                    <div className={styles.uploadedContainer}>
                      <span>{formData?.batchFile?.name}</span>
                      <p className="mb-0" onClick={onFileRemove}>
                        Remove
                      </p>
                    </div>
                    {error.batchFile && (
                      <ErrorMessage>{error.batchFile}</ErrorMessage>
                    )}
                  </div>

                  <div className="d-flex justify-content-center mt-5">
                    <Button
                      size={MEDIUM}
                      hoveredStyle={primaryButtonHoverStyle}
                      style={primaryButtonStyle}
                      isLoading={loading}
                      disabled={loading}
                      type="submit"
                      onClick={() => {
                        onFormSubmit();
                      }}
                      id="z-submit-add-batch"
                    >
                      Submit Batch
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  onDragOver={allowDrop}
                  onDrop={(ev: any) => {
                    onFileDrop(ev);
                  }}
                  onDragLeave={() => {
                    onDragLeave();
                  }}
                >
                  <div className="d-flex justify-content-between mt-4">
                    <div>
                      <p className={"mb-0 " + styles.uploadFile}>Upload File</p>
                      <p className={styles.instructionText}>
                        CSV Format Only | Max size 50 MB
                      </p>
                    </div>
                    <div className="d-flex align-items-end">
                      {downloadSampleLink && (
                        <a
                          href={downloadSampleLink}
                          className={styles.downloadFile}
                          download
                        >
                          Download Sample CSV
                        </a>
                      )}
                    </div>
                  </div>
                  <div className={styles.chooseFile}>
                    <input
                      type="file"
                      className="custom-file-input d-none"
                      id="z-createTaskFileeInput"
                      accept=".csv"
                      ref={fileRef}
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          batchFile: e.target?.files[0],
                        }));
                        setError((error) => ({
                          ...error,
                          batchFile: "",
                        }));
                      }}
                    />

                    <div className="d-flex justify-content-center mt-5">
                      <FileUploadSvg />
                    </div>
                    <p
                      className={
                        "d-flex justify-content-center mt-4 " +
                        styles.selectFiletxt
                      }
                    >
                      Drag and drop file here
                    </p>

                    <div id="fileSelectEle">
                      <p
                        className={
                          "d-flex justify-content-center mt-1 mb-2 " +
                          styles.selectFileOrTxt
                        }
                      >
                        Or
                      </p>
                      <div className="d-flex justify-content-center">
                        <Button
                          size={MEDIUM}
                          hoveredStyle={primaryButtonHoverStyle}
                          style={primaryButtonStyle}
                          isLoading={false}
                          type="submit"
                          htmlFor="createTaskFileeInput"
                          onClick={() => {
                            fileRef?.current?.click();
                          }}
                          id="z-submit-file"
                        >
                          Browse File
                        </Button>
                      </div>
                    </div>
                    <div className="mb-5"></div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-5 mb-5">
              Please subscribe the products first.
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddBatchModal;
