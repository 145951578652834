//Dashboard render 2 conditional components based on the onboarding status of the user
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Styles from "./dashboard.module.scss";
import Loading from "../../../Shared/loading/loading";
import CheckList from "./check-list/checkList";
import { ACCENTCOLOR } from "../../../Shared/colors";
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";
import { envTypes } from "../../../constants/envTypes";
import { ONBOARDING_STATUS } from "../../../constants/onboardingStatuses";
import moment from "moment";
import { useTitle } from "../../../Shared/useTitle";
import ProductsUsage from "./product-usage/productsUsage";
import TransactionStats from "./transaction-stats/transactionStats";
import MultipurposeCard from "./multipurpose-card/multipurposeCard";
import DataTable from "../../../Shared/data-table/datatable";

function Dashboard() {
  const org_id = localStorage.getItem("organisation_id");
  const [appData, setAppData] = useState([]);
  const user: any = localStorage.getItem("@user");
  const x_user_id = JSON.parse(user)._id;

  const tableHeaderCustom = (
    <>
      <div className="d-flex justify-content-between p-2">
        <p className={"mb-0 card_title_semibold_text"}>
          {appData.length} apps running
        </p>
        <Link
          to="/application/manage-apps"
          id="id-manage-apps-button"
          className="card_button px-2 py-2"
        >
          Manage apps
        </Link>
      </div>
    </>
  );
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = React.useState(0);
  const [onboardingStatus, setOnboardingStatus] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [overAllLoading, setOverallLoading] = React.useState(false);
  const [productOptions, setProductOptions] = React.useState([]);
  const [transactionStatLoading, setTransactionStatLoading] = React.useState(
    false
  );
  const [summaryLoading, setSummaryLoading] = React.useState(false);
  const [overallStats, setOverallStats] = React.useState({
    subscribed_products_count: 0,
    pending_products_count: 0,
    plan_validity: "",
    wallet_balance: 0,
  });
  const plan_types = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID",
  };
  const [env, setEnv] = React.useState(envTypes.TEST);
  const [selectedApp, setSelectedApp] = React.useState("");
  const [selectedAppInfo, setSelectedAppInfo] = React.useState<any>();
  const [selectedAppId, setSelectedAppId] = React.useState("");
  const [productCount, setProductCount] = React.useState(0);
  const [planType, setPlanType] = React.useState();
  const [tranxnVolume, setTranxnVolume] = useState("0");
  const [latestApprovedProd, setLatestApprovedProduct] = React.useState("");
  const [selectedDuration, setSelectedDuration] = React.useState({
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
  });

  // getting org info api
  useEffect(() => {
    setLoading(true);
    callApi(`/organisation/${org_id}`, {
      method: "GET",
    })
      .then((response) => {
        const { activePlan, onboarding_status, country_code } = response;
        localStorage.setItem("country_code", response.country_code);
        setPlanType(activePlan?.plan_type);
        setOnboardingStatus(onboarding_status);
        setCountryCode(country_code);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toastTimeout(true, "error", error.message);
        // TODO: add sentry error
      });
  }, [org_id]);

  async function getAllAppNames(page: string) {
    if (onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED) {
      setOverallLoading(true);
      try {
        const data = await callApi(`/app?page=${page}`, {
          method: "GET",
        });
        setAppData(data);
        if (
          onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED &&
          data.length > 1
        ) {
          setSelectedApp(data[1].name);
          setSelectedAppInfo(data[1]);
          setSelectedAppId(data[1]._id);
        } else {
          setSelectedApp(data[0].name);
          setSelectedAppInfo(data[0]);
          setSelectedAppId(data[0]._id);
        }
        setOverallLoading(false);
      } catch (err) {
        setOverallLoading(false);
        toastTimeout(true, "error", (err as Error).message);
      }
    }
    return;
  }

  // get all apps api without keys and service assess
  useEffect(() => {
    getAllAppNames("dashboard");
  }, [org_id, onboardingStatus]);

  // get overall stats and org balance
  useEffect(() => {
    async function getOrgBalance() {
      if (onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED) {
        const res = await callApi("/summary", {
          method: "GET",
        });
        setOverallStats(res);
        setProductCount(res.subscribed_products_count);
      }
    }
    try {
      getOrgBalance();
    } catch (_) {
      toastTimeout(true, "error", "Failed to fetch");
    }
  }, [onboardingStatus]);

  //get overall volume of transactions
  useEffect(() => {
    async function getTransactionVolume() {
      try {
        const res = await callApi(
          `/organisation/${org_id}?action=get_org_total_transaction_counts`,
          {
            method: "GET",
            init: {
              headers: {
                "x-user-id": x_user_id,
              },
            },
          }
        );
        setTranxnVolume(res.total_count);
      } catch (error) {
        console.log(error);
      }
    }
    getTransactionVolume();
  }, []);

  useTitle("Zoop Customer Platform | Dashboard");

  const tableHeader = [
    {
      name: "App Name",
      className: Styles.date,
    },
    {
      name: "Keys",
      className: Styles.id,
    },
    {
      name: "Products",
      className: Styles.product_name,
    },
  ];

  return (
    <div className={`container-fluid p-0 ${Styles.container}`}>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      {loading || overAllLoading || summaryLoading ? (
        <div
          style={{ height: "80vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        // The user will not be able to see the dashboard unless the Onboarding
        //  has successfully Completed
        <div className="px-4 py-1">
          {onboardingStatus === ONBOARDING_STATUS.PLAN_SUBSCRIBED ? (
            <div>
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <ProductsUsage
                    preselectedProduct={"All Products"}
                    setLatestApprovedProduct={setLatestApprovedProduct}
                    preSelectedDatePreference={"Last 30 days"}
                  />
                  <TransactionStats
                    loading={loading}
                    appDetails={selectedAppInfo}
                    appData={appData}
                  />
                </div>
                <div className="d-flex flex-column ml-1">
                  <div className="d-flex">
                    <MultipurposeCard
                      purpose="trans_info"
                      tranxnVolume={tranxnVolume}
                    />
                    <MultipurposeCard
                      purpose="subscription_info"
                      productCount={productCount}
                    />
                  </div>
                  <div className="d-flex">
                    <MultipurposeCard
                      purpose="avl_balance_info"
                      availableBalance={
                        planType === plan_types.PREPAID
                          ? overallStats.wallet_balance
                          : "NA"
                      }
                    />
                    <MultipurposeCard
                      purpose="trial_info"
                      latestApprovedProd={
                        latestApprovedProd ? latestApprovedProd : "NA"
                      }
                    />
                  </div>
                  <div className="pl-1">
                    <DataTable
                      headers={[...tableHeader]}
                      loading={loading}
                      rows={appData}
                      setActivePageNumber={setActivePageNumber}
                      totalNumberOfPages={totalNumberOfPages}
                      tableHeader={tableHeaderCustom}
                      purpose="forDashboard_mini"
                      bodyClassName={Styles?.bodyClassName}
                      pagination={false}
                    >
                      {appData.length ? (
                        appData.map((app: any, index: number) => {
                          return (
                            <tr key={`Dashboard_mini_${index}`}>
                              <td className="px-4 py-2">
                                <p className="font body_small_text">
                                  {app.name}
                                </p>
                              </td>
                              <td className="px-4 py-2">
                                <p className={"mb-0 body_small_text"}>
                                  {app.keys}
                                </p>
                              </td>
                              <td className="px-4 py-2">
                                <p className={"mb-0 body_small_text"}>
                                  {app.service_access}
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="d-flex align-items-center justify-content-center">
                          No data found
                        </div>
                      )}
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CheckList
              onboardingStatus={onboardingStatus}
              countryCode={countryCode}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Dashboard;
