export const ALL = "All";
export const IDENTITY = "Identity";
export const MERCHANT = "Merchant";
export const VEHICLE = "Vehicle";
export const ADDRESS = "Address";
export const FINANCIAL = "Financial";
export const UTILITIES = "Utilities";
export const E_SIGN = "E-Sign";
export const filters = [
  ALL,
  IDENTITY,
  MERCHANT,
  VEHICLE,
  ADDRESS,
  FINANCIAL,
  UTILITIES,
  E_SIGN,
];
