import React from "react";
import Styles from "../passwordResetLinkExpiry.module.scss";
import checkMarkSrc from "../../../../assets/images/checkMark.svg";
import { useHistory } from "react-router";
import { ACCENTCOLOR } from "../../../../Shared/colors";
import emptyStateStyle from "../../../../Shared/empty-state/emptyState.module.scss";
import Button from "../../../../Shared/button/button";
import { LARGE } from "../../../../Shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../Shared/buttonStyles";

function PasswordResetLinkSuccess() {
  const history = useHistory();
  const progressRef = React.useRef(null);
  const size = 100;
  const strokeWidth = 4;
  let progress = 0;
  const [offset, setOffset] = React.useState(progress);
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  React.useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
    const ref: any = progressRef?.current;
    ref.style = "transition: stroke-dashoffset 2s";
  }, [progress, circumference]);
  setTimeout(() => {
    progress = 100;
  }, 500);
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.background}`}
    >
      <div>
        <div className="d-flex justify-content-center">
          <div
            style={{
              position: "relative",
              top: 0,
              left: 0,
            }}
          >
            <svg
              style={{ transform: "rotate(-90deg)", display: "block" }}
              width={size}
              height={size}
              viewBox={`0 0 ${size} ${size}`}
            >
              <circle
                fill="transparent"
                stroke={ACCENTCOLOR}
                strokeWidth={strokeWidth}
                r={radius}
                cx={center}
                cy={center}
              />
              <circle
                fill="transparent"
                ref={progressRef}
                stroke="#DFDFDF"
                strokeWidth={strokeWidth}
                r={radius}
                cx={center}
                cy={center}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
              />
            </svg>
            <div style={{ position: "absolute", top: "25px", left: "27px" }}>
              <img src={checkMarkSrc} alt="checkMark" width="45" height="50" />
            </div>
          </div>
        </div>
        <div className="py-3">
          <div className={"py-2"}>
            <p className={`${emptyStateStyle.emptyStateHeadingText} mb-0`}>
              Password Reset Link Sent
            </p>
          </div>
          <div className="py-3">
            <p className={`mb-0 ${emptyStateStyle.emptyStateBodyText}`}>
              We have sent you the Reset link on your email. Please check your
              Inbox.
            </p>
          </div>
        </div>
        <div className="py-4 text-center">
          <Button
            size={LARGE}
            style={primaryButtonStyle}
            hoveredStyle={primaryButtonHoverStyle}
            onClick={() => history.replace("/login")}
          >
            Redirect to Login
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetLinkSuccess;
