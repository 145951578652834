import React, { useEffect } from "react";
import Styles from "./createPassword.module.scss";
import AuthSidebar from "../../auth/authSidebar/authSidebar";
import Illustration from "../../../assets/images/Create_password_dashboard_illustration.svg";
import InformativeText from "../../auth/informativeText/informativeText";
import Button from "../../../Shared/button/button";
import Input from "../../../Shared/input/input";
import { isPasswordValid } from "../../../utils/validation";
import { useHistory } from "react-router-dom";
import PasswordEye from "../../../Shared/svg/Eye";
import { LINKCOLOR, SECONDARYCOLOR, SUCCESS } from "../../../Shared/colors";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";
import TickSvg from "../../../Shared/svg/tickSvg";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import { LARGE } from "../../../Shared/buttonSize";
import { useTitle } from "../../../Shared/useTitle";
import { encryptField, encryptObject } from "../../../constants/encrypt";

function CreatePassword() {
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [passwordShown, setPasswordShown] = React.useState(false);
  const history = useHistory();
  const checks = isPasswordValid(password);
  const validPassword = Object.values(isPasswordValid(password)).every(Boolean);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [toggleLoginPageButton, setToggleLoginPageButton] = React.useState(
    false
  );
  const [isValidPassword, setIsValidPassword] = React.useState(false);
  const [error, setError] = React.useState("");
  const [resetToken, setResetToken] = React.useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const dataFromQueryParams = JSON.parse(params.get("data") || "{}");
    if (dataFromQueryParams.reset_token) {
      setResetToken(dataFromQueryParams.reset_token);
    }
    return;
  }, []);

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  async function handelCreatePassword(event: any) {
    event.preventDefault();
    if (validPassword) {
      setLoading(true);
      if (resetToken) {
        // call api for resetting the password
        try {
          const dataToEncrypt = {
            reset_token: resetToken,
            password: password,
          };
          const encryptReset = encryptObject(dataToEncrypt);
          await callApi("/user/password-reset", {
            method: "PUT",
            data: {
              encryptReset,
            },
          });
          setLoading(false);
          setToggleLoginPageButton(true);
          toastTimeout(
            true,
            "success",
            "Your Password has been Reset Successfully"
          );
        } catch (err) {
          setLoading(false);
          toastTimeout(true, "error", (err as Error).message);
        }
        return;
      }
      try {
        const params = new URLSearchParams(window.location.search);
        const dataFromQueryParams = JSON.parse(params.get("data") || "{}");
        const encryptPassword = encryptField(password);
        await callApi(`/user/password/${dataFromQueryParams.user}`, {
          method: "POST",
          data: {
            encryptPassword,
            // password: password
          },
        });
        setLoading(false);
        setToggleLoginPageButton(true);
        toastTimeout(
          true,
          "success",
          "Your Password is generated. Login again to get in action."
        );
      } catch (err) {
        setLoading(false);
        toastTimeout(true, "error", (err as Error).message);
      }
      return;
    }
    return;
  }
  useTitle("Zoop Customer Platform | Create Password");
  return (
    <div className="container-fluid p-0">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className="row no-gutters">
        <div className="col-xl-5 d-none d-xl-block">
          <AuthSidebar
            img={<img src={Illustration} alt="illustration" width="250px" />}
            info={
              <InformativeText
                headertext="Password is the key to secure your access."
                bodytext="Ensure you follow the password guidelines to ensure your data and platform access stays secured."
              />
            }
          />
        </div>
        <div className="col-xl-7 col-lg-12">
          <div className={`${Styles.authActionsBackground}`}>
            <div
              style={{ height: "100%" }}
              className="d-flex align-items-center justify-content-center"
            >
              {toggleLoginPageButton ? (
                <div>
                  <div className="py-3">
                    <p className={`${Styles.headingText} mb-0 text-center`}>
                      Redirect to Login page
                    </p>
                  </div>
                  <div className="py-3">
                    <Button
                      size={LARGE}
                      hoveredStyle={primaryButtonHoverStyle}
                      style={primaryButtonStyle}
                      onClick={() => history.replace("/login")}
                    >
                      Go To Login
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="py-5">
                    <p className={`${Styles.headingText} mb-0`}>
                      Please create your password
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className={Styles.formWidth}>
                      <form>
                        <div
                          className="form-group mb-0"
                          style={{ position: "relative" }}
                        >
                          <Input
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            id="password"
                            autoComplete="off"
                            hasError={error}
                            maxLength={15}
                            isValid={isValidPassword}
                            placeholder="Create password"
                            labelname="Password"
                            onChange={(event: any) => {
                              setPassword(event.target.value);
                              setIsValidPassword(true);
                              setError("");
                            }}
                            clear={false}
                          />
                          <div
                            onClick={() => setPasswordShown(!passwordShown)}
                            className={Styles.eye}
                          >
                            <PasswordEye
                              color={passwordShown ? LINKCOLOR : SECONDARYCOLOR}
                            />
                          </div>
                        </div>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        <div className="px-2">
                          <div className="d-flex align-items-center py-2">
                            <div className="pr-2">
                              {checks.hasLowerCase ? (
                                <TickSvg
                                  color={SUCCESS}
                                  width="15"
                                  height="15"
                                />
                              ) : (
                                <div
                                  className={Styles.circle}
                                  style={{
                                    background: checks.hasLowerCase
                                      ? SUCCESS
                                      : SECONDARYCOLOR,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="pr-2">
                              <p
                                className={Styles.passwordInstructions}
                                style={{
                                  color: checks.hasLowerCase
                                    ? SUCCESS
                                    : SECONDARYCOLOR,
                                }}
                              >
                                One lowercase character
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center py-2">
                            <div className="pr-2">
                              {checks.hasUpperCase ? (
                                <TickSvg
                                  color={SUCCESS}
                                  width="15"
                                  height="15"
                                />
                              ) : (
                                <div
                                  className={Styles.circle}
                                  style={{
                                    background: checks.hasUpperCase
                                      ? SUCCESS
                                      : SECONDARYCOLOR,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="pr-2">
                              <p
                                className={Styles.passwordInstructions}
                                style={{
                                  color: checks.hasUpperCase
                                    ? SUCCESS
                                    : SECONDARYCOLOR,
                                }}
                              >
                                One uppercase character
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center py-2">
                            <div className="pr-2">
                              {checks.hasNumber ? (
                                <TickSvg
                                  color={SUCCESS}
                                  width="15"
                                  height="15"
                                />
                              ) : (
                                <div
                                  className={Styles.circle}
                                  style={{
                                    background: checks.hasNumber
                                      ? SUCCESS
                                      : SECONDARYCOLOR,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="pr-2">
                              <p
                                className={Styles.passwordInstructions}
                                style={{
                                  color: checks.hasNumber
                                    ? SUCCESS
                                    : SECONDARYCOLOR,
                                }}
                              >
                                One number character
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center py-2">
                            <div className="pr-2">
                              {checks.hasSpecialChar ? (
                                <TickSvg
                                  color={SUCCESS}
                                  width="15"
                                  height="15"
                                />
                              ) : (
                                <div
                                  className={Styles.circle}
                                  style={{
                                    background: checks.hasSpecialChar
                                      ? SUCCESS
                                      : SECONDARYCOLOR,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="pr-2">
                              <p
                                className={Styles.passwordInstructions}
                                style={{
                                  color: checks.hasSpecialChar
                                    ? SUCCESS
                                    : SECONDARYCOLOR,
                                }}
                              >
                                One special character
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center py-2">
                            <div className="pr-2">
                              {checks.hasMinLength ? (
                                <TickSvg
                                  color={SUCCESS}
                                  width="15"
                                  height="15"
                                />
                              ) : (
                                <div
                                  className={Styles.circle}
                                  style={{
                                    background: checks.hasMinLength
                                      ? SUCCESS
                                      : SECONDARYCOLOR,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="pr-2">
                              <p
                                className={Styles.passwordInstructions}
                                style={{
                                  color: checks.hasMinLength
                                    ? SUCCESS
                                    : SECONDARYCOLOR,
                                }}
                              >
                                At least 8 characters and maximum 15 characters
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="py-2">
                          <div
                            style={
                              validPassword
                                ? {}
                                : { cursor: "no-drop", opacity: "0.5" }
                            }
                          >
                            <Button
                              size={LARGE}
                              hoveredStyle={primaryButtonHoverStyle}
                              disabled={loading || !validPassword}
                              isLoading={loading}
                              style={primaryButtonStyle}
                              onClick={handelCreatePassword}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePassword;
