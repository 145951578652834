import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
  primaryButtonStyleOkyc,
  primaryButtonHoverStyleOkyc,
} from "../../../../../Shared/buttonStyles";
import { COMPRESSED, MEDIUM } from "../../../../../Shared/buttonSize";
import { validOtpNumber, validEmailOtp } from "../../../../../utils/validation";

function EmailServicePopup({ handleResponse, getOtp, name }: any) {
  const errorObject = {
    emailError: false,
    fetchError: false,
    otpError: false,
    consentError: false,
    nameError: false,
    nameEmptyError: false,
    emailEmptyError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isOtpTriggered, setIsOtpTriggered] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [loadingForOtp, setLoadingForOtp] = React.useState(false);
  const [isOtpSent, setIsOtpSent] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Send Otp");
  const emailVerificationRef = React.useRef<any>("");
  const nameRef = React.useRef<any>("");
  const otpRef = React.useRef<any>("");

  function isValidName(name: any) {
    const pattern = /[^a-zA-Z\s]/g;
    if (pattern.test(name)) {
      return false;
    }
    if (!name.replace(/\s/g, "").length) {
      return false;
    }
    if (name.length < 2) {
      return false;
    }
    return true;
  }

  async function checkemail() {
    try {
      const email = emailVerificationRef.current.value.toLowerCase().trim();
      const name = nameRef.current.value;

      if (name.length === 0) {
        setError(() => ({
          ...error,
          nameEmptyError: true,
        }));
        resetError();
        return false;
      }
      if (!isValidName(name)) {
        setError(() => ({
          ...error,
          nameError: true,
        }));
        resetError();
        return false;
      }
      if (email.length === 0) {
        setError(() => ({
          ...error,
          emailEmptyError: true,
        }));
        resetError();
        return false;
      }
      if (!validEmailOtp(email, false)) {
        setError(() => ({
          ...error,
          emailError: true,
        }));
        resetError();
        return false;
      }

      setLoadingForOtp(true);
      const res = await getOtp(email, setLoadingForOtp, name);
      if (res.result.is_otp_sent) {
        setIsOtpTriggered(true);
        setIsOtpSent(true);
        setButtonText("Resend Otp");
        setTimeout(() => setIsOtpSent(false), 60000);
      }
    } catch (e) {
      setError(() => ({
        ...error,
        fetchError: true,
      }));
      resetError();
    }
  }

  function isValid() {
    if (
      validEmailOtp(emailVerificationRef.current.value.trim(), false) &&
      isValidName(nameRef.current.value.trim()) &&
      validOtpNumber(otpRef.current.value.trim())
    )
      return setIsDisabled(false);
    return setIsDisabled(true);
  }

  function resetError() {
    setTimeout(() => setError({ ...errorObject }), 3000);
  }

  function setOtpStatus() {
    if (isOtpTriggered) setIsOtpTriggered(false);
    setIsDisabled(true);
  }

  function handleCheck() {
    const otp = otpRef.current.value;

    if (!validOtpNumber(otp)) {
      setError(() => ({
        ...error,
        otpError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    const consent = isChecked === true ? "Y" : "N";
    setLoading(true);
    handleResponse({ otp, consent }, setLoading);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <div className="d-flex flex-column mb-4 ">
                  <div style={{ width: "66%" }}>
                    <label className={`${Styles.label} flex-grow-1`}>
                      Name:
                    </label>
                    <InputField
                      id="id-input-voter"
                      text="lowerCase"
                      onChange={setOtpStatus}
                      inputRef={nameRef}
                      isError={error.nameError}
                      purpose="forVoter"
                    />
                    {error.nameEmptyError ? (
                      <ErrorMessage> Name should not be blank </ErrorMessage>
                    ) : error.nameError ? (
                      <ErrorMessage>Name is not valid</ErrorMessage>
                    ) : null}
                  </div>
                </div>

                <label className={`${Styles.label} flex-grow-1`}>Email</label>
                <div className="d-flex">
                  <div className={Styles.inputContainer}>
                    <InputField
                      id="id-input-voter"
                      text="lowerCase"
                      onChange={setOtpStatus}
                      inputRef={emailVerificationRef}
                      isError={error.emailError}
                      purpose="forVoter"
                    />
                    {error.emailEmptyError ? (
                      <ErrorMessage> Email should not be blank </ErrorMessage>
                    ) : error.emailError ? (
                      <ErrorMessage> Please enter a valid Email</ErrorMessage>
                    ) : null}
                  </div>

                  <div className={Styles.buttonContainer}>
                    <Button
                      id="id-input-submit-aadhaar-number"
                      hoveredStyle={primaryButtonHoverStyleOkyc}
                      disabled={isOtpSent}
                      size={COMPRESSED}
                      isLoading={loadingForOtp}
                      style={primaryButtonStyleOkyc}
                      onClick={() => {
                        checkemail();
                      }}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </div>
                {error.fetchError && (
                  <div>
                    <ErrorMessage>
                      Could not fetch info, please try later.
                    </ErrorMessage>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mb-4 ">
            {isOtpTriggered && (
              <div className="d-flex flex-column">
                <label className={`${Styles.label} flex-grow-1`}>OTP</label>
                <InputField
                  id="id-input-otp"
                  onChange={isValid}
                  maxLength={6}
                  inputRef={otpRef}
                  isError={error.otpError}
                  purpose="forOtp"
                />
                {error.otpError && (
                  <ErrorMessage>Please enter a valid OTP</ErrorMessage>
                )}
              </div>
            )}
          </div>
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-okyc"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailServicePopup;
