import React from "react";
import { SECONDARYCOLOR } from "../colors";

function RupeeSVG({
  color = SECONDARYCOLOR,
  width = "10",
  height = "16",
  margin = "0",
}: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__rupee-indian_1_"
      width={width}
      height={height}
      data-name="rupee-indian (1)"
      viewBox="0 0 11.484 18.006"
      style={{ fill: color, marginBottom: margin }}
    >
      <path
        id="prefix__Path_441"
        d="M84.175 4.079a.4.4 0 0 0-.294-.115h-2.187a4.426 4.426 0 0 0-.819-1.841h2.98a.393.393 0 0 0 .409-.409V.409A.393.393 0 0 0 83.855 0h-10.64a.394.394 0 0 0-.409.409v1.7a.415.415 0 0 0 .409.409h1.855q2.7 0 3.427 1.445h-5.282a.394.394 0 0 0-.409.409v1.3a.394.394 0 0 0 .409.409h5.461a2.5 2.5 0 0 1-1.311 1.6 5.771 5.771 0 0 1-2.718.55h-1.432a.415.415 0 0 0-.409.409v1.624a.382.382 0 0 0 .115.281q2.455 2.609 6.369 7.3a.377.377 0 0 0 .32.153h2.49a.371.371 0 0 0 .371-.23.349.349 0 0 0-.051-.435q-3.734-4.578-5.87-6.855a6.486 6.486 0 0 0 3.53-1.407 4.781 4.781 0 0 0 1.65-2.993h2.148a.394.394 0 0 0 .409-.409v-1.3a.4.4 0 0 0-.112-.29z"
        data-name="Path 441"
        transform="translate(-72.806)"
      />
    </svg>
  );
}

export default RupeeSVG;
