import React from "react";

function Delete({ className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__delete-button"
      className={className}
      width="15"
      height="20"
      viewBox="0 0 16.764 20.731"
    >
      <g id="prefix__Group_114" data-name="Group 114">
        <path
          id="prefix__Path_437"
          d="M60.551 2.484h-3.227a2.805 2.805 0 00-5.574 0h-3.227a2.371 2.371 0 00-2.368 2.369v.122a2.371 2.371 0 001.5 2.2v11.188a2.371 2.371 0 002.368 2.369h9.034a2.371 2.371 0 002.368-2.369V7.174a2.371 2.371 0 001.5-2.2v-.122a2.371 2.371 0 00-2.374-2.368zm-6.014-1.361a1.686 1.686 0 011.652 1.361h-3.3a1.686 1.686 0 011.648-1.361zm5.763 17.24a1.247 1.247 0 01-1.245 1.245H50.02a1.247 1.247 0 01-1.245-1.245V7.342H60.3zm1.5-13.389a1.247 1.247 0 01-1.245 1.245H48.523a1.247 1.247 0 01-1.245-1.245v-.122a1.247 1.247 0 011.245-1.245h12.028A1.247 1.247 0 0161.8 4.852v.122z"
          data-name="Path 437"
          transform="translate(-46.155)"
        />
        <path
          id="prefix__Path_438"
          d="M158.637 257.018a.562.562 0 00.562-.562v-6.324a.562.562 0 10-1.123 0v6.324a.562.562 0 00.561.562z"
          data-name="Path 438"
          transform="translate(-153.266 -238.846)"
        />
        <path
          id="prefix__Path_439"
          d="M228.707 257.018a.562.562 0 00.562-.562v-6.324a.562.562 0 10-1.123 0v6.324a.562.562 0 00.561.562z"
          data-name="Path 439"
          transform="translate(-220.324 -238.846)"
        />
        <path
          id="prefix__Path_440"
          d="M298.776 257.018a.562.562 0 00.562-.562v-6.324a.562.562 0 10-1.123 0v6.324a.562.562 0 00.561.562z"
          data-name="Path 440"
          transform="translate(-287.382 -238.846)"
        />
      </g>
    </svg>
  );
}

export default Delete;
