import React, { useRef, useState } from "react";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import { checkUAN } from "../../../../../utils/validation";

const errorObject = {
  uanNumberError: false,
  consentError: false,
};

const productName = "UAN Number";

const UANAdvancePopup = ({ handleResponse, name }: any) => {
  const uanNumberRef = useRef<any>("");
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [error, setError] = useState({ ...errorObject });
  const [loading, setLoading] = React.useState(false);

  function isValid() {
    if (checkUAN(uanNumberRef?.current?.value)) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    setError(() => ({
      ...error,
      uanNumberError: true,
    }));

    return setIsDisabled(true);
  }

  function handleCheck() {
    const uanNumber = uanNumberRef?.current?.value;
    const consent = isChecked === true ? "Y" : "N";

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }

    setLoading(true);
    handleResponse({ uanNumber, consent }, setLoading);
  }

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>
              {productName}
            </label>
            <InputField
              id="uan-advance-number"
              type="number"
              onChange={isValid}
              maxLength={12}
              inputRef={uanNumberRef}
              isError={error.uanNumberError}
              purpose="forPan"
            />
            {error.uanNumberError && (
              <ErrorMessage>{`Please enter a valid ${productName}`}</ErrorMessage>
            )}
          </div>
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-panNumber"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              isLoading={loading}
              size={MEDIUM}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UANAdvancePopup;
