import React from "react";
import { SECONDARYCOLOR } from "../colors";

function PasswordEye({
  color = SECONDARYCOLOR,
  width = "23.9px",
  height = "10.9px",
}: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.945 13.973"
      style={{ fill: color }}
    >
      <g id="eye" transform="translate(4.086 -125.957)">
        <path
          id="Path_1060"
          data-name="Path 1060"
          className="cls-1"
          d="M194.723,192a2.723,2.723,0,1,0,2.723,2.723A2.726,2.726,0,0,0,194.723,192Zm0,4.084a1.361,1.361,0,1,1,1.361-1.361A1.363,1.363,0,0,1,194.723,196.084Z"
          transform="translate(-184.836 -61.779)"
        />
        <path
          id="Path_1061"
          data-name="Path 1061"
          className="cls-1"
          d="M26.745,132.885l-3.781-2.353a17.23,17.23,0,0,0-17.983,0L1.2,132.885a2.439,2.439,0,0,0,0,4.2l3.781,2.353a17.23,17.23,0,0,0,17.983,0l3.781-2.353a2.439,2.439,0,0,0,0-4.2Zm-12.772,7.341a5.24,5.24,0,1,1,5.24-5.24,5.246,5.246,0,0,1-5.24,5.24Z"
          transform="translate(-4.086 -2.043)"
        />
      </g>
    </svg>
  );
}

export default PasswordEye;
