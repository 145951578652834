import React from "react";

function ReportResultFileSvg(classes: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path
        d="M20.9 6.08457L15.1953 0.562695C14.8484 0.225195 14.375 0.0330078 13.8875 0.0330078L3.14375 0.00488281C1.84063 0.00488281 0.78125 1.05488 0.78125 2.34863V21.6518C0.78125 22.9221 1.87344 24.0002 3.16719 24.0002H19.0578C20.3281 24.0002 21.3594 22.9455 21.3594 21.6471V7.20488C21.3594 6.76894 21.1953 6.37051 20.9 6.08457ZM15.1719 3.1502L18.1203 6.0002H15.6828C15.4109 6.0002 15.1719 5.78926 15.1719 5.5502V3.1502ZM19.0578 22.1252H3.16719C2.90469 22.1252 2.65625 21.8955 2.65625 21.6471V2.34863C2.65625 2.1002 2.88594 1.87988 3.14375 1.87988L13.2969 1.90801V5.5502C13.2969 6.82988 14.3656 7.8752 15.6828 7.8752H19.4844V21.6471C19.4844 21.8814 19.325 22.1252 19.0578 22.1252Z"
        fill="#969696"
      />
      <path
        d="M4.85938 12.5156C4.85938 13.0312 5.28125 13.4531 5.79688 13.4531H14C14.5156 13.4531 14.9375 13.0312 14.9375 12.5156C14.9375 12 14.5156 11.5781 14 11.5781H5.79688C5.28125 11.5781 4.85938 12 4.85938 12.5156ZM5.75 9.70312H11.1875C11.7031 9.70312 12.125 9.28125 12.125 8.76562C12.125 8.25 11.7031 7.82812 11.1875 7.82812H5.75C5.23438 7.82812 4.8125 8.25 4.8125 8.76562C4.8125 9.28125 5.23438 9.70312 5.75 9.70312ZM16.3438 15.3281H5.79688C5.28125 15.3281 4.85938 15.75 4.85938 16.2656C4.85938 16.7812 5.28125 17.2031 5.79688 17.2031H16.3438C16.8594 17.2031 17.2812 16.7812 17.2812 16.2656C17.2812 15.75 16.8594 15.3281 16.3438 15.3281Z"
        fill="#969696"
      />
    </svg>
  );
}

export default ReportResultFileSvg;
