import React from "react";
import Styles from "./switch.module.scss";
import sharedStyles from "../../components/application/dashboard/dashboard.module.scss";
import StylesDemoPortal from "./demoPortalSwitch.module.scss";

function Switch({
  positiveLabel,
  negativeLabel,
  isDisabled = null,
  forDemoPortal = false,
  specialCase = false,
  ...props
}: any) {
  const styleToUse = props.purpose === "forDashboard" ? sharedStyles : Styles;
  return (
    <div className="d-flex align-items-center">
      <div className="pr-2">
        <p
          className={`m-0 ${
            specialCase && props.requesttypeforspecialcase === "test"
              ? Styles.switch_text_special
              : Styles.switch_text
          } `}
        >
          {negativeLabel}
        </p>
      </div>
      <div className="pr-2">
        <div className={Styles.switch__container}>
          <input
            className={
              !forDemoPortal
                ? `${styleToUse.switch} ${styleToUse.switch__shadow}`
                : `${StylesDemoPortal.switch} ${StylesDemoPortal.switch__shadow}`
            }
            type="checkbox"
            disabled={isDisabled}
            {...props}
          />
          <label
            htmlFor={props.id}
            className="mb-0"
            style={isDisabled ? { cursor: "no-drop" } : {}}
          ></label>
        </div>
      </div>
      <div className="pr-2">
        <p
          className={`m-0 ${
            specialCase && props.requesttypeforspecialcase === "production"
              ? Styles.switch_text_special
              : Styles.switch_text
          }`}
        >
          {positiveLabel}
        </p>
      </div>
    </div>
  );
}

export default Switch;
