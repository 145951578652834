import ReactGA from "react-ga4";

interface GaOptions {
  userId: string;
}

const measurementId = process.env.REACT_APP_MEASUREMENT_ID;

export const initGA = (trackingId: string, gaOptions?: GaOptions) => {
  const initializeOptions = {
    trackingId,
    gaOptions,
  };
  ReactGA.initialize([initializeOptions]);
};

/**
 * pageview - send pageview
 * @param {string} hitType
 * @param {string} page
 */

export const pageView = (hitType: string, page: string) => {
  ReactGA.initialize(measurementId as string);
  ReactGA.send({ hitType, page });
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */

export const customEvent = (
  category: string,
  action: string,
  label: string = ""
) => {
  ReactGA.initialize(measurementId as string);
  return ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
