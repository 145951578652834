// First step Create Organisation sub part for adding information of business and tech POC.
//  On completion successful creation of organisation
import React, { useContext } from "react";
import Styles from "./businessTechPOC.module.scss";
import PocForm from "../poc-form/pocForm";
import Button from "../../../Shared/button/button";
import TickSvg from "../../../Shared/svg/tickSvg";
import ArrowDown from "../../../Shared/svg/arrowDown";
import { Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { callApi } from "../../../api/fetch";
import {
  poc_info,
  default_service,
} from "../../../constants/onboardingConstants";
import { SUCCESS } from "../../../Shared/colors";
import Toast from "../../../Shared/toast/toast";
import { onboardingSidebarContext } from "../../../utils/onboardingSidebarContext";
import { LARGE } from "../../../Shared/buttonSize";

function BusinessTechPOC({
  orgName,
  orgCategory,
  orgCountryCode,
  orgTradeName,
  categories,
}: any) {
  const history = useHistory();
  window.addEventListener("popstate", () => {
    history.go(1);
  });
  const [activeKey, setActiveKey] = React.useState("0");
  const [success, setSuccess] = React.useState({
    showTechPocForm: false,
    showBusPocForm: false,
  });
  const [businessPOCInfo, setBusinessPOCInfo] = React.useState({
    name: "",
    email: "",
    designation: "",
    phone: "",
  });
  const [techPOCInfo, setTechPOCInfo] = React.useState({
    name: "",
    email: "",
    designation: "",
    phone: "",
  });
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [loading, setLoading] = React.useState(false);
  const sidebarContext = useContext(onboardingSidebarContext);

  // Custom Toggle function here
  function CustomToggle({ children, onClick, eventKey, successShadow }: any) {
    return (
      <div
        className={
          activeKey.includes(eventKey)
            ? Styles.activeToggle
            : Styles.customToggle
        }
        style={
          successShadow
            ? { boxShadow: "0 3px 6px 0 rgba(0, 117, 243, 0.16)" }
            : { boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)" }
        }
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  const getOrgCategory = () => {
    if (orgCategory?.label.toLowerCase() === "other") {
      return categories.filter(
        (item: any) =>
          item?.name?.toLowerCase() === "other" &&
          item?.sub_category?.toLowerCase() === "other"
      )?._id;
    }

    return orgCategory.value;
  };

  async function handelFormSubmission() {
    setLoading(true);
    try {
      const data = await callApi("/organisation", {
        method: "POST",
        data: {
          name: orgName,
          trade_name: orgTradeName,
          category: orgCategory.label,
          category_id: getOrgCategory(),
          country_code: orgCountryCode,
          business_poc: businessPOCInfo,
          technical_poc: techPOCInfo,
        },
      });
      localStorage.setItem("organisation_id", data._id);

      // creating new org take some time so we will redirect user after some time
      await new Promise((resolve) => setTimeout(resolve, 10000)); // 10 seconds

      sidebarContext.setStepCompleted([
        ...sidebarContext.step_completed,
        poc_info,
      ]);
      sidebarContext.setOrgCreated(true);
      sidebarContext.setNextStep(default_service);
      sidebarContext.setCreateOrgProgress(100);
      sidebarContext.setSelectServiceProgress(10);
      return history.push("/onboarding/select-service");
    } catch (err) {
      toastTimeout(true, "error", (err as Error).message);
    } finally {
      setLoading(false);
    }
    return;
  }
  return (
    <div className="container-fluid p-0">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className={`${Styles.authActionsBackground}`}>
        <div
          style={{ height: "100vh" }}
          className={`d-flex justify-content-center ${
            success.showBusPocForm && success.showTechPocForm
              ? " align-items-center"
              : ""
          }`}
        >
          <div>
            <div className="py-3">
              <p className={`${Styles.headingText} mb-0 text-center`}>
                Just a few more information
              </p>
              <p className={`${Styles.educationalText} mb-0 text-center`}>
                this will help us to serve you better
              </p>
            </div>
            <div className={`${Styles.formWidth} py-2`}>
              <Accordion defaultActiveKey="0" activeKey={activeKey}>
                <div className="py-2">
                  <CustomToggle
                    successShadow={success.showBusPocForm}
                    eventKey="0"
                    onClick={() => setActiveKey("0")}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <div className="px-2 flex-grow-1">
                        <div className="d-flex">
                          <p className={`mb-0 ${Styles.accordion_header}`}>
                            Business owner details
                          </p>
                        </div>
                      </div>
                      <div className="px-2">
                        {success.showBusPocForm ? (
                          <div style={{ marginBottom: "5px" }}>
                            <TickSvg color={SUCCESS} />
                          </div>
                        ) : activeKey === "0" ? (
                          <ArrowDown />
                        ) : (
                          <div className={Styles.rotateArrowRight}>
                            <ArrowDown />
                          </div>
                        )}
                      </div>
                    </div>
                  </CustomToggle>
                  <Accordion.Collapse eventKey="0">
                    <div
                      style={{
                        position: "relative",
                        zIndex: 0,
                      }}
                    >
                      <PocForm
                        hasError={() => {
                          setSuccess((success) => ({
                            ...success,
                            showBusPocForm: false,
                          }));
                        }}
                        click={(value: any) => {
                          setBusinessPOCInfo((businessPOCInfo) => ({
                            ...businessPOCInfo,
                            name: value.name,
                            email: value.email,
                            designation: value.designation,
                            phone: value.phoneNumber,
                          }));
                          if (!success.showTechPocForm) {
                            setActiveKey("1");
                          } else {
                            setActiveKey("2");
                          }
                          setSuccess((success) => ({
                            ...success,
                            showBusPocForm: true,
                          }));
                        }}
                        orgCountryCode={orgCountryCode}
                      />
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="py-2">
                  <CustomToggle
                    successShadow={success.showTechPocForm}
                    eventKey="1"
                    onClick={() => setActiveKey("1")}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <div className="px-2 flex-grow-1">
                        <div className="d-flex">
                          <p className={`mb-0 ${Styles.accordion_header}`}>
                            Tech owner details
                          </p>
                        </div>
                      </div>
                      <div className="px-2">
                        {success.showTechPocForm ? (
                          <div style={{ marginBottom: "5px" }}>
                            <TickSvg color={SUCCESS} />
                          </div>
                        ) : activeKey === "1" ? (
                          <ArrowDown />
                        ) : (
                          <div className={Styles.rotateArrowRight}>
                            <ArrowDown />
                          </div>
                        )}
                      </div>
                    </div>
                  </CustomToggle>
                  <Accordion.Collapse eventKey="1">
                    <div
                      style={{
                        position: "relative",
                        zIndex: 0,
                      }}
                    >
                      <PocForm
                        hasError={() => {
                          setSuccess((success) => ({
                            ...success,
                            showTechPocForm: false,
                          }));
                        }}
                        click={(value: any) => {
                          setTechPOCInfo((techPOCInfo) => ({
                            ...techPOCInfo,
                            name: value.name,
                            email: value.email,
                            designation: value.designation,
                            phone: value.phoneNumber,
                          }));
                          if (!success.showBusPocForm) {
                            setActiveKey("0");
                          } else {
                            setActiveKey("2");
                          }
                          setSuccess((success) => ({
                            ...success,
                            showTechPocForm: true,
                          }));
                        }}
                        orgCountryCode={orgCountryCode}
                      />
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
              <div className={"text-center py-3"}>
                <Button
                  size={LARGE}
                  hoveredStyle={primaryButtonHoverStyle}
                  buttontext="Next"
                  disabled={
                    loading ||
                    !success.showBusPocForm ||
                    !success.showTechPocForm
                  }
                  isLoading={loading}
                  style={primaryButtonStyle}
                  onClick={handelFormSubmission}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessTechPOC;
