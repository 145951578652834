import React from "react";
import Styles from "./authSidebar.module.scss";
import { AUTHSIDEBARGRADIENT } from "../../../Shared/colors";
import logo from "../../../assets/images/logo.png";
function AuthSidebar(props: any) {
  const { img, info } = props;
  return (
    <div
      className={`${Styles.authSidebar} sticky-top`}
      style={{ background: AUTHSIDEBARGRADIENT, zIndex: 2 }}
    >
      <div
        className="d-flex align-items-end justify-content-center"
        style={{ height: "50%" }}
      >
        <div>
          <div className="d-flex justify-content-center">{img}</div>
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "30%" }}
      >
        {info}
      </div>
      <div
        className="text-center d-flex align-items-center justify-content-center"
        style={{ height: "20%" }}
      >
        <img src={logo} alt="logo" height="35px" />
      </div>
    </div>
  );
}

export default AuthSidebar;
