import React, { useState } from "react";
import Styles from "../passport-service-popup/passportServicePopup.module.scss";
import InputField from "../../Shared/inputField/inputField";

import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import {
  validEmail,
  validName,
  validPhoneNumber,
  validPincode,
} from "../../../../../utils/validation";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import Button from "../../../../../Shared/button/button";

const defaultErrors = {
  phoneNumberError: false,
  emailError: false,
  nameError: false,
  pincodeError: false,
  consentError: false,
  allFieldEmpty: true,
};

const DigitalAgeFraudPopup = ({ name, handleResponse }: any) => {
  const phoneNumberRef = React.useRef<any>("");
  const emailRef = React.useRef<any>("");
  const nameRef = React.useRef<any>("");
  const pincodeRef = React.useRef<any>("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [isChecked, setIsChecked] = useState(false);

  const [isDisabled] = React.useMemo(() => {
    let disabled = false;
    if (
      errors.phoneNumberError ||
      errors.emailError ||
      errors.nameError ||
      errors.pincodeError ||
      errors.allFieldEmpty
    ) {
      disabled = true;
    }

    return [disabled];
  }, [errors]);

  function isValidPhoneNumber() {
    if (phoneNumberRef.current.value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumberError: false,
      }));

      if (
        !emailRef.current.value &&
        !pincodeRef.current.value &&
        !nameRef.current.value
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          allFieldEmpty: true,
        }));
      }
      return;
    }

    if (!validPhoneNumber(phoneNumberRef.current.value.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumberError: true,
        allFieldEmpty: false,
      }));

      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumberError: false,
      allFieldEmpty: false,
    }));
  }

  function isValidEmail() {
    if (emailRef.current.value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: false,
      }));

      if (
        !pincodeRef.current.value &&
        !phoneNumberRef.current.value &&
        !nameRef.current.value
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          allFieldEmpty: true,
        }));
      }

      return;
    }

    if (!validEmail(emailRef.current.value.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: true,
        allFieldEmpty: false,
      }));

      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      emailError: false,
      allFieldEmpty: false,
    }));
  }

  function isValidName() {
    if (nameRef.current.value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nameError: false,
      }));

      if (
        !emailRef.current.value &&
        !phoneNumberRef.current.value &&
        !pincodeRef.current.value
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          allFieldEmpty: true,
        }));
      }

      return;
    }

    if (!validName(nameRef.current.value.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nameError: true,
        allFieldEmpty: false,
      }));

      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      nameError: false,
      allFieldEmpty: false,
    }));
  }

  function isValidPincode() {
    if (pincodeRef.current.value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pincodeError: false,
      }));

      if (
        !emailRef.current.value &&
        !phoneNumberRef.current.value &&
        !nameRef.current.value
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          allFieldEmpty: true,
        }));
      }

      return;
    }

    if (!validPincode(pincodeRef.current.value.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pincodeError: true,
        allFieldEmpty: false,
      }));

      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      pincodeError: false,
      allFieldEmpty: false,
    }));
  }

  function isConsentProvided(value: boolean) {
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        consentError: true,
      }));
      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      consentError: false,
    }));
  }

  function handleRunVerification() {
    if (!isChecked) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        consentError: true,
      }));
      return;
    }

    const phoneNumber = phoneNumberRef.current.value;
    const email = emailRef.current.value;
    const name = nameRef.current.value;
    const pincode = pincodeRef.current.value;

    if (!phoneNumber && !email && !name && !pincode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        allFieldEmpty: true,
      }));

      return;
    }

    const consent = isChecked ? "Y" : "N";
    let reqBody = {};

    if (phoneNumber) {
      reqBody = { ...reqBody, customer_phone_number: phoneNumber };
    }
    if (email) {
      reqBody = { ...reqBody, customer_email: email };
    }
    if (name) {
      reqBody = { ...reqBody, customer_name: name };
    }
    if (pincode) {
      reqBody = { ...reqBody, pincode: pincode };
    }

    setLoading(true);
    handleResponse({ ...reqBody, consent }, setLoading);
  }

  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          {/* Phone Number */}
          <div className="mb-2">
            <label className={`${Styles.label} flex-grow-1`}>
              Phone Number
            </label>
            <InputField
              id="id-input-mobile-number"
              onChange={isValidPhoneNumber}
              maxLength={10}
              inputRef={phoneNumberRef}
              isError={errors.phoneNumberError}
              purpose="forPan"
            />
            {errors.phoneNumberError && (
              <ErrorMessage>Please enter a valid Phone number</ErrorMessage>
            )}
          </div>

          {/* Email */}
          <div className="mb-2">
            <label className={`${Styles.label} flex-grow-1`}>Email</label>

            <div className={Styles.inputContainer}>
              <InputField
                id="id-input-voter"
                text="lowerCase"
                onChange={isValidEmail}
                inputRef={emailRef}
                isError={errors.emailError}
                purpose="forVoter"
              />
              {errors.emailError ? (
                <ErrorMessage> Please enter a valid Email</ErrorMessage>
              ) : null}

              {/* {errors.emailEmptyError ? (
                <ErrorMessage> Email should not be blank </ErrorMessage>
              ) : errors.emailError ? (
                <ErrorMessage> Please enter a valid Email</ErrorMessage>
              ) : null} */}
            </div>
          </div>

          <div className="mb-2">
            <label className={`${Styles.label} flex-grow-1`}>Name</label>
            <InputField
              id="id-input-mobile-number"
              inputRef={nameRef}
              onChange={isValidName}
              isError={errors.nameError}
              purpose="forPan"
            />

            {errors.nameError && (
              <ErrorMessage>Name should not contains numbers</ErrorMessage>
            )}
          </div>

          <div className="mb-2">
            <label className={`${Styles.label} flex-grow-1`}>Pincode</label>
            <InputField
              id="id-input-mobile-number"
              onChange={isValidPincode}
              maxLength={6}
              inputRef={pincodeRef}
              isError={errors.pincodeError}
              purpose="forPan"
            />
            {errors.pincodeError && (
              <ErrorMessage>Please enter a valid Pincode</ErrorMessage>
            )}
          </div>

          {/* consent */}

          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isConsentProvided(e.target.checked);
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {errors.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>

          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-telecom"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                // setTimeout(() => setError({ ...errorObject }), 2000);
                handleRunVerification();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalAgeFraudPopup;
