import parse from "@bany/curl-to-json";

export type paramsType = {
  name: string;
  example: any;
  required: boolean;
  description: string;
};

export const addPaths = (docs: any[]): any[] => {
  const updatedDocs = docs.map((doc) => {
    const location = (parse(doc.curl) as any)?.location || "";
    const path = location?.split(".one")[1] || "";
    return {
      ...doc,
      path: path,
    };
  });

  return updatedDocs;
};

export function toOpenApiResponseObject(code: any): any {
  if (!code) return code;

  const obj: any = {};
  for (const [key, value] of Object.entries(code) as any) {
    if (value === undefined) {
      obj[key] = {
        type: "",
        example: value,
      };
    } else if (value === null) {
      obj[key] = {
        type: "object",
        example: value,
      };
    } else if (typeof value === "object") {
      if (Array.isArray(value)) {
        if (typeof value[0] === "object") {
          if (typeof value === "object") {
            obj[key] = {
              type: "array",
              items: {
                type: typeof value[0],
              },
              example: value,
            };
          } else {
            obj[key] = toOpenApiResponseObject(value);
          }
        } else {
          obj[key] = {
            type: "array",
            items: {
              type: typeof value[0],
            },
            example: value,
          };
        }
      } else {
        obj[key] = {
          type: "object",
          properties: toOpenApiResponseObject(value),
        };
      }
    } else {
      obj[key] = {
        type: typeof value,
        example: value,
      };
    }
  }
  return obj;
}

export function createOpenApiObjectBasicData(options: any) {
  return {
    arn: options.arn,
    category: options.category,
    path: options.path,
    name: options.name,
    description: options.description,
    tag: options.type,
    curl: options.curl,
  };
}

export function createOpenApiObjectParameters(options: paramsType[]) {
  return options.map((param) => {
    return {
      ...param,
      in: "header",
    };
  });
}

function isAllPropertiesAreRequired(data: any): boolean {
  const required = JSON.stringify(data.required.sort());
  const keys = JSON.stringify(Object.keys(data.properties).sort());
  return keys === required;
}

function addRequiredForNestedParameter(data: any) {
  for (const [key, value] of Object.entries(data?.properties)) {
    if ("properties" in (value as any)) {
      const ans = isAllPropertiesAreRequired(value);
      if (ans) {
        data.required.push(key);
      }
      data.properties[key] = addRequiredForNestedParameter(value);
    }
  }
  return data;
}

function unflatObject(data: any) {
  const type = "object";
  const properties: any = {};
  const required: string[] = [];

  if (Array.isArray(data)) {
    for (const param of data) {
      const keys = param.name.split(".");
      const paramName = keys[0];
      if (!(paramName in properties)) {
        properties[paramName] = {
          type: type,
        };
      }
      if (!("properties" in properties[paramName])) {
        properties[paramName]["properties"] = {};
      }
      if (!("required" in properties[paramName])) {
        properties[paramName]["required"] = [];
      }
      if (keys.length > 1) {
        const name = param.name.slice(paramName.length + 1);
        if (param.required) {
          properties[paramName]["required"].push(name);
        }
        properties[paramName]["properties"] = {
          ...properties[paramName]?.["properties"],
          ...unflatObject({
            ...param,
            name: name,
          }),
        };
      } else {
        if (param.required) {
          required.push(paramName);
        }
        properties[paramName] = {
          example: param.example,
          required: param.required,
          type: typeof param.example,
          description: param.description,
        };
      }
    }
  } else {
    return {
      [data.name]: {
        example: data.example,
        required: data.required,
        type: typeof data.example,
        description: data.description,
      },
    };
  }

  return {
    type: type,
    required: required,
    properties: properties,
  };
}

export function createOpenApiObjectRequestBody(options: paramsType[]) {
  const unflattenedObject = unflatObject(options);
  const reqBody = addRequiredForNestedParameter(unflattenedObject);

  return {
    content: {
      "application/json": {
        schema: {
          ...reqBody,
        },
      },
    },
  };
}

export function createMultiProductOpenApiObjectReqAndRes(
  format: "req" | "res",
  options: any[]
) {
  const obj: any = {};
  const schema: any = {};
  const mapping: any = {};
  let discriminatorName: string = "";

  if (options[0].type === "REPORT") {
    discriminatorName = "task_id";
  } else {
    discriminatorName = "consent";
  }

  if (format === "req") {
    for (const doc of options) {
      const arnKey = String(doc.arn);
      const param = createOpenApiObjectRequestBody(doc.params);
      mapping[doc.name.toUpperCase()] = String(
        `#/components/schemas/${arnKey}`
      );
      schema[arnKey] = param.content["application/json"].schema;
    }
  }

  if (format === "res") {
    for (const doc of options) {
      for (const [key, value] of Object.entries(doc.responses)) {
        for (const [code, object] of Object.entries(value as any)) {
          const arnKey = String(`${doc.arn}_${code}`);
          mapping[String(`${doc.name.toUpperCase()} | ${code}`)] = String(
            `#/components/schemas/${arnKey}`
          );
          const encoded = toOpenApiResponseObject(object);
          schema[arnKey] = {
            type: "object",
            properties: encoded,
          };
        }
        obj[key] = {
          content: {
            "application/json": {
              schema: {
                discriminator: {
                  propertyName: "response_code",
                  mapping: mapping,
                },
              },
            },
          },
        };
      }
    }
  }

  if (format === "req") {
    return {
      requestBody: {
        content: {
          "application/json": {
            schema: {
              discriminator: {
                propertyName: discriminatorName,
                mapping: mapping,
              },
            },
          },
        },
      },
      components: {
        schemas: schema,
      },
    };
  } else {
    return {
      responses: obj,
      components: {
        schemas: schema,
      },
    };
  }
}

export function createOpenApiObjectResAndComponents(arn: string, options: any) {
  const res: any = {};
  const schema: any = {};

  for (const [key, value] of Object.entries(options)) {
    const mapping: any = {};
    for (const [code, object] of Object.entries(value as any)) {
      const arnKey = String(`${arn}_${code}`);
      mapping[code] = String(`#/components/schemas/${arnKey}`);
      const encoded = toOpenApiResponseObject(object);
      schema[arnKey] = {
        type: "object",
        properties: encoded,
      };
    }
    res[key] = {
      content: {
        "application/json": {
          schema: {
            discriminator: {
              propertyName: "response_code",
              mapping: mapping,
            },
          },
        },
      },
    };
  }

  return {
    responses: res,
    components: {
      schemas: schema,
    },
  };
}

export function createMultiProductOpenApiObject(docs: any[]) {
  const paramsMap = new Map();
  for (const doc of docs) {
    const param = createOpenApiObjectParameters(doc.headers);
    for (const obj of param) {
      if (paramsMap.get(obj.name) === undefined) {
        paramsMap.set(obj.name, [obj]);
      }
    }
  }
  const params: any = [];
  for (const map of paramsMap) {
    params.push(map[1][0]);
  }

  const req = createMultiProductOpenApiObjectReqAndRes("req", docs);
  const res = createMultiProductOpenApiObjectReqAndRes("res", docs);

  const openApiObj = {
    request_body: req.requestBody,
    components: {
      schemas: { ...req.components.schemas, ...res.components.schemas },
    },
    responses: res.responses,
    parameters: params,
  };

  return openApiObj;
}

export function createOpenApiObject(
  arn: string,
  path: string,
  name: string,
  description: string,
  type: string,
  category: string,
  curl: any,
  headers: any,
  params: any,
  responses: any
) {
  const basic = createOpenApiObjectBasicData({
    arn,
    name,
    description,
    type,
    category,
    curl,
    path,
  });

  const para = createOpenApiObjectParameters(headers);
  const reqBody = createOpenApiObjectRequestBody(params);
  const res = createOpenApiObjectResAndComponents(arn, responses);

  const openApiObj = {
    ...basic,
    request_body: reqBody,
    components: res.components,
    responses: res.responses,
    parameters: para,
  };

  return openApiObj;
}

// Not in use but saved to transform to normal obj in future
export function toNormalJsonResponse(code: any): any {
  if (!code) return code;

  if (code.type === "object") {
    return toNormalJsonResponse(code.properties);
  }

  const obj: any = {};
  for (const [key, value] of Object.entries(code) as any) {
    if (value.type === "object") {
      obj[key] = toNormalJsonResponse(value.properties);
    } else if (value.type === "array") {
      if (value.items.type === "object") {
        if ("example" in value) {
          obj[key] = value.example.map((example: any) => {
            return example;
          });
        }
      } else {
        obj[key] = value.example;
      }
    } else {
      obj[key] = value.example;
    }
  }

  return obj;
}

export function getHeaders(parameters: any[]): any[] {
  return parameters.filter((param) => param.in === "header");
}

export function getParams(request_body: any): any[] {
  const paramObj: any =
    request_body.content["application/json"].schema.properties;
  return Object.keys(paramObj).map((param: string) => {
    return {
      name: param,
      required: paramObj[param].required,
      example: paramObj[param].example,
      description: paramObj[param].description,
    };
  });
}

export function createParams(params: any): paramsType[] {
  if (!params) return [];

  const param: paramsType[] = [];
  for (const [key, value] of Object.entries(params)) {
    const para: paramsType = {
      name: key,
      example: value,
      required: true,
      description: "Description here",
    };

    param.push(para);
  }
  return param;
}

export function updateParams(data: any, params: any): paramsType[] {
  if (!params || !data) return [];

  const param: paramsType[] = [];
  for (const [key, value] of Object.entries(data)) {
    if (key in params) {
      const para: paramsType = {
        name: key,
        example: value,
        required: params[key].required,
        description: params[key].description,
      };
      param.push(para);
    } else {
      const para: paramsType = {
        name: key,
        example: value,
        required: true,
        description: "Description here",
      };
      param.push(para);
    }
  }
  return param;
}
