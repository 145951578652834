import React from "react";

function BillingSVG(classes: any) {
  return (
    <svg
      width="24"
      height="29"
      viewBox="0 0 24 29"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path d="M21.602 2.7793C21.096 2.46673 20.5184 2.28877 19.9242 2.26231C19.33 2.23586 18.7389 2.36179 18.2071 2.62816L17.8583 2.80256C17.7084 2.86993 17.546 2.90477 17.3816 2.90477C17.2173 2.90477 17.0548 2.86993 16.9049 2.80256L12.917 1.2446C12.0883 0.914559 11.1647 0.914559 10.336 1.2446L6.34807 2.83744C6.19819 2.90481 6.03572 2.93965 5.87139 2.93965C5.70705 2.93965 5.54458 2.90481 5.3947 2.83744L5.0459 2.66304C4.51712 2.399 3.92992 2.27367 3.33941 2.2988C2.74891 2.32393 2.17448 2.49871 1.67004 2.80671C1.16561 3.11472 0.747715 3.54586 0.455592 4.05966C0.163469 4.57346 0.00669942 5.15306 0 5.74406V23.1838C0 24.7256 0.612466 26.2042 1.70266 27.2944C2.79286 28.3846 4.27148 28.9971 5.81325 28.9971H17.4398C18.9815 28.9971 20.4602 28.3846 21.5503 27.2944C22.6405 26.2042 23.253 24.7256 23.253 23.1838V5.74406C23.253 5.15021 23.1013 4.5662 22.8124 4.04737C22.5235 3.52854 22.1069 3.09206 21.602 2.7793ZM13.9518 20.7655H6.9759C6.66755 20.7655 6.37182 20.643 6.15378 20.425C5.93575 20.2069 5.81325 19.9112 5.81325 19.6029C5.81325 19.2945 5.93575 18.9988 6.15378 18.7807C6.37182 18.5627 6.66755 18.4402 6.9759 18.4402H13.9518C14.2602 18.4402 14.5559 18.5627 14.7739 18.7807C14.992 18.9988 15.1145 19.2945 15.1145 19.6029C15.1145 19.9112 14.992 20.2069 14.7739 20.425C14.5559 20.643 14.2602 20.7655 13.9518 20.7655ZM16.2771 13.7896H6.9759C6.66755 13.7896 6.37182 13.6671 6.15378 13.4491C5.93575 13.231 5.81325 12.9353 5.81325 12.627C5.81325 12.3186 5.93575 12.0229 6.15378 11.8048C6.37182 11.5868 6.66755 11.4643 6.9759 11.4643H16.2771C16.5855 11.4643 16.8812 11.5868 17.0992 11.8048C17.3173 12.0229 17.4398 12.3186 17.4398 12.627C17.4398 12.9353 17.3173 13.231 17.0992 13.4491C16.8812 13.6671 16.5855 13.7896 16.2771 13.7896Z" />
    </svg>
  );
}

export default BillingSVG;
