import React from "react";
import Styles from "./ipWhitelistingHelperText.module.scss";

function IpWhitelistingHelperText({ helperPoints }: any) {
  return (
    <div className="p-3">
      <div className={`py-2 ${Styles.headingText}`}>
        Cases when IP whitelisting is required
      </div>
      <div className={Styles.headingTitle}>
        You will need IP whitelisting while using...
      </div>

      <div className="py-2 d-flex justify-content-between w-100">
        {helperPoints.map((point: any) => {
          return (
            <div
              key={`${point.question} ${point.answer}`}
              className={"p-4 px-4 mr-4 " + Styles.answerContainer}
            >
              <p className={`mb-0 ${Styles.questionText}`}>{point.question}</p>
              <p className={`mb-0 ${Styles.answerText}  ml-2 pl-1`}>
                {point.answer}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default IpWhitelistingHelperText;
