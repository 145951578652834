//Welcome screen as the user successfully added trial services to the test app.
//  Last Step of onboarding.
import React from "react";
import Styles from "./appCreationSuccess.module.scss";
import { useLocation } from "react-router-dom";
import Button from "../../../Shared/button/button";
import AuthSidebar from "../../auth/authSidebar/authSidebar";
import InformativeText from "../../auth/informativeText/informativeText";
import ClipEducation from "../../../assets/images/Generating_API_key_dashboard_illustration.svg";
import { useHistory } from "react-router-dom";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import TransitionPopUp from "../transition-pop-up/transitionPopUp";
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";
import { LARGE } from "../../../Shared/buttonSize";
import AddAppKey from "../../application/manage-apps/add-api-key/addApiKey";
import { useTitle } from "../../../Shared/useTitle";

function AppCreationSuccess() {
  const {
    state: { subscribedService: selectedServices },
  }: any = useLocation();
  const history = useHistory();
  const test_app_id = localStorage.getItem("test_app_id") || "";
  const [toggleApiKeyPopUp, setToggleApiKeyPopUp] = React.useState(false);
  //user will not be able to use browser back once the onboarding state is passed
  window.addEventListener("popstate", () => {
    history.go(1);
  });
  const subscribedService = selectedServices.filter(
    (selectedService: any) => selectedService.auto_approval === true
  );
  const approvalNeeded = selectedServices.filter(
    (selectedService: any) => selectedService.auto_approval === false
  );
  const [generateApiKeyLoading, setGenerateApiKeyLoading] = React.useState(
    false
  );
  const [
    toggleSkipForNowTransitionPopUp,
    setToggleSkipForNowTransitionPopUp,
  ] = React.useState(false);
  const messageArray = [
    "You can use the Trial Application created for you to test our services instantly.",
    "Go to Products to select the required services.",
    "Refer the documentations to guide you through the process and help you get started immediately.",
  ];
  const [loaderMessage, setLoaderMessage] = React.useState(messageArray[0]);
  const [apiKey, setApiKey] = React.useState("");
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }
  //set timer for the welcome Modal to appear while displaying welcome message
  function setIntervalFunction() {
    let i = 1;
    const timer = setInterval(() => {
      if (i >= messageArray.length) {
        clearInterval(timer);
        history.push("/application/dashboard");
        return;
      }
      setLoaderMessage(messageArray[i]);
      i += 1;
    }, 3000);
    return;
  }
  function goToManageAppsHandler() {
    setGenerateApiKeyLoading(true);
    callApi(`/app/${test_app_id}?action=generate_app_key`, {
      method: "PUT",
      data: {
        keyName: "Test Key",
      },
    })
      .then((responses) => {
        setApiKey(responses.apiKey);
        setGenerateApiKeyLoading(false);
        setToggleApiKeyPopUp(true);
      })
      .catch((error) => {
        setGenerateApiKeyLoading(false);
        setToggleSkipForNowTransitionPopUp(false);
        toastTimeout(true, "error", error.message);
        // TODO: add sentry error
      });
  }
  function handleGoToChecklist() {
    setToggleSkipForNowTransitionPopUp(true);
    setIntervalFunction();
    return;
  }
  useTitle("Zoop Customer Platform | Generate API Key");
  return (
    <div>
      <div
        className="container-fluid p-0"
        style={
          toggleSkipForNowTransitionPopUp
            ? { filter: "blur(5px)" }
            : { filter: "blur(0)" }
        }
      >
        {toggleApiKeyPopUp && (
          <AddAppKey
            onCancel={() => {
              setToggleApiKeyPopUp(false);
              history.push(`/application/manage-apps/${test_app_id}`);
            }}
            apiKey={apiKey}
          />
        )}
        {toast.show && (
          <Toast
            message={toast.message}
            type={toast.type}
            click={() =>
              setToast((toast: any) => ({
                ...toast,
                show: false,
              }))
            }
          />
        )}
        <div className="row no-gutters">
          <div className="col-xl-5 d-none d-xl-block">
            <AuthSidebar
              img={
                <img src={ClipEducation} alt="ClipEducation" width="350px" />
              }
              info={
                <InformativeText
                  headertext="Generate your API key"
                  bodytext="You can also generate the API key later from the Manage Apps
                  section."
                />
              }
            />
          </div>
          <div className="col-xl-7 col-lg-12">
            <div className={`${Styles.authActionsBackground}`}>
              <div
                style={{ height: "15%" }}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="py-3">
                  <p className={`${Styles.headingText} text-center mb-0`}>
                    We have created an application for you
                  </p>
                </div>
              </div>
              <div style={{ height: "70%" }}>
                <div
                  className="container px-0 py-3"
                  style={{ minHeight: "450px", overflow: "auto" }}
                >
                  {approvalNeeded.length !== 0 &&
                  subscribedService.length !== 0 ? (
                    <div className="row no-gutters">
                      <div
                        className="col-6"
                        style={{
                          borderRight: "0.2px solid #dfdfdf",
                          height: "450px",
                        }}
                      >
                        <p
                          className={`mb-0 text-center ${Styles.subscribeLabelText}`}
                        >
                          Subscribed Products
                        </p>
                        <div className="py-3">
                          {subscribedService.map((service: any) => {
                            return (
                              <div
                                className={`${Styles.serviceLevelSettings} py-2 px-3 d-flex align-items-center`}
                                key={service._id}
                                style={{ width: "80%" }}
                              >
                                <div className="px-1">
                                  <p className={`mb-0 ${Styles.serviceName}`}>
                                    {service.name}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-6">
                        <p
                          className={`mb-0 text-center ${Styles.waitingForApprovalLabelText}`}
                        >
                          Waiting for approval
                        </p>
                        <div className="py-3">
                          {approvalNeeded.map((service: any) => {
                            return (
                              <div
                                className={`${Styles.serviceLevelSettings} py-2 px-3 d-flex align-items-center`}
                                key={service._id}
                                style={{ width: "80%" }}
                              >
                                <div className="px-1">
                                  <p className={`mb-0 ${Styles.serviceName}`}>
                                    {service.name}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : subscribedService.length !== 0 ? (
                    <div className="row no-gutters">
                      <div className="col-12">
                        <p
                          className={`mb-0 text-center ${Styles.subscribeLabelText}`}
                        >
                          Subscribed Products
                        </p>
                        <div className="py-3">
                          {subscribedService.map((service: any) => {
                            return (
                              <div
                                className={`${Styles.serviceLevelSettings} py-2 px-3 d-flex align-items-center`}
                                key={service._id}
                                style={{ width: "50%" }}
                              >
                                <div className="px-1">
                                  <p className={`mb-0 ${Styles.serviceName}`}>
                                    {service.name}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : approvalNeeded.length !== 0 ? (
                    <div className="row no-gutters">
                      <div className="col-12">
                        <p
                          className={`mb-0 text-center ${Styles.waitingForApprovalLabelText}`}
                        >
                          Waiting for approval
                        </p>
                        <div className="py-3">
                          {approvalNeeded.map((service: any) => {
                            return (
                              <div
                                className={`${Styles.serviceLevelSettings} py-2 px-3 d-flex align-items-center`}
                                key={service._id}
                                style={{ width: "50%" }}
                              >
                                <div className="px-1">
                                  <p className={`mb-0 ${Styles.serviceName}`}>
                                    {service.name}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                style={{ height: "15%" }}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="py-3">
                  {subscribedService.length === 0 ? (
                    <div style={{ margin: "0 auto" }}>
                      <Button
                        size={LARGE}
                        hoveredStyle={primaryButtonHoverStyle}
                        onClick={handleGoToChecklist}
                        style={primaryButtonStyle}
                      >
                        Go to Dashboard
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <div style={{ margin: "0 auto" }}>
                        <Button
                          size={LARGE}
                          isLoading={generateApiKeyLoading}
                          hoveredStyle={primaryButtonHoverStyle}
                          onClick={goToManageAppsHandler}
                          style={primaryButtonStyle}
                        >
                          Generate Key
                        </Button>
                      </div>
                      <div className="py-2">
                        <div
                          className={`${Styles.blockButton}`}
                          onClick={handleGoToChecklist}
                        >
                          <p className={`${Styles.linkText} mb-0`}>
                            Skip for now
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggleSkipForNowTransitionPopUp ? (
        <TransitionPopUp
          onCancel={() => setToggleSkipForNowTransitionPopUp(false)}
          headingText="Welcome! Your account setup is complete!"
        >
          <p className={`mb-0 ${Styles.stepText}`}>{loaderMessage}</p>
        </TransitionPopUp>
      ) : null}
    </div>
  );
}

export default AppCreationSuccess;
