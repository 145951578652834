import React, { useEffect } from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
  primaryButtonStyleOkyc,
  primaryButtonHoverStyleOkyc,
} from "../../../../../Shared/buttonStyles";
import { COMPRESSED, MEDIUM } from "../../../../../Shared/buttonSize";
import {
  validAadhaarNumber,
  validNameCheck,
  validOtpNumber,
} from "../../../../../utils/validation";

function OkycServicePopup({ handleResponse, name, getOtp }: any) {
  const errorObject = {
    aadharNumberError: false,
    fetchError: false,
    otpError: false,
    consentError: false,
    nameToMatchError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isOtpTriggered, setIsOtpTriggered] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [loadingForOtp, setLoadingForOtp] = React.useState(false);
  const [isOtpSent, setIsOtpSent] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Send Otp");
  const aadharNumberRef = React.useRef<any>("");
  const otpRef = React.useRef<any>("");
  const nameMatchRef = React.useRef<any>("");
  const [isNameMatchEnabled, setIsNameMatchEnabled] = React.useState(false);

  useEffect(() => {
    if (isNameMatchEnabled) {
      setIsDisabled(true);
    }
    if (!isNameMatchEnabled) {
      if (
        aadharNumberRef.current.value &&
        otpRef &&
        otpRef.current &&
        otpRef.current.value
      ) {
        setIsDisabled(false);
      }
    }
  }, [isNameMatchEnabled]);

  async function checkAadharNumber() {
    try {
      const aadharNumber = aadharNumberRef.current.value;
      if (!validAadhaarNumber(aadharNumber)) {
        setError(() => ({
          ...error,
          aadharNumberError: true,
        }));
        resetError();
        return false;
      }
      setLoadingForOtp(true);
      const res = await getOtp(aadharNumber, setLoadingForOtp);
      if (res.result.is_otp_sent) {
        setIsOtpTriggered(true);
        setIsOtpSent(true);
        setButtonText("Resend Otp");
        setTimeout(() => setIsOtpSent(false), 30000);
      }
    } catch (e) {
      setError(() => ({
        ...error,
        fetchError: true,
      }));
      resetError();
    }
  }

  function isValid() {
    if (
      validAadhaarNumber(aadharNumberRef.current.value.trim()) &&
      otpRef &&
      otpRef.current &&
      validOtpNumber(otpRef.current.value.trim())
    )
      return setIsDisabled(false);
    return setIsDisabled(true);
  }

  function isValidNameMatch() {
    setError({ ...errorObject });
    if (
      validAadhaarNumber(aadharNumberRef.current.value.trim()) &&
      validNameCheck(nameMatchRef.current.value) &&
      otpRef &&
      otpRef.current &&
      validOtpNumber(otpRef.current.value.trim())
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }

    if (!validNameCheck(nameMatchRef.current.value)) {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
    }

    return setIsDisabled(true);
  }

  function resetError() {
    setTimeout(() => setError({ ...errorObject }), 3000);
  }

  function setOtpStatus() {
    if (isOtpTriggered) setIsOtpTriggered(false);
    setIsDisabled(true);
  }

  function handleCheck() {
    const otp = otpRef.current.value;

    if (isNameMatchEnabled && nameMatchRef.current.value === "") {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
      return setIsDisabled(true);
    }

    if (!validOtpNumber(otp)) {
      setError(() => ({
        ...error,
        otpError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    const consent = isChecked === true ? "Y" : "N";
    setLoading(true);

    if (nameMatchRef && nameMatchRef.current && nameMatchRef.current.value) {
      const nameToMatch = nameMatchRef.current.value;
      handleResponse({ otp, nameToMatch, consent }, setLoading);
    } else if (!isNameMatchEnabled) {
      handleResponse({ otp, consent }, setLoading);
    }
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <label className={`${Styles.label} flex-grow-1`}>
                  AADHAAR Number
                </label>
                <div className="d-flex">
                  <div className={Styles.inputContainer}>
                    <InputField
                      id="id-input-aadhaar-number"
                      maxLength={12}
                      onChange={setOtpStatus}
                      inputRef={aadharNumberRef}
                      isError={error.aadharNumberError}
                      purpose="forOkyc"
                    />
                  </div>
                  <div className={Styles.buttonContainer}>
                    <Button
                      id="id-input-submit-aadhaar-number"
                      hoveredStyle={primaryButtonHoverStyleOkyc}
                      disabled={isOtpSent}
                      size={COMPRESSED}
                      isLoading={loadingForOtp}
                      style={primaryButtonStyleOkyc}
                      onClick={() => {
                        checkAadharNumber();
                      }}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </div>
                {error.fetchError && (
                  <div>
                    <ErrorMessage>
                      Could not fetch info, please try later.
                    </ErrorMessage>
                  </div>
                )}

                {error.aadharNumberError && (
                  <ErrorMessage>
                    Please enter a valid AADHAAR number
                  </ErrorMessage>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mb-4 ">
            {isOtpTriggered && (
              <div className="d-flex flex-column">
                <label className={`${Styles.label} flex-grow-1`}>OTP</label>
                <InputField
                  id="id-input-otp"
                  onChange={isValid}
                  maxLength={6}
                  inputRef={otpRef}
                  isError={error.otpError}
                  purpose="forOtp"
                />
                {error.otpError && (
                  <ErrorMessage>Please enter a valid OTP</ErrorMessage>
                )}
              </div>
            )}
          </div>
          {isOtpTriggered && (
            <div className="pl-4 pb-2 pt-4 d-flex flex-column">
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={(e: any) => setIsNameMatchEnabled(e.target.checked)}
                />
                <p className={`${Styles.ackText}`}>is name match required ??</p>
              </div>
            </div>
          )}
          {isNameMatchEnabled && (
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                Name To Match
              </label>
              <InputField
                onChange={isValidNameMatch}
                inputRef={nameMatchRef}
                isError={error.nameToMatchError}
                purpose="forPassport"
                text="lowerCase"
              />
              {error.nameToMatchError && (
                <ErrorMessage>Please enter a valid Name</ErrorMessage>
              )}
            </div>
          )}
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isNameMatchEnabled ? isValidNameMatch() : isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-okyc"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OkycServicePopup;
