import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import Button from "../../../../../Shared/button/button";
import { primaryButtonStylePopup } from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";

function FaceCropOrMatch({ handleResponse, name }: any) {
  const serviceName = name.replaceAll(" ", "").toLowerCase();
  const errorObject = {
    cardError: false,
    userImageError: false,
    consentError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [uploadedCard, setUploadedCard] = React.useState<any>("");
  const [uploadedUserImage, setUploadedUserImage] = React.useState<any>("");

  const handleFileUpload = async (
    card: any,
    userImage: any,
    consent: string
  ) => {
    const cardEncoded: any = await toBase64(card);
    const finalCardImage = cardEncoded.replaceAll(
      "data:image/jpeg;base64,",
      ""
    );
    setLoading(true);
    if (serviceName === "facecrop") {
      return handleResponse({ finalCardImage, consent }, setLoading);
    }

    const userImageEncoded: any = await toBase64(userImage);
    const finalUserImage = userImageEncoded.replaceAll(
      "data:image/jpeg;base64,",
      ""
    );
    handleResponse({ finalCardImage, finalUserImage, consent }, setLoading);
  };

  function toBase64(image: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  function handleCheck() {
    if (!uploadedCard) {
      setError(() => ({
        ...error,
        cardError: true,
      }));
      return false;
    } else if (serviceName === "facematch" && !uploadedUserImage) {
      setError(() => ({
        ...error,
        userImageError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    const consent = isChecked === true ? "Y" : "N";

    handleFileUpload(uploadedCard, uploadedUserImage, consent);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>Card Image</label>
            <input
              type="file"
              id="id-input-face-crop"
              onChange={(e: any) => setUploadedCard(e.target.files[0])}
              className={Styles.fileUpload}
            />
            {error.cardError && (
              <ErrorMessage>Please upload a file</ErrorMessage>
            )}
          </div>{" "}
          {serviceName === "facematch" && (
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                User Image
              </label>
              <input
                type="file"
                id="id-input-face-crop-back"
                onChange={(e: any) => setUploadedUserImage(e.target.files[0])}
                className={Styles.fileUpload}
              />
              {error.userImageError && (
                <ErrorMessage>Please upload a file</ErrorMessage>
              )}
            </div>
          )}
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-face-crop"
              hoveredStyle={primaryButtonStylePopup}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceCropOrMatch;
