export function numberWithCommas(value: any) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function roundOfDecimal(value: number) {
  return Math.round((Number(value) + Number.EPSILON) * 100) / 100;
}

export function roundDecimalWithComma(value: number) {
  return Number(value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
}

export function addGST(value: number) {
  return roundOfDecimal(value + 0.18 * value);
}
