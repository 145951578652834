import React from "react";

const PauseSvg = ({ fillBackgroundColor, InnerColor, border }: any) => (
  <svg
    width="70"
    height="35"
    viewBox="0 0 64 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.5"
      width="64"
      height="40"
      rx="6"
      fill={fillBackgroundColor}
      stroke={border}
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_1561_1032)">
      <path
        d="M38.773 20.5C38.773 24.2405 35.7407 27.2727 32.0003 27.2727C28.2598 27.2727 25.2275 24.2405 25.2275 20.5C25.2275 16.7595 28.2598 13.7273 32.0003 13.7273C35.7407 13.7273 38.773 16.7595 38.773 20.5Z"
        stroke={InnerColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8184 18.3181H34.182V22.6818H29.8184V18.3181Z"
        stroke={InnerColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <rect
        width="16"
        height="16"
        fill="white"
        transform="translate(24 12.5)"
      />
    </defs>
  </svg>
);

export default PauseSvg;
