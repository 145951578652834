import React from "react";

function ArrowDownSVG() {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM6 6L5.29289 6.70711C5.68342 7.09763 6.31658 7.09763 6.70711 6.70711L6 6ZM11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 1.70711L5.29289 6.70711L6.70711 5.29289L1.70711 0.292893L0.292893 1.70711ZM6.70711 6.70711L11.7071 1.70711L10.2929 0.292893L5.29289 5.29289L6.70711 6.70711Z"
        fill="black"
      />
    </svg>
  );
}

export default ArrowDownSVG;
