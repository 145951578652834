import React from "react";
import { business_info } from "../constants/onboardingConstants";

export const onboardingSidebarContext = React.createContext({
  orgCreated: false,
  next_step: "",
  step_completed: [""],
  appCreated: false,
  createOrgProgress: 0,
  selectServiceProgress: 0,
  setOrgCreated: (value: any) => {},
  setNextStep: (value: any) => {},
  setStepCompleted: (value: any) => {},
  setAppCreated: (value: any) => {},
  setCreateOrgProgress: (value: any) => {},
  setSelectServiceProgress: (value: any) => {},
});

const OnBoardingSidebarContextProvider = (props: any) => {
  const [isOrgCreated, setIsOrgCreated] = React.useState(false);
  const [isAppCreated, setIsAppCreated] = React.useState(false);
  const [next_step, setNextStep] = React.useState(business_info);
  const [step_completed, setStepCompleted] = React.useState([]);
  const [createOrgProgress, setCreateOrgProgress] = React.useState(10);
  const [selectServiceProgress, setSelectServiceProgress] = React.useState(0);
  return (
    <onboardingSidebarContext.Provider
      value={{
        orgCreated: isOrgCreated,
        next_step: next_step,
        step_completed: step_completed,
        appCreated: isAppCreated,
        createOrgProgress: createOrgProgress,
        selectServiceProgress: selectServiceProgress,
        setAppCreated: setIsAppCreated,
        setNextStep: setNextStep,
        setOrgCreated: setIsOrgCreated,
        setCreateOrgProgress: setCreateOrgProgress,
        setStepCompleted: setStepCompleted,
        setSelectServiceProgress: setSelectServiceProgress,
      }}
    >
      {props.children}
    </onboardingSidebarContext.Provider>
  );
};

export default OnBoardingSidebarContextProvider;
