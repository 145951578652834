import React from "react";

function productsSVGNew(classes: any) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path d="M10.4167 3.125V21.875C10.4167 22.7038 10.0874 23.4987 9.50138 24.0847C8.91532 24.6708 8.12047 25 7.29167 25H3.125C2.2962 25 1.50134 24.6708 0.915291 24.0847C0.32924 23.4987 0 22.7038 0 21.875V3.125C0 2.2962 0.32924 1.50134 0.915291 0.915291C1.50134 0.32924 2.2962 0 3.125 0H7.29167C8.12047 0 8.91532 0.32924 9.50138 0.915291C10.0874 1.50134 10.4167 2.2962 10.4167 3.125ZM21.875 16.3542H15.625C14.7962 16.3542 14.0013 16.6834 13.4153 17.2695C12.8292 17.8555 12.5 18.6504 12.5 19.4792V21.875C12.5 22.7038 12.8292 23.4987 13.4153 24.0847C14.0013 24.6708 14.7962 25 15.625 25H21.875C22.7038 25 23.4987 24.6708 24.0847 24.0847C24.6708 23.4987 25 22.7038 25 21.875V19.4792C25 18.6504 24.6708 17.8555 24.0847 17.2695C23.4987 16.6834 22.7038 16.3542 21.875 16.3542ZM21.875 0H15.625C14.7962 0 14.0013 0.32924 13.4153 0.915291C12.8292 1.50134 12.5 2.2962 12.5 3.125V11.1458C12.5 11.9746 12.8292 12.7695 13.4153 13.3555C14.0013 13.9416 14.7962 14.2708 15.625 14.2708H21.875C22.7038 14.2708 23.4987 13.9416 24.0847 13.3555C24.6708 12.7695 25 11.9746 25 11.1458V3.125C25 2.2962 24.6708 1.50134 24.0847 0.915291C23.4987 0.32924 22.7038 0 21.875 0Z" />
    </svg>
  );
}

export default productsSVGNew;
