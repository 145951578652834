import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import Select from "react-select";
import { cardTypes } from "../../../../../constants/data";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import Button from "../../../../../Shared/button/button";
import { primaryButtonStylePopup } from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";

function OcrServicePopup({ handleResponse, name }: any) {
  const errorObject = {
    frontSideError: false,
    backSideError: false,
    cardTypeError: false,
    consentError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [cardType, setCardType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [uploadedFileFront, setUploadedFileFront] = React.useState<any>("");
  const [uploadedFileBack, setUploadedFileBack] = React.useState<any>("");
  const [aadhaarMaskedStatus, setAadhaarMaskedStatus] = React.useState<any>(
    false
  );
  const handleFileUpload = async (
    frontSide: any,
    backSide: any,
    cardType: any,
    consent: string
  ) => {
    const frontSideEncoded: any = await toBase64(frontSide);
    const backSideEncoded: any = backSide ? await toBase64(backSide) : "";

    const char = ",";
    const frontIndex = frontSideEncoded.indexOf(char);
    const finalFront =
      frontIndex !== -1 ? frontSideEncoded.substring(frontIndex + 1) : "";
    const backIndex = backSide ? backSideEncoded.indexOf(char) : -1;
    const finalBack =
      backIndex !== -1 ? backSideEncoded.substring(backIndex + 1) : "";

    const aadhaar_masked_image = aadhaarMaskedStatus;

    handleResponse(
      { finalFront, finalBack, cardType, consent, aadhaar_masked_image },
      setLoading
    );
  };

  const handleChange = (event: any) => {
    setAadhaarMaskedStatus(event.target.value === "true");
  };

  function toBase64(image: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  function handleCheck() {
    if (!uploadedFileFront) {
      setError(() => ({
        ...error,
        frontSideError: true,
      }));
      return false;
    } else if (!cardType) {
      setError(() => ({
        ...error,
        cardTypeError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    const consent = isChecked === true ? "Y" : "N";
    handleFileUpload(uploadedFileFront, uploadedFileBack, cardType, consent);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>Front side</label>
            <input
              id="id-input-file-ocr"
              type="file"
              accept=".png,.jpeg,.jpg"
              onChange={(e: any) => setUploadedFileFront(e.target.files[0])}
              className={Styles.fileUpload}
            />
            {error.frontSideError && (
              <ErrorMessage>Please upload a file</ErrorMessage>
            )}
          </div>
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>Back side</label>
            <input
              id="id-input-file-ocr-back"
              type="file"
              accept=".png,.jpeg,.jpg"
              onChange={(e: any) => setUploadedFileBack(e.target.files[0])}
              className={Styles.fileUpload}
            />
            <p className={`${Styles.labelLite} mt-2 flex-grow-1`}>
              ** Only for passport, AADHAAR & Voter ID, back side is mandatory.
            </p>
          </div>
          <div>
            <label className={`${Styles.label}`}>Card Type</label>
            <Select
              id="id-input-card-type"
              onChange={(e: any) => setCardType(e.value)}
              placeholder=""
              options={cardTypes}
              styles={
                !error.cardTypeError
                  ? dropDownStyles.customStylesForCountry
                  : dropDownStyles.customStylesForCountryError
              }
            />
            {error.cardTypeError && (
              <ErrorMessage>Please select type!</ErrorMessage>
            )}
          </div>

          {cardType === "AADHAAR_MASKED" && (
            <>
              <label className={`${Styles.label} flex-grow-1 mt-4`}>
                is Aadhaar masked image required ??
              </label>
              <div className="d-flex mt-1">
                <label className="mr-2 mb-0">
                  <input
                    type="radio"
                    value="true"
                    checked={aadhaarMaskedStatus === true}
                    onChange={handleChange}
                    className="mr-1"
                  />
                  True
                </label>

                <label className="mb-0">
                  <input
                    type="radio"
                    value="false"
                    checked={aadhaarMaskedStatus === false}
                    onChange={handleChange}
                    className="mr-1"
                  />
                  False
                </label>
              </div>
            </>
          )}
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-ocr"
              hoveredStyle={primaryButtonStylePopup}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OcrServicePopup;
