// Child component of selectService
import React from "react";
import Styles from "./serviceCard.module.scss";
import TickSvg from "../../../../Shared/svg/tickSvg";
import { WHITE } from "../../../../Shared/colors";

function ServiceCard({ serviceDetails, handleCheckedItem }: any) {
  const [check, setCheck] = React.useState(false);
  const checkboxEvent = () => {
    handleCheckedItem(!check, serviceDetails);
    setCheck(!check);
  };
  const checkMark = (
    <div style={{ position: "absolute", top: "-4px", left: "4px" }}>
      <TickSvg width="13" height="13" color={WHITE} />
    </div>
  );
  return (
    <div
      className={`${Styles.serviceCardWrapper} d-flex align-items-center`}
      onClick={checkboxEvent}
    >
      <div style={{ width: "11%" }}>
        <div className={`${check ? Styles.stepCompleted : Styles.unVisited}`}>
          {check && checkMark}
        </div>
      </div>
      <div style={{ width: "89%" }}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <p className={`mb-0 ${Styles.serviceName}`}>
              {serviceDetails.name}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <div className="px-1">
              <p className={`mb-0 ${Styles.limit}`}>Testing Limit:</p>
            </div>
            <div className="px-1">
              <p className={` mb-0 ${Styles.limitValue}`}>
                {serviceDetails.default_free_transactions}
              </p>
            </div>
          </div>
        </div>
        <div className="py-2">
          <p className={`mb-0 ${Styles.DescText}`}>
            {serviceDetails.short_description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
