// Route connecting all the components after signUp or login having sidebar
//  for navigation.
import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import OnBoardingSideBar from "../../Shared/on-boarding-side-bar/onBoardingSideBar";
import CreateOrganization from "./create-organization/createOrganization";
import SelectService from "./select-service/selectService";

function Onboarding() {
  const location = useLocation();
  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-xl-5 d-none d-xl-block">
          <OnBoardingSideBar />
        </div>
        <div className="col-xl-7 col-lg-12">
          <Switch location={location}>
            <Route
              path={"/onboarding/create-org"}
              component={CreateOrganization}
            />
            <Route
              path={"/onboarding/select-service"}
              component={SelectService}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
