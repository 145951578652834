//KYC details form
import React from "react";
import Styles from "./activationForm.module.scss";
import Toast from "../../../Shared/toast/toast";
import Button from "../../../Shared/button/button";
import { useHistory } from "react-router-dom";
import { checkIsEmpty, isValidName } from "../../../utils/validation";
import Input from "../../../Shared/input/input";
import Close from "../../../Shared/svg/close";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import {
  primaryButtonStyle,
  cancelButtonStyle,
  cancelButtonHoverStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { MEDIUM } from "../../../Shared/buttonSize";
import InputDropdown from "../../../Shared/input-dropdown/inputDropdown";
import TopBar from "../../../Shared/top-bar/topBarNew";

function Activation() {
  const history = useHistory();
  const [gstinFileName, setGstinFileName] = React.useState("");
  const [documentName, setDocumentName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [bankAccountNumber, setBankAccountNumber] = React.useState("");
  const [gstinNumber, setGstinNumber] = React.useState("");
  const [ifsc, setIfsc] = React.useState("");
  const [authorName, setAuthorName] = React.useState("");
  const [registration, setRegistration] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [error, setError] = React.useState({
    bankAccountNumberError: "",
    gstinNumberError: "",
    ifscError: "",
    gstinCertificateError: "",
    registrationError: "",
    authorNameError: "",
    designationError: "",
    documentError: "",
  });
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const registrationCategory = ["CIN", "Udhyog Aadhaar"];
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
  }
  // Validation functions for various input fields
  function checkBankAccountNumber() {
    if (checkIsEmpty(bankAccountNumber)) {
      setError((error) => ({
        ...error,
        bankAccountNumberError: "Field Cannot be Empty",
      }));
      return false;
    }
    return true;
  }
  function checkGstinNumber() {
    if (checkIsEmpty(gstinNumber)) {
      setError((error) => ({
        ...error,
        gstinNumberError: "Field Cannot be Empty",
      }));
      return false;
    }
    return true;
  }
  function checkIfsc() {
    if (checkIsEmpty(ifsc)) {
      setError((error) => ({
        ...error,
        ifscError: "Field Cannot be Empty",
      }));
      return false;
    }
    return true;
  }
  function checkGstinUploaded() {
    if (checkIsEmpty(gstinFileName)) {
      setError((error) => ({
        ...error,
        gstinCertificateError: "Please upload the file!",
      }));
      return false;
    }
    return true;
  }
  function checkAuthorName() {
    if (checkIsEmpty(authorName)) {
      setError((error) => ({
        ...error,
        authorNameError: "Field Cannot be Empty",
      }));
      return false;
    }
    if (isValidName(authorName, false)) {
      setError((error) => ({
        ...error,
        authorNameError: "should contain alphabets only!",
      }));
      return false;
    }
    return true;
  }
  function checkRegistration() {
    if (checkIsEmpty(registration)) {
      setError((error) => ({
        ...error,
        registrationError: "Please Select an option",
      }));
      return false;
    }
    return true;
  }
  function checkDesignation() {
    if (checkIsEmpty(designation)) {
      setError((error) => ({
        ...error,
        designationError: "Field Cannot be Empty",
      }));
      return false;
    }
    if (isValidName(designation, false)) {
      setError((error) => ({
        ...error,
        designationError: "should contain alphabets only!",
      }));
      return false;
    }
    return true;
  }
  function checkDocumentUploaded() {
    if (checkIsEmpty(documentName)) {
      setError((error) => ({
        ...error,
        documentError: "Please upload the file!",
      }));
      return false;
    }
    return true;
  }
  // On Submission of activation form
  function handleActivation() {
    const allCheckPassed = [
      checkBankAccountNumber(),
      checkGstinNumber(),
      checkIfsc(),
      checkGstinUploaded(),
      checkAuthorName(),
      checkRegistration(),
      checkDesignation(),
      checkDocumentUploaded(),
    ].every(Boolean);
    if (!allCheckPassed) {
      toastTimeout(true, "error", "Please fill all the input box to continue");
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      const inputsObj = {
        bankAccountNumber: bankAccountNumber,
        gstinNumber: gstinNumber,
        ifsc: ifsc,
        gstinFileName: gstinFileName,
        authorName: authorName,
        registration: registration,
        designation: designation,
        documentName: documentName,
      };
      console.log(inputsObj);
      history.push("/application");
    }, 3000);
    return;
  }
  // On uploading file applying boundary conditions
  function uploadFile(file: any, ref: any) {
    if (file.name.includes("pdf")) {
      if (file.size < 2000000) {
        if (ref === "gstin") setGstinFileName(file.name);
        else setDocumentName(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
      } else {
        toastTimeout(true, "error", "File should be less than 2 MB");
        return;
      }
    } else {
      toastTimeout(true, "error", "File should be in pdf format");
      return;
    }
  }

  return (
    <div>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className={`${Styles.background}`}>
        <TopBar />
        <div className="container-fluid p-3">
          <div className="d-flex align-items-center p-3">
            <div className="px-2">
              <p className={`mb-0 ${Styles.overallheadingText}`}>KYC Details</p>
            </div>
            <div className="ml-auto px-2">
              <Button
                hoveredStyle={cancelButtonHoverStyle}
                style={cancelButtonStyle}
                onClick={() => history.push("/application")}
                size={MEDIUM}
                id="z-cancel-activation-form"
              >
                Cancel
              </Button>
            </div>
            <div className="px-2">
              <Button
                hoveredStyle={primaryButtonHoverStyle}
                style={primaryButtonStyle}
                isLoading={loading}
                onClick={handleActivation}
                size={MEDIUM}
                id="z-submit-activation-form"
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="container-fluid p-3" style={{ width: "80%" }}>
            <div className={`${Styles.ActivationCard} ${Styles.cardAnimation}`}>
              <div
                className={`p-3 d-flex align-items-center ${Styles.cardHeader}`}
              >
                <p className={`mb-0 px-2 ${Styles.headingText}`}>
                  Account Activation Form
                </p>
              </div>
              <div className="p-3">
                <div className="pb-3">
                  <div className="container-fluid p-0">
                    <div className="row no-gutters p-2">
                      <div className="col-md-6">
                        <div className="px-2">
                          <div
                            className={"mb-0"}
                            style={{
                              position: "relative",
                              width: "60%",
                            }}
                          >
                            <Input
                              type="text"
                              name="accountNumber"
                              id="accountNumber"
                              required
                              requiredAstrict
                              autoComplete="off"
                              placeholder="Enter Bank Account Number"
                              hasError={error.bankAccountNumberError}
                              labelname="Bank Account Number"
                              onChange={(event: any) => {
                                setBankAccountNumber(event.target.value);
                                setError((error) => ({
                                  ...error,
                                  bankAccountNumberError: "",
                                }));
                              }}
                              onBlur={checkBankAccountNumber}
                            />
                            {error.bankAccountNumberError ? (
                              <ErrorMessage>
                                {error.bankAccountNumberError}
                              </ErrorMessage>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="px-2">
                          <div
                            className="mb-0"
                            style={{
                              position: "relative",
                              width: "60%",
                            }}
                          >
                            <Input
                              type="text"
                              name="gstinNumber"
                              id="gstinNumber"
                              required
                              requiredAstrict
                              autoComplete="off"
                              placeholder="Enter GSTIN Number"
                              hasError={error.gstinNumberError}
                              labelname="GSTIN Number"
                              onChange={(event: any) => {
                                setGstinNumber(event.target.value);
                                setError((error) => ({
                                  ...error,
                                  gstinNumberError: "",
                                }));
                              }}
                              onBlur={checkGstinNumber}
                            />
                            {error.gstinNumberError ? (
                              <ErrorMessage>
                                {error.gstinNumberError}
                              </ErrorMessage>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0">
                    <div className="row no-gutters p-2">
                      <div className="col-md-6">
                        <div className="px-2">
                          <div
                            className="mb-0"
                            style={{
                              position: "relative",
                              width: "60%",
                            }}
                          >
                            <Input
                              type="text"
                              name="ifsc"
                              id="ifsc"
                              required
                              requiredAstrict
                              autoComplete="off"
                              placeholder="Enter IFSC Code"
                              hasError={error.ifscError}
                              labelname="IFSC"
                              onChange={(event: any) => {
                                setIfsc(event.target.value);
                                setError((error) => ({
                                  ...error,
                                  ifscError: "",
                                }));
                              }}
                              onBlur={checkIfsc}
                            />
                            {error.ifscError ? (
                              <ErrorMessage>{error.ifscError}</ErrorMessage>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="p-2">
                          <p
                            className={`${Styles.uploadLabel} ${Styles.required} mb-0`}
                          >
                            Upload GSTIN Certificate
                          </p>
                          {gstinFileName ? (
                            <div className="d-flex align-items-center">
                              <p className={`${Styles.fileURL} mb-0`}>
                                {gstinFileName}
                              </p>
                              <div
                                className={`${Styles.closeSvg} px-2 `}
                                onClick={() => {
                                  setGstinFileName("");
                                }}
                              >
                                <Close width="8" height="8" />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <p className={`mb-0 ${Styles.descText}`}>
                                Supports pdf upto 2MB
                              </p>
                              <label
                                htmlFor="uploadGstInCertificate"
                                className={`pt-2 mb-0 ${Styles.upload}`}
                              >
                                <p className="mb-0"> Upload File</p>
                                <input
                                  id="uploadGstInCertificate"
                                  type="file"
                                  accept=".pdf"
                                  className={Styles.fileType}
                                  onChange={(e: any) => {
                                    uploadFile(e.target.files[0], "gstin");
                                    setError((error) => ({
                                      ...error,
                                      gstinCertificateError: "",
                                    }));
                                  }}
                                  onBlur={checkGstinUploaded}
                                />
                              </label>
                              {error.gstinCertificateError && (
                                <ErrorMessage>
                                  {error.gstinCertificateError}
                                </ErrorMessage>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3">
                  <div className="container-fluid p-0">
                    <div className="row no-gutters p-2">
                      <div className="col-md-6">
                        <div className="px-2">
                          <div
                            className="mb-0"
                            style={{
                              position: "relative",
                              width: "60%",
                            }}
                          >
                            <Input
                              type="text"
                              name="authorName"
                              id="authorName"
                              required
                              requiredAstrict
                              autoComplete="off"
                              placeholder="Enter Name"
                              hasError={error.authorNameError}
                              labelname="Authorised Signatory Name "
                              onChange={(event: any) => {
                                setAuthorName(event.target.value);
                                setError((error) => ({
                                  ...error,
                                  authorNameError: "",
                                }));
                              }}
                              onBlur={checkAuthorName}
                            />
                            {error.authorNameError ? (
                              <ErrorMessage>
                                {error.authorNameError}
                              </ErrorMessage>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="px-2  py-2">
                          <div
                            className="mb-0"
                            style={{
                              position: "relative",
                              width: "60%",
                            }}
                          >
                            <InputDropdown
                              labelname="Registration Document"
                              hasError={error.registrationError}
                              optionsArray={registrationCategory}
                              click={(value: any) => {
                                setRegistration(value);
                                setError((error) => ({
                                  ...error,
                                  registrationError: "",
                                }));
                              }}
                              id={"activation_registration_document"}
                            />
                            {error.registrationError ? (
                              <ErrorMessage>
                                {error.registrationError}
                              </ErrorMessage>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0">
                    <div className="row no-gutters p-2">
                      <div className="col-md-6">
                        <div className="px-2">
                          <div
                            className="mb-0"
                            style={{
                              position: "relative",
                              width: "60%",
                            }}
                          >
                            <Input
                              type="text"
                              name="authorDesignation"
                              id="authorDesignation"
                              required
                              requiredAstrict
                              autoComplete="off"
                              placeholder="Enter Designation"
                              hasError={error.designationError}
                              labelname="Authorised Signatory Designation"
                              onChange={(event: any) => {
                                setDesignation(event.target.value);
                                setError((error) => ({
                                  ...error,
                                  designationError: "",
                                }));
                              }}
                              onBlur={checkDesignation}
                            />
                            {error.designationError ? (
                              <ErrorMessage>
                                {error.designationError}
                              </ErrorMessage>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {registration ? (
                        <div className="col-md-6">
                          <div className="px-2  py-2">
                            <div
                              className={`${Styles.uploadLabel} ${Styles.required}`}
                            >
                              Upload {registration} Certificate
                            </div>
                            {documentName ? (
                              <div className="d-flex align-items-center">
                                <p className={`${Styles.fileURL} mb-0`}>
                                  {documentName}
                                </p>
                                <div
                                  className={`${Styles.closeSvg} px-2 `}
                                  onClick={() => {
                                    setDocumentName("");
                                  }}
                                >
                                  <Close width="8" height="8" />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p className={`${Styles.descText} mb-0`}>
                                  Supports pdf upto 2MB
                                </p>
                                <label
                                  htmlFor="uploadDocumentFile"
                                  className={`pt-2 mb-0 ${Styles.upload}`}
                                >
                                  <p className="mb-0">Upload File</p>
                                  <input
                                    id="z-uploadDocumentFile"
                                    type="file"
                                    accept=".pdf"
                                    className={Styles.fileType}
                                    onChange={(e: any) => {
                                      uploadFile(
                                        e.target.files[0],
                                        "registrationDocument"
                                      );
                                      setError((error) => ({
                                        ...error,
                                        documentError: "",
                                      }));
                                    }}
                                    onBlur={checkDocumentUploaded}
                                  />
                                </label>
                                {error.documentError && (
                                  <ErrorMessage>
                                    {error.documentError}
                                  </ErrorMessage>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activation;
