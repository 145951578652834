import Styles from "../current-plan-product-pricing-pop-up/currentPlanProductPricingPopUp.module.scss";
import React, { Fragment, useState } from "react";
import RupeeSVG from "../../../../Shared/svg/rupeeSVG";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../Shared/colors";
import Loading from "../../../../Shared/loading/loading";
import ArrowDown from "../../../../Shared/svg/arrowDown";

function ProductTable({ onCancel, pricing, Loding, subscribedProduct }: any) {
  const filterCurrentPlanPricing = pricing.filter((pricing: any) => {
    return (
      subscribedProduct.filter(
        (subscribedProducts: any) =>
          subscribedProducts["arn"] === pricing["productArn"]
      ).length > 0
    );
  });

  const [addOnsToggle, setAddOnsToggles] = useState({
    productName: "",
    addOn: [],
  });

  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  const emptyState = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Plan Details Found</h5>
    </div>
  );

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`}>
        <div className={`d-flex justify-content-end ${Styles.cancelButton}`}>
          <button className={Styles.cancelButton} onClick={onCancel}>
            X
          </button>
        </div>
        {Loding ? (
          loading
        ) : filterCurrentPlanPricing.length > 0 ? (
          <form style={{ height: "100%" }}>
            <p className={Styles.card_title_bold_text}> Products</p>
            <div style={{ overflowX: "auto" }}>
              <table style={{ width: "100%" }}>
                <thead className={Styles.tableHeaderBack}>
                  <tr>
                    <th>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>
                        Product Name
                      </p>
                    </th>
                    <th>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>
                        Price Per Transaction
                      </p>
                    </th>
                    <th>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>Addons</p>
                    </th>
                  </tr>
                </thead>
                <tbody className={Styles.tableWrapper}>
                  {filterCurrentPlanPricing
                    .sort((a: any, b: any) =>
                      a.productName > b.productName
                        ? 1
                        : b.productName > a.productName
                        ? -1
                        : 0
                    )
                    .map(
                      ({
                        productArn,
                        productName,
                        productPricing,
                        addons,
                      }: any) => {
                        const unitPrice =
                          typeof productPricing === "object"
                            ? productPricing.unit_price
                            : productPricing;
                        return (
                          <Fragment key={productArn}>
                            <tr key={productArn}>
                              <td className={Styles.tableBody}>
                                {productName}
                              </td>
                              <td className={Styles.tableBodyforPrice}>
                                <div className="d-flex align-items-center">
                                  <div className="pr-1">
                                    <RupeeSVG
                                      color={PRIMARYCOLOR}
                                      width="7"
                                      height="14"
                                    />
                                  </div>
                                  {unitPrice}
                                </div>
                              </td>
                              <td className={Styles.tableBody}>
                                <div
                                  className="d-flex align-items-center"
                                  onClick={() => {
                                    if (
                                      addOnsToggle.productName === productName
                                    ) {
                                      setAddOnsToggles({
                                        ...addOnsToggle,
                                        productName: "",
                                        addOn: [],
                                      });
                                    } else {
                                      setAddOnsToggles({
                                        ...addOnsToggle,
                                        productName: productName,
                                        addOn: addons,
                                      });
                                    }
                                  }}
                                  style={
                                    addons.length > 0
                                      ? { cursor: "pointer" }
                                      : {}
                                  }
                                >
                                  {/* <p className={`mb-0 ${Styles.tableBody}`}>
                                    View
                                  </p> */}
                                  <p className={`mb-3 ${Styles.tableBody}`}>
                                    View
                                  </p>
                                  {addons.length > 0 && (
                                    <div className="pl-2">
                                      {addOnsToggle.productName ===
                                      productName ? (
                                        <ArrowDown />
                                      ) : (
                                        <div
                                          className={
                                            Styles.rotateArrowRightLongDescription
                                          }
                                        >
                                          <ArrowDown />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                            {addOnsToggle.productName === productName &&
                              addons.length > 0 && (
                                <tr
                                  style={{
                                    borderBottom: "1px solid #eceeef",
                                  }}
                                >
                                  <td colSpan={3} className={Styles.tableBody}>
                                    <table
                                      style={{
                                        width: "90%",
                                        height: "100%",
                                        border: "1px solid #eceeef",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <thead
                                        className={Styles.tableHeaderBack}
                                        key={productArn}
                                      >
                                        <tr>
                                          <th className={Styles.product_name}>
                                            <div className="d-flex align-items-center">
                                              <p
                                                className={`mb-0 ${Styles.tableHeaderText}`}
                                              >
                                                Addon Name
                                              </p>
                                            </div>
                                          </th>
                                          <th className={Styles.least}>
                                            <div className="d-flex align-items-center">
                                              <p
                                                className={`mb-0 ${Styles.tableHeaderText}`}
                                              >
                                                Addon Price
                                              </p>
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {addOnsToggle.addOn.map(
                                          (addOn: any, index: number) => (
                                            <Fragment
                                              key={addOn.addon_key + index}
                                            >
                                              <tr
                                                key={addOn.addon_key + index}
                                                style={{
                                                  borderBottom:
                                                    "1pxpx solid #eceeef",
                                                }}
                                              >
                                                <td
                                                  className={Styles.tableBody}
                                                >
                                                  <p
                                                    className={`mb-0 ${Styles.tableBodyText}`}
                                                  >
                                                    {addOn.addon_name}
                                                  </p>
                                                </td>
                                                <td className={Styles.least}>
                                                  <div className="d-flex align-items-center">
                                                    <div className="pr-1">
                                                      <RupeeSVG
                                                        color={PRIMARYCOLOR}
                                                        width="7"
                                                        height="14"
                                                      />
                                                    </div>
                                                    {typeof productPricing[
                                                      addOn.addon_key
                                                    ] === "object"
                                                      ? productPricing[
                                                          String(
                                                            addOn.addon_key
                                                          )
                                                        ]?.unit_price
                                                      : productPricing[
                                                          addOn.addon_key
                                                        ]}
                                                    {addOn.price}
                                                  </div>
                                                </td>
                                              </tr>

                                              {addOn.sub_addons.length > 0 ? (
                                                <tr
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #eceeef",
                                                  }}
                                                >
                                                  <td
                                                    colSpan={3}
                                                    className={Styles.tableBody}
                                                  >
                                                    <table
                                                      style={{
                                                        height: "100%",
                                                        width: "90%",
                                                        border:
                                                          "1px solid #eceeef",
                                                        borderRadius: "5px",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      <thead
                                                        className={
                                                          Styles.tableHeaderBack
                                                        }
                                                      >
                                                        <tr>
                                                          <th
                                                            className={
                                                              Styles.product_name
                                                            }
                                                          >
                                                            <div className="d-flex align-items-center">
                                                              <p
                                                                className={`mb-0 ${Styles.tableHeaderText}`}
                                                              >
                                                                Sub Addon Name
                                                              </p>
                                                            </div>
                                                          </th>
                                                          <th
                                                            className={
                                                              Styles.least
                                                            }
                                                          >
                                                            <div className="d-flex align-items-center">
                                                              <p
                                                                className={`mb-0 ${Styles.tableHeaderText}`}
                                                              >
                                                                {" "}
                                                                Addon Price
                                                              </p>
                                                            </div>
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {addOn.sub_addons.map(
                                                          (
                                                            sub_addon: any,
                                                            index: number
                                                          ) => {
                                                            return (
                                                              <tr
                                                                key={
                                                                  sub_addon.sub_addon_key +
                                                                  index
                                                                }
                                                                style={{
                                                                  borderBottom:
                                                                    "1px solid #eceeef",
                                                                }}
                                                              >
                                                                <td
                                                                  className={
                                                                    Styles.product_name
                                                                  }
                                                                >
                                                                  <p
                                                                    className={`mb-0 ${Styles.tableBodyText}`}
                                                                  >
                                                                    {
                                                                      sub_addon.sub_addon_name
                                                                    }
                                                                  </p>
                                                                </td>
                                                                <td
                                                                  className={
                                                                    Styles.least
                                                                  }
                                                                >
                                                                  <>
                                                                    <div className="d-flex align-items-center">
                                                                      <div className="pr-1">
                                                                        <RupeeSVG
                                                                          color={
                                                                            PRIMARYCOLOR
                                                                          }
                                                                          width="7"
                                                                          height="14"
                                                                        />
                                                                      </div>
                                                                      {
                                                                        productPricing[
                                                                          String(
                                                                            addOn?.addon_key
                                                                          )
                                                                        ][
                                                                          sub_addon
                                                                            .sub_addon_key
                                                                        ]
                                                                      }
                                                                    </div>
                                                                  </>
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              ) : null}
                                            </Fragment>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                          </Fragment>
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
          </form>
        ) : (
          emptyState
        )}
      </div>
    </div>
  );
}

export default ProductTable;
