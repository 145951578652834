import { formatCurl } from "../common/constants";

interface Icurlconverter {
  toPython(request: string, requestType: string): string;
  toDart(request: string, requestType: string): string;
  toNode(request: string, requestType: string): string;
  toAnsible(request: string, requestType: string): string;
  toCSharp(request: string, requestType: string): string;
  toCFML(request: string, requestType: string): string;
  toElixir(request: string, requestType: string): string;
  toGo(request: string, requestType: string): string;
  toJava(request: string, requestType: string): string;
  toJavascript(request: string, requestType: string): string;
  toJSON(request: string, requestType: string): string;
  toMATLAB(request: string, requestType: string): string;
  toPHP(request: string, requestType: string): string;
  toR(request: string, requestType: string): string;
  toRuby(request: string, requestType: string): string;
  toRust(request: string, requestType: string): string;
  toStrest(request: string, requestType: string): string;
  getData(request: string, requestType: string): any;
}

class curlconverter implements Icurlconverter {
  getData(request: string, requestType: string): any {
    const resultData: any = [];
    request = formatCurl(request);
    const requestData = request.split(" ");
    const option = request.includes("--data-raw") ? "--data-raw" : "--data";
    const dataIndex = requestData.findIndex(
      (predicate: string) => predicate === option
    );
    const data = requestData[dataIndex + 1];
    const headers: any = {};
    requestData.forEach((item: string, index) => {
      if (item === "--header") {
        const [key, value] = requestData[index + 1].split(":");
        headers[key] = value;
      }
    });
    resultData.push(
      requestType === "test"
        ? requestData[2].replace("live.", "test.")
        : requestData[2],
      headers,
      data
    );
    return [...resultData];
  }

  toDart(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    import 'package:http/http.dart' as http;
      void main() async {
        var headers = ${JSON.stringify(requestData[1])};
        var request = http.Request('POST', Uri.parse(${requestData[0]}));
        request.body = json.encode(${requestData[2]});
        request.headers.addAll(headers);
        http.StreamedResponse response = await request.send();
        if (response.statusCode == 200) {
          print(await response.stream.bytesToString());
        }
        else {
          print(response.reasonPhrase);
        }
    }`;
    return convertedRequest;
  }

  toNode(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    var request = require('request');
    var options = {
      'method': 'POST',
      'url': ${requestData[0]},
      'headers': ${JSON.stringify(requestData[1])},
    };
    body: JSON.stringify(${requestData[2]})
    request(options, function (error, response) {
      if (error) throw new Error(error);
      console.log(response.body);
    })`;
    return convertedRequest;
  }

  toAnsible(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `-
      name: ${requestData[0]}
      uri:
        url: ${JSON.stringify(requestData[0])}
        method: POST
        body: ${requestData[2]}
        headers: ${JSON.stringify(requestData[1])}
      register: result`;
    return convertedRequest;
  }

  toCSharp(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    using System.Net.Http.Headers;
    var client = new HttpClient();

    var request = new HttpRequestMessage(HttpMethod.Post,${requestData[0]});
    ${Object.entries(requestData[1]).map(
      ([key, value]) => `request.Headers.Add(${key}, ${value});\n`
    )}
    var content = new StringContent(${requestData[2]});

    request.Content = content;
    var response = await client.SendAsync(request);

    response.EnsureSuccessStatusCode();
    Console.WriteLine(await response.Content.ReadAsStringAsync());`;
    return convertedRequest;
  }

  toCFML(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    httpService = new http();
    httpService.setUrl(${requestData[0]});
    httpService.setMethod("POST");
    ${Object.entries(requestData[1]).map(
      ([key, value]) =>
        `\n  \u00A0 \u00A0 httpService.addParam(type="header", name=${key}, value=${value})`
    )}
    httpService.addParam(type="body", value="{ mode: sync, data: { mobile_number: 916522XXXX, consent: Y, consent_text: Approve_the_values_here");

    result = httpService.send().getPrefix();
    writeDump(result);`;
    return convertedRequest;
  }

  toElixir(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    request = %HTTPoison.Request{
    method: :post,
    url: ${requestData[0]},
    options: [],
    headers: [
      ${Object.entries(requestData[1]).map(
        ([key, value]) => `{~s|${key}|, ~s|${value}|},);`
      )}
    ],
    params: [],
    body: ~s|${requestData[2]}|
  }

  response = HTTPoison.request(request)`;
    return convertedRequest;
  }

  toPython(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
  import requests
  import json
      url = ${requestData[0]}
      payload = json.dumps(${requestData[2]})
      headers = ${JSON.stringify(requestData[1])}
      response = requests.request("POST", url, headers=headers, data=payload)
      print(response.text)`;
    return convertedRequest;
  }

  toGo(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    package main
   import( 
    "fmt"
    "strings"
    "net/http"
    "io/ioutil"
    )
      func main() {
        url :=  ${requestData[0]}
        method := "POST"
      
        payload := strings.NewReader(${requestData[2]})

        client := &http.Client {
        }
        req, err := http.NewRequest(method, url, payload)
      
        if err != nil {
          fmt.Println(err)
          return
        }
        ${Object.entries(requestData[1]).map(
          ([key, value]) => `\n  \u00A0 \u00A0 req.Header.Add(${key}, ${value})`
        )}
        res, err := client.Do(req)
        if err != nil {
          fmt.Println(err)
          return
        }
        defer res.Body.Close()

        body, err := ioutil.ReadAll(res.Body)
        if err != nil {
          fmt.Println(err)
          return
        }
        fmt.Println(string(body))
      }`;
    return convertedRequest;
  }

  toJava(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
      import java.io.IOException;
      import java.io.InputStream;
      import java.io.OutputStreamWriter;
      import java.net.HttpURLConnection;
      import java.net.URL;
      import java.util.Scanner;

      class Main {
        public static void main(String[] args) throws IOException {

          OkHttpClient client = new OkHttpClient().newBuilder()
          .build();
        MediaType mediaType = MediaType.parse("application/json");
        RequestBody body = RequestBody.create(mediaType, ${requestData[2]});
        Request request = new Request.Builder()
          .url(${requestData[0]})
          .method("POST", body)
          ${Object.entries(requestData[1]).map(
            ([key, value]) =>
              `\n  \u00A0 \u00A0 \u00A0 .addHeader(${key}, ${value});`
          )}          
          .build();
        Response response = client.newCall(request).execute();`;
    return convertedRequest;
  }

  toJavascript(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
      var myHeaders = new Headers();
      ${Object.entries(requestData[1]).map(
        ([key, value]) =>
          `\n  \u00A0 \u00A0 \u00A0 myHeaders.append(${key}, ${value});`
      )}          
      
      var raw = JSON.stringify(${requestData[2]});
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(${requestData[0]}, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error))`;
    return convertedRequest;
  }

  toJSON(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    {
      "url": ${requestData[0]},
      "raw_url": ${requestData[0]},
      "method": "post",
      "headers": ${JSON.stringify(requestData[1])},
      "data": ${requestData[2]}
    }`;
    return convertedRequest;
  }

  toMATLAB(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    %% Web Access using Data Import and Export API
      uri = ${requestData[0]};
      body = ${requestData[2]};
      options = weboptions(...
          'MediaType', 'application/json',...
          'HeaderFields', {
              'app-id' '{{app-id}}'
              'api-key' '{{api-key}}'
          }...
      );
      response = webwrite(uri, body, options);

      %% HTTP Interface
      import matlab.net.*
      import matlab.net.http.*
      import matlab.net.http.io.*

      header = [
        ${Object.entries(requestData[1]).map(
          ([key, value]) =>
            `\n  \u00A0 \u00A0 \u00A0 HeaderField(${key}, ${value})`
        )}
      ]';
      uri = URI(${requestData[0]});
      body = FormProvider(...
          ${requestData[2]}...
      );
      response = RequestMessage('post', header, body).send(uri.EncodedURI);`;
    return convertedRequest;
  }

  toPHP(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    <?php
    $curl = curl_init();
    
    curl_setopt_array($curl, array(
      CURLOPT_URL => ${requestData[0]},
      CURLOPT_RETURNTRANSFER => true,
      CURLOPT_ENCODING => '',
      CURLOPT_MAXREDIRS => 10,
      CURLOPT_TIMEOUT => 0,
      CURLOPT_FOLLOWLOCATION => true,
      CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
      CURLOPT_CUSTOMREQUEST => 'POST',
      CURLOPT_POSTFIELDS =>${requestData[2]},
      CURLOPT_HTTPHEADER => array(
        ${Object.entries(requestData[1]).map(
          ([key, value]) => `\n  \u00A0 \u00A0 \u00A0 (${key}, ${value})`
        )}
      ),
    ));
    
    $response = curl_exec($curl)
    curl_close($curl);
    echo $response;`;
    return convertedRequest;
  }

  toR(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    library(RCurl)
      headers = c(
        ${Object.entries(requestData[1]).map(
          ([key, value]) => `\n  \u00A0 \u00A0 \u00A0 \`${key}\` = ${value}`
        )} 
      )
      params = ${requestData[2]}

      res <- postForm(${
        requestData[0]
      }, .opts=list(postfields = params, httpheader = headers, followlocation = TRUE), style = "httppost")
cat(res)`;
    return convertedRequest;
  }

  toRuby(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    require "uri"
    require "json"
    require "net/http"
      
      url = URI(${requestData[0]})
      https = Net::HTTP.new(url.host, url.port)
      https.use_ssl = true
      request = Net::HTTP::Post.new(url)
      ${Object.entries(requestData[1]).map(
        ([key, value]) => `\n  \u00A0 \u00A0 \u00A0 request[${key}] = ${value}`
      )} 

      req.body = JSON.dump(${requestData[2]})

      response = https.request(request)
      puts response.read_body
      `;
    return convertedRequest;
  }

  toRust(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    #[tokio::main]
    async fn main() -> Result<(), Box<dyn std::error::Error>> {
        let client = reqwest::Client::builder()
            .build()?;
    
        let mut headers = reqwest::header::HeaderMap::new();
        ${Object.entries(requestData[1]).map(
          ([key, value]) =>
            `\n  \u00A0 \u00A0 \u00A0 headers.insert[${key}] = ${value}`
        )} 
  
        
    
        let data = r#"{${requestData[2]}}"#;
    
        let json: serde_json::Value = serde_json::from_str(&data)?;
    
        let request = client.request(reqwest::Method::POST, ${requestData[0]})
            .headers(headers)
            .json(&json);
    
        let response = request.send().await?;
        let body = response.text().await?;
    
        println!("{}", body);
    
        Ok(())
    }`;
    return convertedRequest;
  }

  toStrest(request: string, requestType: string): string {
    const requestData = this.getData(request, requestType);
    const convertedRequest = `
    version: 2
      requests:
        curl_converter:
          request:
            url: ${requestData[0]}
            method: POST
            postData:
              mimeType: application/json
              text: ${requestData[2]}
            headers:
            ${Object.entries(requestData[1]).map(
              ([key, value]) =>
                `-
                name: ${key}
                value: ${value}`
            )}`;
    return convertedRequest;
  }

  toCurl(request: string, requestType: string) {
    return requestType === "test" ? request.replace("live.", "test.") : request;
  }
}

export { curlconverter };
