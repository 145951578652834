//Some services provides trials services. To enable them this popup is rendered.
import React from "react";
import Styles from "../../../../Shared/popup.module.scss";
import classes from "./trialActivatedPopUp.module.scss";
import newProductSrc from "../../../../assets/images/congratulations.svg";
import Button from "../../../../Shared/button/button";
import {
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../Shared/buttonSize";

function TrialActivatedPopUp({ onConfirm, trial_transaction }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 ${Styles.popUpContent}`} style={{ width: "600px" }}>
        <div className="py-3 text-center">
          <img
            src={newProductSrc}
            alt="new product"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 px-3">
          <p className={`mb-0 text-center ${classes.welcomeText}`}>
            Congratulations
          </p>
          <div className="py-2">
            <p className={`mb-0 text-center ${classes.headingText}`}>
              You have successfully enabled the service,{" "}
              <span className={classes.transactions}>
                {" "}
                {trial_transaction}{" "}
              </span>
              trial transactions have been enabled.
            </p>
          </div>
          <div className="py-2 px-3">
            <p className={`mb-0 text p-3 ${classes.noteText}`}>
              <p className="card_title_semibold_text">Note</p>
              Post trial you can directly link this service to your production
              account to enable the service as per the current plan selected.
              Wallet deductions will apply accordingly.
            </p>
          </div>
        </div>
        <div className="py-1 d-flex justify-content-center">
          <div>
            <Button
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              onClick={() => onConfirm()}
              size={MEDIUM}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrialActivatedPopUp;
