import React from "react";
import SuccessPopup from "../../success-popup/successPopup";
import { callApi } from "../../../api/fetch";
import CreateZpin from "../create-zpin/createZpin";
import OtpVerificationPopUp from "../otp-verification-pop-up/otpVerificationPopUp";

function ZpinCreateOrUpdate({
  forUpdate,
  forOtp,
  onCancel,
  resendOtp,
  onVerifyOtp,
  afterSuccessfulCreation,
}: any) {
  const [createZpin, setCreateZpin] = React.useState(!forOtp);
  const [zpin, setZpin] = React.useState("");
  const [relayId, setRelayId] = React.useState("");
  const forOtpFlow = forOtp;
  const orgId = localStorage.getItem("organisation_id");
  const [forUpdateFlow, setForUpdateFlow] = React.useState(forUpdate);
  const [otpForZpin, setOtpForZpin] = React.useState(forOtp);
  const [onSuccess, setOnSuccess] = React.useState(false);

  async function handleZpinAuthCreate(
    otp_entered: string,
    setInvalidOtpError: any
  ) {
    try {
      await callApi("/zpin", {
        method: "POST",
        data: {
          zpin: zpin,
          orgId: orgId,
          relayId: relayId,
          otp: otp_entered,
        },
      });
      setOtpForZpin(false);
      setOnSuccess(true);
      setTimeout(() => {
        setOnSuccess(false);
        afterSuccessfulCreation();
      }, 3000);
    } catch (error) {
      setInvalidOtpError();
    }
  }

  async function handleZpinAuthUpdate(
    otp_entered: string,
    setInvalidOtpError: any
  ) {
    try {
      await callApi(`/zpin/${orgId}`, {
        method: "PUT",
        data: {
          zpin: zpin,
          relayId: relayId,
          otp: otp_entered,
        },
      });
      setOtpForZpin(false);
      setOnSuccess(true);
      setTimeout(() => {
        setOnSuccess(false);
        setForUpdateFlow(false);
        afterSuccessfulCreation();
      }, 3000);
    } catch (error) {
      console.log(error);
      setInvalidOtpError();
    }
  }

  async function getZpin(zpin_entered: string) {
    setZpin(zpin_entered);
    setCreateZpin(false);
    setOtpForZpin(true);
    const relayId = await resendOtp("Z-PIN");
    setRelayId(relayId);
  }

  function getOtp(zpin: string, otp_entered: string, setInvalidOtpError: any) {
    if (forOtpFlow) {
      onVerifyOtp(zpin, otp_entered, setInvalidOtpError);
      return;
    }
    forUpdateFlow
      ? handleZpinAuthUpdate(otp_entered, setInvalidOtpError)
      : handleZpinAuthCreate(otp_entered, setInvalidOtpError);
  }

  return (
    <>
      {createZpin && (
        <CreateZpin
          onCancel={onCancel}
          getZpin={getZpin}
          resendOtp={resendOtp}
        />
      )}
      {otpForZpin && (
        <OtpVerificationPopUp
          onCancel={() => onCancel()}
          getOtp={getOtp}
          resendOtp={resendOtp}
          forZpin={true}
        />
      )}
      {onSuccess && (
        <SuccessPopup
          message={`ZPIN successfully ${forUpdateFlow ? "updated" : "created"}`}
        />
      )}
    </>
  );
}

export default ZpinCreateOrUpdate;
