import React from "react";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import { validCinNumber } from "../../../../../utils/validation";

function CinServicePopup({ handleResponse, name }: any) {
  const errorObject = {
    cinNumberError: false,
    consentError: false,
  };

  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const cinNumberRef = React.useRef<any>("");

  function isValid() {
    if (validCinNumber(cinNumberRef.current.value.trim().toUpperCase())) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    setError(() => ({
      ...error,
      cinNumberError: true,
    }));
    return setIsDisabled(true);
  }

  function handleCheck() {
    const cinNumber = cinNumberRef.current.value.toUpperCase();
    const consent = isChecked === true ? "Y" : "N";

    if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }
    setLoading(true);
    handleResponse({ cinNumber, consent }, setLoading);
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>CIN Number</label>
            <InputField
              onChange={isValid}
              maxLength={21}
              inputRef={cinNumberRef}
              isError={error.cinNumberError}
              purpose="forPan"
              id="id-input-cin"
            />
            {error.cinNumberError && (
              <ErrorMessage>Please enter a valid CIN number</ErrorMessage>
            )}
          </div>
          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-cin"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CinServicePopup;
