//sections
export const DEVELOPERDOCS = "Developer Docs";
export const PRODUCTS = "Products";
export const RESPONSECODES = "Response Codes";
export const HELP = "Need More Help";
export const INTRO = "Introduction";
export const DASHBOARD = "Dashboard";

//product categories
export const MERCHANT = "Merchant Verification";
export const UTILITY = "Utility Verification";
export const FINANCIAL = "Financial Verification";
export const IDENTITY = "Identity Verification";
export const VEHICLE = "Vehicle Verification";
export const ML = "ML Utility";

//Add mapping to this object whenever new category is introduced
export const categoryList: any = {
  "Identity Verification": "Identity",
  "Merchant Verification": "Merchant",
  "Financial Verification": "Financial",
  "Utility Verification": "Utility",
  "Vehicle Verification": "Vehicle",
  "ML Utility": "ML",
  "Face Analytics": "Face Analytics",
  "Persona Verification": "Persona",
};

export const languageChoices = [
  { value: "ansible", label: "Ansible" },
  { value: "c#", label: "C#" },
  { value: "cfml", label: "CFML" },
  { value: "dart", label: "Dart" },
  { value: "elixir", label: "Elixir" },
  { value: "go", label: "Go" },
  { value: "java", label: "Java" },
  { value: "javascript", label: "JavaScript" },
  { value: "json", label: "JSON" },
  { value: "node", label: "Node.js" },
  { value: "matlab", label: "MATLAB" },
  { value: "python", label: "Python" },
  { value: "r", label: "R" },
  { value: "ruby", label: "Ruby" },
  { value: "rust", label: "Rust" },
  { value: "strest", label: "Strest" },
  { value: "curl", label: "cURL" },
];

export const languageChoiceDropdownStyles = {
  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    outline: "none",
  }),
  control: () => ({
    height: "35px",
    width: "100px",
    border: "1px solid #ebebeb",
    display: "flex",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "'Poppins' sans-serif",
    marginBottom: "10px",
    cursor: "pointer",
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: "180px",
    borderRadius: "8px",
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export const arnToServiceNameMapping: any = {
  in_identity_pan_lite: "PAN Lite",
  in_identity_pan_advance: "PAN Advance",
  in_identity_dl_advance: "DL Advance",
  in_identity_voter_advance: "Voter Advance",
  in_identity_passport_lite: "Passport Lite",
  in_identity_epfo_pro: "Corporate Verification",
  in_financial_bav_lite: "Bank Account Verification",
  in_merchant_gstin_lite: "GSTIN Lite",
  in_merchant_gstin_advance: "GSTIN Advance",
  in_utility_ifsc_lite: "IFSC Lite",
  in_identity_pan_demographic: "PAN Demographic",
  in_identity_pan_206ab: "PAN 206AB",
  in_utility_telecom_circle: "Telecom Circle",
  in_identity_pan_pro: "PAN Pro",
  in_identity_aadhaar_verification: "Aadhaar Verification",
  in_merchant_cin_advance: "CIN Advance",
  in_merchant_gstpan_lite: "GSTIN PAN",
  in_merchant_fssai_number: "FSSAI API",
  in_merchant_udyog_lite: "Udyog Aadhaar",
  in_ml_face_match: "Face Match",
  in_ml_face_crop: "Face Crop",
  in_vehicle_rc_rto: "RC RTO",
  in_vehicle_rc_lite: "RC Lite",
  in_vehicle_rc_advance: "RC Advance",
  in_vehicle_rc_dynamic: "RC Dynamic",
  in_vehicle_rc_utility: "RC Utility",
  in_vehicle_rc_challan: "RC Challan",
  in_utility_ocr_lite: "OCR Lite",
  in_identity_aadhaar_okyc: "OKYC VERIFY",
  "": "All Products",
  in_merchant_report_pro: "Merchant Reports",
  in_merchant_report_pro_pan_cin: "PAN CIN",
  in_merchant_report_pro_pan_cin_gst: "PAN CIN GST",
  in_merchant_report_pro_pan_gst: "PAN GST",
  in_utility_telecom_otp: "MOBILE OTP VERIFY",
  in_merchant_report_pro_gst_pan: "GST CIN",
  in_merchant_report_pro_cin_gst: "CIN GST",
  in_persona_email_verification: "EMAIL VERIFY",
};

export const serviceNameToARNMapping: any = {
  "Pan Lite": "in_identity_pan_lite",
  "Pan Advance": "in_identity_pan_advance",
  "Driving License Advance": "in_identity_dl_advance",
  "Voter ID Advance": "in_identity_voter_advance",
  "Passport Lite": "in_identity_passport_lite",
  "Bank A/c Verification Lite": "in_financial_bav_lite",
  "GST Verification Lite": "in_merchant_gstin_lite",
  "GST Verification Advance": "in_merchant_gstin_advance",
  "IFSC Verification Lite": "in_utility_ifsc_lite",
  "Pan Demographic": "in_identity_pan_demographic",
  "Pan 206AB": "in_identity_pan_206ab",
  "Telecom circle API": "in_utility_telecom_circle",
  "Pan Pro": "in_identity_pan_pro",
  "Aadhaar Verification": "in_identity_aadhaar_verification",
  "CIN Advance": "in_merchant_cin_advance",
  "GST PAN": "in_merchant_gstpan_lite",
  "Face Match": "in_ml_face_match",
  "Face Crop": "in_ml_face_crop",
  "Face Liveness": "in_ml_face_liveness",
  "RC RTO Vehicle Verification ": "in_vehicle_rc_rto",
  "RC Verification Lite": "in_vehicle_rc_lite",
  "RC Verification Advance": "in_vehicle_rc_advance",
  "RC Verification Dynamic": "in_vehicle_rc_dynamic",
  "RC Utility": "in_vehicle_rc_utility",
  "RC Challan": "in_vehicle_rc_challan",
  "OCR Lite": "in_utility_ocr_lite",
  "OKYC VERIFY": "in_identity_aadhaar_okyc",
  "FSSAI API": "in_merchant_fssai_number",
  "Udyog Aadhaar": "in_merchant_udyog_lite",
  "Name Match API": "in_identity_name_match",
  "Student Verification SDK": "in_persona_student_verification",
  "All Products": "",
  "MOBILE OTP VERIFY": "in_utility_telecom_otp",
  "Merchant Reports": "in_merchant_report_pro",
  "Corporate Verification": "in_identity_epfo_pro",
  "PAN CIN": "in_merchant_report_pro_pan_cin",
  "PAN CIN GST": "in_merchant_report_pro_pan_cin_gst",
  "PAN GST": "in_merchant_report_pro_pan_gst",
  "GST CIN": "in_merchant_report_pro_gst_pan",
  "CIN GST": "in_merchant_report_pro_cin_gst",
  "EMAIL VERIFY": "in_persona_email_verification",
};

export function formatCurl(curl: string) {
  const formattedCurl: string = curl
    .trim()
    .replaceAll("\n", "")
    .replaceAll(" ", "")
    .replaceAll("--header", " --header ")
    .replaceAll("--location", " --location ")
    .replace("--request", "--request ")
    .replace("POST", "POST ")
    .replaceAll("--data-raw", " --data-raw ")
    .replaceAll("--data", " --data ")
    .replaceAll('"', "'");

  return formattedCurl.substring(0, formattedCurl.length - 1);
}

//categories mapping
export const categories = [
  {
    name: [
      "GST Verification Lite",
      "GST Verification Advance",
      "GST PAN",
      "Pan Lite",
      "Pan Advance",
      "Pan Demographic",
      "Pan Pro",
      "IFSC Verification Lite",
      "Pan 206AB",
      "Bank A/c Verification Lite",
    ],
    value: 0,
  },
  {
    name: ["Driving License Advance", "CIN Advance", "Face Match"],
    value: 1,
  },
  {
    name: ["Passport Lite", "Face Crop", "FSSAI API"],
    value: 2,
  },
  {
    name: ["Voter ID Advance", "OCR Lite", "Udyog Aadhaar"],
    value: 3,
  },
  {
    name: ["OKYC", "Telecom circle API", "Merchant Reports"],
    value: 4,
  },
  {
    name: ["Corporate Verification"],
    value: 5,
  },
];

//sub categories mapping

export const sub_categories = [
  {
    name: ["GST Verification Lite", "Pan Lite"],
    value: 0,
  },
  {
    name: ["GST Verification Advance", "Pan Advance"],
    value: 1,
  },
  {
    name: ["GST PAN", "Pan Demographic"],
    value: 2,
  },
  {
    name: ["Pan 206AB"],
    value: 3,
  },
  {
    name: ["Pan Pro"],
    value: 4,
  },
];

//UTILITIES
export const capitalise = (str: string) => {
  const words = str.trim().split(" ");
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export const extractURL = (curl: string, test?: boolean) => {
  return test
    ? curl.split(" ")[2].replace("live.", "test.")
    : curl.split(" ")[2];
};

export const extractBody = (curl: string) => {
  let body = curl.includes("--data-raw ")
    ? curl.split("--data-raw ")[1]
    : curl.split("--data ")[1];
  const temp = body.split("");
  temp.shift();
  temp.pop();
  body = temp.join("");
  const final_body = JSON.parse(body);
  return JSON.stringify(final_body);
};
// view docs button redirect to the confluence for eSign documents
export const productToLinkMap: any = {
  "Aadhaar based e-sign":
    "https://documenter.getpostman.com/view/19964277/2s9Ykkehsi",
  "Face Liveness":
    "https://zoop.atlassian.net/wiki/spaces/ZD/pages/355697409/Liveness",
  "OCR SDK":
    "https://zoop.atlassian.net/wiki/spaces/ZD/pages/396525607/OCR+SDK",
  DIGILOCKER:
    "https://zoop.atlassian.net/wiki/spaces/ZD/pages/344031598/ZOOP+Digilocker+Documentation+Beta",
  "Student Verification SDK": "https://zoop.atlassian.net/l/cp/FdL9VJZS",
  OKYC: "/doc#tag/AADHAAR/operation/okyc_generate_otp",
  "Mobile OTP": "/doc#tag/TELECOM/operation/mobile_otp_request",
  "Email-Verification API":
    "/doc#tag/EMAIL/operation/email-verification_api_request",
  "Estamp API": "https://documenter.getpostman.com/view/19964277/2sA3Bq2qPJ",
};

export const customMap: any = {
  in_utility_ifsc_lite: "Financial",
  in_utility_ocr_cheque: "ML",
  in_utility_ocr_lite: "ML",
  in_ml_face_match: "Face Analytics",
  in_ml_face_crop: "Face Analytics",
};
