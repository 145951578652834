export const ONBOARDING_STATUS = {
  ORG_CREATED: "ORG_CREATED",
  SERVICES_SUBSCRIBED: "SERVICES_SUBSCRIBED",
  SKIPPED: "SKIPPED",
  TEST_APP_CREATED: "TEST_APP_CREATED",
  SERVICES_ADDED: "SERVICES_ADDED",
  ONBOARDING_COMPLETED: "ONBOARDING_COMPLETED",
  ACTIVATION_REQUESTED: "ACTIVATION_REQUESTED",
  ACCOUNT_ACTIVATED: "ACCOUNT_ACTIVATED",
  PLAN_SUBSCRIBED: "PLAN_SUBSCRIBED",
};
