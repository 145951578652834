import { RedocStandalone } from "redoc";
import React, { useEffect, useState } from "react";

import {
  addPaths,
  createMultiProductOpenApiObject,
  createOpenApiObject,
} from "./openapi.algo";
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";
import { useTitle } from "../../../Shared/useTitle";
import { ACCENTCOLOR } from "../../../Shared/colors";
import { OpenApiDoc, OpenApiTheme } from "./openapi";
import Loading from "../../../Shared/loading/loading";
import { curlconverter } from "../developer-docs/common/curlconverter";

function Docs() {
  useTitle("Documentation | Zoop Customer Platform");
  const [updatedDoc, setUpdatedDoc] = useState<any>(null);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });

  const curlCon = new curlconverter();
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  const getProductsDocumentation = async () => {
    try {
      const data = await callApi("/doc", {
        method: "GET",
      });

      const docs = addPaths(data);
      const docsMap = new Map();
      for (const doc of docs) {
        if (docsMap.get(doc.path) === undefined) {
          docsMap.set(doc.path, [doc]);
        } else {
          docsMap.get(doc.path)?.push(doc);
        }
      }

      const tagGroups = [];
      const paths: Record<string, object> = {};
      const components: Record<string, object> = {};
      const tags: Map<string, string[]> = new Map();

      for (const [key, val] of docsMap) {
        const hasMultipleProducts = val.length > 1;

        if (tags.get(val[0].category) === undefined) {
          tags.set(val[0].category, [val[0].type]);
        } else if (!tags.get(val[0].category)?.includes(val[0].type)) {
          tags.get(val[0].category)?.push(val[0].type);
        }

        if (hasMultipleProducts) {
          const openApiObjs: any = createMultiProductOpenApiObject(val);
          let desc: string = "";
          const curls: any[] = [];
          for (const doc of val) {
            desc =
              desc +
              " " +
              String(`\n\n## ${doc.name.toUpperCase()} \n${doc.description}`);

            const testCurl = doc.curl.replace("live.", "test.");
            const prodCurl = doc.curl.replace("test.", "live.");

            curls.push({
              lang: doc.name + " TEST cURL",
              source: testCurl,
            });
            curls.push({
              lang: doc.name + " LIVE cURL",
              source: prodCurl,
            });
          }

          paths[key] = {
            parameters: openApiObjs.parameters,
            post: {
              description: desc,
              operationId: val[0].type.toLowerCase().replaceAll(" ", "_"),
              tags: [val[0].type],
              summary: val[0].type,
              responses: openApiObjs.responses,
              requestBody: openApiObjs.request_body,
              "x-codeSamples": curls,
            },
          };

          if (openApiObjs.components) {
            components.schemas = {
              ...components.schemas,
              ...openApiObjs.components.schemas,
            };
          }
        } else {
          const openApiObj = createOpenApiObject(
            val[0].arn,
            val[0].path,
            val[0].name,
            val[0].description,
            val[0].type,
            val[0].category,
            val[0].curl,
            val[0].headers,
            val[0].params,
            val[0].responses
          );

          const testCurl = openApiObj.curl.replace("live.", "test.");
          const prodCurl = openApiObj.curl.replace("test.", "live.");

          const operationId = openApiObj.name
            .toLowerCase()
            .replaceAll(" ", "_");
          paths[key] = {
            parameters: openApiObj.parameters,
            post: {
              description: openApiObj.description,
              operationId: operationId,
              tags: [openApiObj.tag],
              summary: openApiObj.name,
              responses: openApiObj.responses,
              requestBody: openApiObj.request_body,
              "x-codeSamples": [
                {
                  lang: "TEST cURL",
                  source: testCurl,
                },
                {
                  lang: "LIVE cURL",
                  source: prodCurl,
                },
                {
                  lang: "Python",
                  source: curlCon.toPython(openApiObj.curl, "live"),
                },
                {
                  lang: "Java",
                  source: curlCon.toJava(openApiObj.curl, "live"),
                },
                {
                  lang: "JavaScript",
                  source: curlCon.toJavascript(openApiObj.curl, "live"),
                },
                {
                  lang: "C#",
                  source: curlCon.toCSharp(openApiObj.curl, "live"),
                },
                {
                  lang: "PHP",
                  source: curlCon.toPHP(openApiObj.curl, "live"),
                },
                {
                  lang: "Go",
                  source: curlCon.toGo(openApiObj.curl, "live"),
                },
                {
                  lang: "R",
                  source: curlCon.toR(openApiObj.curl, "live"),
                },
              ],
            },
          };

          if (openApiObj.components) {
            components.schemas = {
              ...components.schemas,
              ...openApiObj.components.schemas,
            };
          }
        }
      }

      for (const [key, val] of tags) {
        tagGroups.push({
          name: key,
          tags: val,
        });
      }

      setUpdatedDoc({
        ...OpenApiDoc,
        "x-tagGroups": tagGroups,
        paths: paths,
        components: components,
      });
    } catch (error) {
      setUpdatedDoc({
        ...OpenApiDoc,
      });
      toastTimeout(true, "error", "Failed to fetch product documentation");
    }
  };

  useEffect(() => {
    getProductsDocumentation();
  }, []);

  return (
    <div className="w-100 h-100">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      {updatedDoc ? (
        <RedocStandalone
          spec={updatedDoc}
          options={{
            expandResponses: "200",
            nativeScrollbars: true,
            hideDownloadButton: true,
            sortTagsAlphabetically: true,
            sortPropsAlphabetically: true,
            sortOperationsAlphabetically: true,
            theme: OpenApiTheme,
          }}
        />
      ) : (
        <div
          style={{ height: "100vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      )}
    </div>
  );
}

export default Docs;
