import React from "react";

function RupeeSVGnew(classes: any) {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 50 60"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path
        d="M4.89234 29.8856H4.88113L4.86993 29.8858C3.2691 29.9097 1.48659 31.0649 1.51878 33.0175C1.52451 33.9738 2.02528 34.7685 2.63677 35.2803L2.65246 35.2935L2.6685 35.3062L31.0137 57.746C31.0145 57.7467 31.0153 57.7473 31.0161 57.7479C32.3331 58.7932 34.323 58.7428 35.5807 57.6347L35.5876 57.6286L35.5945 57.6225C36.2549 57.026 36.6293 56.2014 36.5988 55.324C36.5684 54.4503 36.1418 53.6588 35.4626 53.1045L35.454 53.0975L35.4453 53.0906L13.8874 36.0221H16.209C26.0258 36.0221 34.5488 30.018 36.2755 21.7509H44.5192C46.1171 21.7509 47.8929 20.6125 47.8929 18.6827C47.8929 16.7528 46.1171 15.6144 44.5192 15.6144H36.2647C35.6128 12.6366 34.0336 9.87541 31.8084 7.63653H44.5192C46.1171 7.63653 47.8929 6.49812 47.8929 4.56827C47.8929 2.63841 46.1171 1.5 44.5192 1.5H4.87361C3.27571 1.5 1.5 2.63842 1.5 4.56827C1.5 6.49812 3.27571 7.63653 4.87361 7.63653H16.2277C22.5984 7.63653 27.699 11.1821 29.3114 15.6144H4.87361C3.27571 15.6144 1.5 16.7528 1.5 18.6827C1.5 20.6125 3.27571 21.7509 4.87361 21.7509H29.3271C27.7166 26.3616 22.5501 29.8719 16.2264 29.8856H4.89234Z"
        fill="url(#paint0_linear_2210_1517)"
        stroke="url(#paint1_linear_2210_1517)"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2210_1517"
          x1="24.6964"
          y1="3"
          x2="24.6964"
          y2="57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0075F3" />
          <stop offset="0.510417" stopColor="#66B0FF" />
          <stop offset="1" stopColor="#9ECDFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2210_1517"
          x1="24.6964"
          y1="3"
          x2="24.6964"
          y2="57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0075F3" />
          <stop offset="0.505208" stopColor="#66B0FF" />
          <stop offset="1" stopColor="#9ECDFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default RupeeSVGnew;
