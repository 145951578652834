import React from "react";

function arrowLeftSVG() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.545 3.53416C13.7933 3.77749 13.8158 4.15916 13.6125 4.42749L13.5458 4.50416L7.93833 9.99999L13.5442 15.4958C13.7925 15.7392 13.815 16.1208 13.6117 16.3892L13.545 16.4658C13.4252 16.5825 13.2681 16.6532 13.1013 16.6654C12.9345 16.6776 12.7688 16.6305 12.6333 16.5325L12.555 16.4658L6.45417 10.485C6.33578 10.37 6.26356 10.2157 6.25113 10.0511C6.23869 9.88655 6.2869 9.72313 6.38667 9.59166L6.45333 9.51499L12.555 3.53416C12.6875 3.40551 12.8649 3.33356 13.0496 3.33356C13.2343 3.33356 13.4117 3.40551 13.5442 3.53416H13.545Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}

export default arrowLeftSVG;
