import React from "react";
import { Link } from "react-router-dom";
import Styles from "./service-info-cards.module.scss";

function ServiceInfoCards({
  uniqueId,
  handleServicePopup,
  serviceInfo = {},
  browse,
}: any) {
  function handleBrowseServices() {}
  return (
    <div
      className={`${Styles.serviceInfoCard} d-flex flex-column justify-content-between`}
    >
      {!browse && (
        <>
          <div className="d-flex flex-column">
            <p className="title_bold_text">{serviceInfo.name}</p>
            <p className={Styles.serviceDesc}>
              {serviceInfo.short_description}
            </p>
          </div>
          <div className="d-flex flex-column">
            <p className={`${Styles.avalCreds}`}>Available Trial Credits:</p>
            <p className={Styles.creditsInfo}>
              {serviceInfo.remaining_test_credits}
            </p>
            <button
              id={uniqueId}
              className={Styles.trialButton}
              onClick={() => handleServicePopup(serviceInfo)}
            >
              Run Trial
            </button>
          </div>
        </>
      )}
      {browse && (
        <Link
          to="/application/product-catalog"
          className={`d-flex flex-column justify-content-center align-items-center ${Styles.browseButtonContainer}`}
        >
          <button
            className={Styles.browseButton}
            onClick={() => handleBrowseServices()}
          >
            +
          </button>
          <p>Browse Products</p>
        </Link>
      )}
    </div>
  );
}

export default ServiceInfoCards;
