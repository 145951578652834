// Detailed reports havin graph analysis and tabular description
import React, { useEffect } from "react";
import Styles from "./report.module.scss";
import {
  linkButtonStyleReport,
  linkButtonHoverStyleReport,
  primaryButtonHoverStyleReport,
  primaryButtonStyleReport,
} from "../../../Shared/buttonStyles";
import Button from "../../../Shared/button/button";
import { MEDIUM } from "../../../Shared/buttonSize";
import InputDropdown from "../../../Shared/input-dropdown/inputDropdown";
import { callApi } from "../../../api/fetch";
import Toast from "../../../Shared/toast/toast";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import moment from "moment";
import { useTitle } from "../../../Shared/useTitle";
import CustomDatePicker from "../../../Shared/custom-date-picker/customDatePicker";
import ReportResultFileSvg from "../../../Shared/svg/reportResultFileIcon";
import Input from "../../../Shared/input/input";
import { format } from "date-fns";

function Report() {
  const org_id = localStorage.getItem("organisation_id");
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [toggleRunReport, setToggleRunReport] = React.useState(false);
  const [reportIsRunning, setReportIsRunning] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [postPerPage] = React.useState(50);
  const [inputValues, setInputValues] = React.useState({
    searchIds: "",
    from: null,
    to: null,
    productType: {
      name: "",
      arn: "",
    },
    statusType: {
      label: "",
      value: "",
    },
    chargedType: {
      label: "",
      value: "",
    },
    env: {
      label: "",
      value: "",
    },
    appName: {
      label: "",
      value: "",
    },
  });
  const [error, setError] = React.useState({
    searchIdError: "",
    productTypeError: "",
    durationError: "",
    statusError: "",
    chargedTypeError: "",
    fromError: "",
    ToError: "",
    appNameError: "",
  });
  const [validData, setValidData] = React.useState({
    searchId: false,
    productType: false,
    duration: false,
    status: false,
    chargedType: false,
    from: false,
    to: false,
    env: false,
    appName: false,
  });
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [allProducts, setAllProducts] = React.useState<any>([]);
  const statusCategories: any = [
    { label: "All", value: "ALL" },
    { label: "Success", value: "SUCCESS" },
    { label: "Failure", value: "FAILURE" },
  ];
  const chargedCategories: any = [
    { label: "All", value: "ALL" },
    { label: "Billable", value: "BILLABLE" },
    { label: "Non Billable", value: "NON_BILLABLE" },
  ];
  const envs = [
    { label: "All", value: "ALL" },
    { label: "Test", value: "TEST" },
    { label: "Production", value: "PRODUCTION" },
  ];
  const [queryParams, setQueryParams] = React.useState("");

  const [appNames, setAppNames] = React.useState<any>([]);

  const [showCustomDatePicker, setShowCustomDatePicker] = React.useState<
    boolean
  >(false);

  const [
    customDurationPlaceholder,
    setCustomDurationPlaceholder,
  ] = React.useState<string | null>(null);

  const dataRef = React.useRef<any>();
  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }
  // get all subscribed products api
  useEffect(() => {
    async function getAllAppNames() {
      try {
        const appNames = await callApi("/app?page=report", {
          method: "GET",
          init: {
            headers: {
              "x-org-id": `${org_id}`,
            },
          },
        });
        setAppNames(appNames);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
      }
    }

    async function getAllProducts() {
      try {
        const products = await callApi(
          "/product?subscription_status=subscribed",
          {
            method: "GET",
          }
        );

        const all_products = products.map((product: any) => ({
          name: product.name,
          arn: product.arn,
        }));
        setAllProducts([
          {
            name: "All",
            arn: "ALL",
          },
          ...all_products,
        ]);
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
      }
    }
    getAllAppNames();
    getAllProducts();
  }, [org_id]);

  useEffect(() => {
    // get reports of the org API
    async function getOrgReports() {
      if (!checkWhetherToRunReport()) {
        return;
      }

      setReportIsRunning(true);
      try {
        const { message, data } = await callApi(
          `/organisation/${org_id}?action=get_org_csv_usage_reports&page_no=${activePageNumber}&per_page=${postPerPage}${queryParams}`,
          {
            method: "GET",
          }
        );
        if (data) {
          setReportData(data);
        }
        if (message) {
          setReportData([]);
          toastTimeout(true, "success", message);
        }
      } catch (err) {
        toastTimeout(true, "error", (err as Error).message);
      } finally {
        setReportIsRunning(false);
      }
    }
    function checkWhetherToRunReport() {
      return (
        inputValues.searchIds !== "" ||
        inputValues.productType.name !== "" ||
        inputValues.chargedType.value !== "" ||
        inputValues.statusType.value !== "" ||
        inputValues.appName.label !== "" ||
        inputValues.from !== null ||
        inputValues.to !== null ||
        inputValues.env.value !== ""
      );
    }
    getOrgReports();
    // eslint-disable-next-line
  }, [org_id, queryParams, toggleRunReport, activePageNumber]);

  function getInitialState() {
    setInputValues({
      searchIds: "",
      from: null,
      to: null,
      productType: {
        name: "",
        arn: "",
      },
      statusType: {
        label: "",
        value: "",
      },
      chargedType: {
        label: "",
        value: "",
      },
      env: {
        label: "",
        value: "",
      },
      appName: {
        label: "",
        value: "",
      },
    });
    setError({
      searchIdError: "",
      productTypeError: "",
      durationError: "",
      statusError: "",
      chargedTypeError: "",
      fromError: "",
      ToError: "",
      appNameError: "",
    });
    setValidData({
      searchId: false,
      productType: false,
      duration: false,
      status: false,
      chargedType: false,
      from: false,
      to: false,
      env: false,
      appName: false,
    });
    setQueryParams("");
    setCustomDurationPlaceholder("");
    setToggleRunReport(!toggleRunReport);
  }

  function handleRunReport() {
    let str = "";
    if (inputValues.searchIds !== "") {
      str += `&id=${inputValues.searchIds}`;
    }
    if (
      inputValues.productType.arn !== "" &&
      inputValues.productType.arn !== "ALL"
    ) {
      str += `&product_arn=${inputValues.productType.arn}`;
    }
    if (
      inputValues.chargedType.value !== "" &&
      inputValues.chargedType.value !== "ALL"
    ) {
      str += `&charge_type=${inputValues.chargedType.value}`;
    }
    if (
      inputValues.statusType.value !== "" &&
      inputValues.statusType.value !== "ALL"
    ) {
      str += `&txn_status=${inputValues.statusType.value}`;
    }
    if (
      (inputValues.env.value === envs[1].value ||
        inputValues.env.value === envs[2].value) &&
      inputValues.env.value !== "ALL"
    ) {
      str += `&env=${inputValues.env.value}`;
    }
    if (inputValues.appName.label !== "") {
      str += `&app_id=${inputValues.appName.value}`;
    }
    if (inputValues.from && inputValues.to) {
      str += `&start_date=${moment(inputValues.from).format(
        "YYYY-MM-DD"
      )}&end_date=${moment(inputValues.to).format("YYYY-MM-DD")}`;
    } else {
      if (inputValues.searchIds === "") {
        toastTimeout(true, "error", "Duration is mandatory");
        return;
      }
    }
    scroll();
    setToggleRunReport(!toggleRunReport);
    setQueryParams(str);
    setActivePageNumber(1);
  }
  function enableRunReport() {
    return (
      (inputValues.searchIds === "" &&
        inputValues.productType.arn === "" &&
        inputValues.statusType.label === "" &&
        inputValues.chargedType.value === "" &&
        inputValues.from === null &&
        inputValues.to === null &&
        inputValues.env.value === "" &&
        inputValues.appName.label === "") ||
      error.ToError !== "" ||
      error.fromError !== ""
    );
  }

  const scroll = () => {
    dataRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useTitle("Zoop Customer Platform | Reports");

  // To get custom date range from the CustomDatepicker component
  const setCustomDateRange = (date: any) => {
    setShowCustomDatePicker(false);
    // Formatting the date in YYYY-MM-DD format
    const toFormateDate =
      date.to.year + "-" + date.to.month + "-" + date.to.day;
    const fromFromateDate =
      date.from.year + "-" + date.from.month + "-" + date.from.day;

    const toDate = new Date(toFormateDate);
    const fromDate = new Date(fromFromateDate);

    // To get the placeholder string from function - set the string in state
    setCustomDurationPlaceholder(
      `${format(fromDate, "dd/MM/yyyy")} - ${format(toDate, "dd/MM/yyyy")}`
    );

    setInputValues((inputValues) => ({
      ...inputValues,
      to: toDate as any,
      from: fromDate as any,
    }));
    setError((error) => ({
      ...error,
      fromError: "",
      ToError: "",
    }));
    setValidData((validData) => ({
      ...validData,
      to: true,
      from: true,
    }));
  };

  const cancelCustomDateRange = (val: boolean) => {
    setInputValues({ ...inputValues });
    setShowCustomDatePicker(val);
  };

  const handleEnvironment = (selectedEnv: any) => {
    const env: any = envs.find((env) => {
      return env.label === selectedEnv.target.value;
    });
    setInputValues((inputValues) => ({
      ...inputValues,
      env: env,
    }));
    setValidData((validData) => ({
      ...validData,
      env: true,
    }));
  };

  const handleChargeType = (selectedCharge: any) => {
    const selectedValue = chargedCategories.find(
      (category: any) => category.label === selectedCharge.target.value
    );
    setInputValues((inputValues) => ({
      ...inputValues,
      chargedType: selectedValue,
    }));
    setError((error) => ({
      ...error,
      chargedTypeError: "",
    }));
    setValidData((validData) => ({
      ...validData,
      chargedType: true,
    }));
  };

  return (
    <>
      {showCustomDatePicker && (
        <CustomDatePicker
          setShowCustomDatePicker={cancelCustomDateRange}
          setCustomDurationRange={(data: any) => {
            setCustomDateRange(data);
          }}
          maxDateDifference={60}
        />
      )}
      <div
        className={`p-3 pb-5 ${Styles.reportContainer} mx-1`}
        id="report-container"
      >
        {toast.show && (
          <Toast
            message={toast.message}
            type={toast.type}
            click={() =>
              setToast((toast: any) => ({
                ...toast,
                show: false,
              }))
            }
          />
        )}
        <div className="container-fluid pt-1 pr-3 pl-3 pb-0">
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setTimeout(() => scroll(), 120);
              handleRunReport();
            }}
          >
            <div className="row">
              <div className="col-md-12 py-2 h-100 p-0 m-0">
                <div className=" px-3 row">
                  <div className="p-3 col-md-5">
                    <input
                      className={`${Styles.searchId}`}
                      type="text"
                      name="searchIds"
                      placeholder="Search by Search ID.."
                      value={inputValues.searchIds}
                      onChange={(event: any) => {
                        const value = event.target.value;
                        setInputValues((inputValues) => ({
                          ...inputValues,
                          searchIds: value,
                        }));
                        setError((error) => ({
                          ...error,
                          searchIdError: "",
                        }));
                        setValidData((validData) => ({
                          ...validData,
                          searchId: true,
                        }));
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  "row pl-5 w-100 d-flex justify-content-between align-items-center"
                }
              >
                <p className={`mb-0 px-2 d-flex ${Styles.filterByText}`}>
                  Filter By
                </p>

                <div className={`d-flex ${Styles.runReportContainer}`}>
                  <Button
                    size={MEDIUM}
                    onClick={getInitialState}
                    hoveredStyle={linkButtonHoverStyleReport}
                    style={linkButtonStyleReport}
                    type="button"
                    btnTextClass="primary-color"
                    disabled={reportIsRunning}
                  >
                    Clear all
                  </Button>
                </div>
              </div>

              <div className="col-md-12 px-3">
                <div className={`${Styles.card}`}>
                  <div className="row px-4 py-2">
                    <div className="col-md-4 p-3">
                      <InputDropdown
                        no_shadow="true"
                        labelname="Products"
                        id="z-report-products-select"
                        hasError={error.productTypeError}
                        isValid={validData.productType}
                        optionsArray={allProducts.map(
                          (product: any) => product.name
                        )}
                        default_value={inputValues.productType.name}
                        click={(value: any) => {
                          const selectedProduct: any = allProducts.find(
                            (product: any) => product.name === value
                          );
                          setInputValues((inputValues) => ({
                            ...inputValues,
                            productType: selectedProduct,
                          }));
                          setError((error) => ({
                            ...error,
                            productTypeError: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            productType: true,
                          }));
                        }}
                        resetSelection={() => {
                          setInputValues((inputValues) => ({
                            ...inputValues,
                            productType: {
                              name: "",
                              arn: "",
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="col-md-4 p-3">
                      <InputDropdown
                        no_shadow="true"
                        labelname="App Name"
                        id="z-report-app-name"
                        hasError={error.appNameError}
                        isValid={validData.appName}
                        optionsArray={appNames.map(
                          (appName: any) => appName.name
                        )}
                        default_value={inputValues.appName.label}
                        click={(selectedAppName: any) => {
                          const app: any = appNames.find(
                            (appName: any) => appName.name === selectedAppName
                          );
                          setInputValues((inputValues) => ({
                            ...inputValues,
                            appName: {
                              ...inputValues.appName,
                              label: app.name,
                              value: app._id,
                            },
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            appName: true,
                          }));
                        }}
                        resetSelection={() => {
                          setInputValues((inputValues) => ({
                            ...inputValues,
                            appName: {
                              label: "",
                              value: "",
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="col-md-4 p-3">
                      <InputDropdown
                        no_shadow="true"
                        labelname="Status"
                        id="z-report-status"
                        hasError={error.statusError}
                        isValid={validData.status}
                        optionsArray={statusCategories.map(
                          (category: any) => category.label
                        )}
                        default_value={inputValues.statusType.label}
                        click={(value: any) => {
                          const selectedValue = statusCategories.find(
                            (category: any) => category.label === value
                          );
                          setInputValues((inputValues) => ({
                            ...inputValues,
                            statusType: selectedValue,
                          }));
                          setError((error) => ({
                            ...error,
                            statusError: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            status: true,
                          }));
                        }}
                        resetSelection={() => {
                          setInputValues((inputValues) => ({
                            ...inputValues,
                            statusType: {
                              label: "",
                              value: "",
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="col-md-4 p-3">
                      <Input
                        type="text"
                        clear={false}
                        labelname="Duration"
                        id="z-report-duration"
                        isMandatory={!inputValues.searchIds}
                        placeholder="Select Duration"
                        value={customDurationPlaceholder}
                        onClick={() => setShowCustomDatePicker(true)}
                        autocomplete={"off"}
                        onChange={() => {}}
                      />
                      {(error.fromError || error.ToError) && (
                        <ErrorMessage>
                          {error.fromError || error.ToError}
                        </ErrorMessage>
                      )}
                    </div>
                    <div className="col-md-4 p-3">
                      <p className={`${Styles.formLabel} m-0 pb-3`}>
                        Charge Type
                      </p>
                      <div className="row mt-1">
                        <div className="col-5">
                          <label
                            className={`mb-0 ${Styles.textRadio} ${
                              inputValues.chargedType.value === "BILLABLE"
                                ? Styles.selectedRadio
                                : null
                            }`}
                          >
                            <input
                              type="radio"
                              value="Billable"
                              checked={
                                inputValues.chargedType.value === "BILLABLE"
                              }
                              onChange={handleChargeType}
                              className={`mr-2 `}
                              style={{ cursor: "pointer" }}
                            />
                            Billable
                          </label>
                        </div>
                        <div className="col-7">
                          <label
                            className={`mb-0 ${Styles.textRadio} ${
                              inputValues.chargedType.value === "NON_BILLABLE"
                                ? Styles.selectedRadio
                                : null
                            }`}
                          >
                            <input
                              type="radio"
                              value="Non Billable"
                              checked={
                                inputValues.chargedType.value === "NON_BILLABLE"
                              }
                              onChange={handleChargeType}
                              className={`mr-2 `}
                              style={{ cursor: "pointer" }}
                            />
                            Non-Billable
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 p-3">
                      <p className={`${Styles.formLabel} m-0 pb-3`}>
                        Environment
                      </p>
                      <div className="row mt-1">
                        <div className="col-5">
                          <label
                            className={`mb-0 ${Styles.textRadio} ${
                              inputValues.env.value === "TEST"
                                ? Styles.selectedRadio
                                : null
                            }`}
                          >
                            <input
                              type="radio"
                              value="Test"
                              checked={inputValues.env.value === "TEST"}
                              onChange={handleEnvironment}
                              className={`mr-2 `}
                              style={{ cursor: "pointer" }}
                            />
                            Test
                          </label>
                        </div>
                        <div className="col-7">
                          <label
                            className={`mb-0 ${Styles.textRadio} ${
                              inputValues.env.value === "PRODUCTION"
                                ? Styles.selectedRadio
                                : null
                            }`}
                          >
                            <input
                              type="radio"
                              value="Production"
                              checked={inputValues.env.value === "PRODUCTION"}
                              onChange={handleEnvironment}
                              className={`mr-2 `}
                              style={{ cursor: "pointer" }}
                            />
                            Production
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3 pr-0 pb-0">
              <div
                className={`align-items-center d-flex justify-content-end w-100 ${Styles.resetContainer}`}
              >
                <div className={`${Styles.runReportContainer} pr-5`}>
                  <Button
                    size={MEDIUM}
                    hoveredStyle={primaryButtonHoverStyleReport}
                    style={primaryButtonStyleReport}
                    disabled={enableRunReport() || reportIsRunning}
                    type="submit"
                  >
                    Run Report
                  </Button>
                </div>
              </div>
              <div ref={dataRef}></div>
            </div>
          </form>
        </div>
        <>
          <div className={`px-3 ${Styles.resultSetText}`}>
            Result Set{" "}
            {!!reportData.length && (
              <span className={`${Styles.totalReportCount}`}>
                (Total: {reportData.length})
              </span>
            )}
          </div>

          {!reportData.length ? (
            <div className={`m-3 p-3 ${Styles.emptyResultSet}`}>
              <p
                className={`d-flex justify-content-center ${Styles.resultSetDescription}`}
              >
                <ReportResultFileSvg />
                Search/Filter parameters and “Run report” to generate the result
                set
              </p>
            </div>
          ) : (
            <div className="p-3">
              <div className={`${Styles.card} border-0`}>
                <table className={`${Styles.resultTable} border-0 h-100`}>
                  <tr className={`${Styles.resultTableHeader} border-0 `}>
                    <th className="px-3">ID</th>
                    <th className="px-3">Product</th>
                    <th className="px-3">Product Version</th>
                    <th className="px-3">Request Timestamp</th>
                    <th className="px-3">Response Timestamp</th>
                    <th className="px-3">Response Code</th>
                    <th className="px-3">Status</th>
                    <th className="px-3">Environment</th>
                    <th className="px-3">Task ID</th>
                    <th className="px-3">App ID</th>
                    <th className="px-3">Group ID</th>
                    <th className="px-3">IP Address</th>
                  </tr>
                  {reportData.map((report: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        className={`w-100 border-0 ${Styles.resultTableRow} ${
                          index % 2 ? Styles.resultTableEvenRow : null
                        }`}
                      >
                        <td className="px-3">{report.id}</td>
                        <td className="px-3">{report.product}</td>
                        <td className="px-3">{report.product_version}</td>
                        <td className="px-3">{report.request_timestamp}</td>
                        <td className="px-3">{report.response_timestamp}</td>
                        <td className="px-3">{report.response_code}</td>
                        <td className="px-3">{report.status}</td>
                        <td className="px-3">{report.env}</td>
                        <td className="px-3">{report.task_id}</td>
                        <td className="px-3">{report.app_id}</td>
                        <td className="px-3">{report.group_id}</td>
                        <td className="px-3">{report.client_ip}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default Report;
