import React, { useRef } from "react";
import otpVerificationNew from "../../../assets/images/otpVerificationNew.png";
import Button from "../../button/button";
import { MEDIUM } from "../../buttonSize";
import Styles from "./createZpin.module.scss";
import {
  disabledButtonStylePopup,
  primaryButtonStylePopup,
} from "../../buttonStyles";
import ErrorMessage from "../../error-message/errorMessage";
import { checkIsEmpty, isValidZpin } from "../../../utils/validation";

function CreateZpin({ getZpin, onCancel, isLoading }: any) {
  const lengthOfZpin = 4;
  const [zpin, setZpin] = React.useState("");
  const [confirmZpin, setConfirmZpin] = React.useState("");
  const inputs: any = Array(lengthOfZpin)
    .fill(null)
    .map((_, index) => index);
  const confirm_inputs: any = Array(lengthOfZpin)
    .fill(null)
    .map((_, index) => index);
  const inputRef = useRef<any>([]);
  const confirm_inputRef = useRef<any>([]);

  let currentActiveInputForNormal = 0;
  currentActiveInputForNormal = 0;
  let currentActiveInputForConfirm = 0;
  currentActiveInputForConfirm = 0;
  const [errorForConfirm, setErrorForConfirm] = React.useState("");
  const [errorForCreate, setErrorForCreate] = React.useState("");
  const [errorForBoth, setErrorForBoth] = React.useState("");

  const [validZpin, setValidZpin] = React.useState(false);
  const [invalidZpinForCreate, setInvalidZpinForCreate] = React.useState(false);
  const [invalidZpinForConfirm, setInvalidZpinForConfirm] = React.useState(
    false
  );
  const [invalidZpinForBoth, setInvalidZpinForBoth] = React.useState(false);

  function compareZpins(e: any) {
    e.preventDefault();
    if (checkIsEmpty(zpin) || checkIsEmpty(confirmZpin))
      setInvalidZpinForBoth(true);

    if (zpin !== confirmZpin) {
      setErrorForBoth("Zpins do not match, please retry!");
      return setInvalidZpinForBoth(true);
    }

    getZpin(zpin);
  }

  function handleZpin(
    zPinValue: any,
    index: number,
    ref: any,
    zpin_type: string,
    setZpin: any,
    currentActiveInput: number
  ) {
    setInvalidZpinForCreate(false);
    setInvalidZpinForConfirm(false);
    setInvalidZpinForBoth(false);
    // To check whether ZPIN is numeric or not
    if (!isValidZpin(zPinValue) && zPinValue !== "") {
      ref.current[index].value = "";

      if (zpin_type === zpin) {
        setErrorForCreate("OTP should be numeric");
        setInvalidZpinForCreate(true);
      } else {
        setErrorForConfirm("OTP should be numeric");
        setInvalidZpinForConfirm(true);
      }
      return;
    }
    // To check whether the user is removing the entered ZPIN.
    if (zPinValue === "") {
      currentActiveInput = index - 1;
      const currentInput = ref.current[currentActiveInput];
      // To check whether the focused input is not less than 0
      if (currentActiveInput < 0) {
        return;
      }
      // To check whether the user is clearing the last input
      // if clears the last input than we remove two values else one
      if (zpin_type.length === lengthOfZpin) {
        // const nextInput = ref.current[currentActiveInput + 1];
        const str = zpin_type.substr(0, zpin_type.length - 2);
        setZpin(str);
      } else {
        const str = zpin_type.substr(0, zpin_type.length - 1);
        setZpin(str);
      }
      // Remove disable state.
      currentInput.disabled = false;
      currentInput.value = "";
    } else {
      // Only accepts 4 digits in ZPIN
      if (zpin_type.length > lengthOfZpin - 1) {
        return;
      }
      setZpin((zpin: string) => `${zpin + zPinValue}`);
      const currentInput = ref.current[index];
      // Add disable state once input is filled.
      zpin_type.length < lengthOfZpin - 1 && (currentInput.disabled = true);
      currentActiveInput = index + 1;
    }
    // To check the verify button enable and disable
    if (
      (zpin.length === lengthOfZpin &&
        confirmZpin.length === lengthOfZpin - 1) ||
      (zpin.length === lengthOfZpin - 1 && confirmZpin.length === lengthOfZpin)
    ) {
      setValidZpin(true);
    } else {
      setValidZpin(false);
    }
    setErrorForCreate("");
    setErrorForConfirm("");
    setErrorForBoth("");
    const nextInput = ref.current[currentActiveInput];
    // To Check whether the focus does not exceed the number of input
    // else throw error of index out of bound
    if (currentActiveInput < lengthOfZpin && currentActiveInput >= 0) {
      nextInput.focus();
    }
  }

  return (
    <>
      <form>
        <div className="py-3 text-center">
          <img
            src={otpVerificationNew}
            alt="otp-verification"
            style={{ width: "100px" }}
          />
        </div>
        <div className="py-2 px-4">
          <p className={`mb-0 ${Styles.subText}`}>
            Create a 4-digit ZPIN to make your dashboard experience smoother and
            faster.
          </p>
        </div>
        <div className="px-4">
          <p className={`mb-0 ${Styles.headingText}`}>Choose 4-digit ZPIN</p>
        </div>
        <div style={{ width: "300px", margin: "0 auto" }}>
          <div className="py-2 d-flex align-items-center justify-content-center">
            {inputs.map((input: any, index: number) => {
              return (
                <div key={input} className="px-1">
                  <input
                    ref={(input) => (inputRef.current[index] = input)}
                    id={`z-pin-${index}`}
                    type="password"
                    className={`${Styles.commonInputClass} ${
                      Styles.formControl
                    } ${
                      invalidZpinForCreate || invalidZpinForBoth
                        ? Styles.wrongOtp
                        : ""
                    }`}
                    maxLength={1}
                    autoComplete="off"
                    autoFocus={currentActiveInputForNormal === index}
                    style={{ width: "40px", top: 50, left: 50 }}
                    onKeyUp={(event: any) => {
                      const zpinValue = event.target.value;
                      handleZpin(
                        zpinValue,
                        index,
                        inputRef,
                        zpin,
                        setZpin,
                        currentActiveInputForNormal
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="px-1 d-flex align-items-center justify-content-center flex-column">
            {errorForCreate && <ErrorMessage>{errorForCreate}</ErrorMessage>}
          </div>
        </div>
        <div className="py-1 px-4">
          <p className={`mb-0 ${Styles.headingText}`}>Confirm ZPIN</p>
        </div>
        <div style={{ width: "300px", margin: "0 auto" }}>
          <div
            className={"py-2 d-flex align-items-center justify-content-center"}
          >
            {confirm_inputs.map((input: any, index: number) => {
              return (
                <div key={input} className="px-1">
                  <input
                    ref={(input) => (confirm_inputRef.current[index] = input)}
                    id={`z-pin-2-${index}`}
                    type="password"
                    className={`${Styles.commonInputClass} ${
                      Styles.formControl
                    } ${
                      invalidZpinForConfirm || invalidZpinForBoth
                        ? Styles.wrongOtp
                        : ""
                    }`}
                    maxLength={1}
                    autoComplete="off"
                    style={{ width: "40px", top: 50, left: 50 }}
                    onKeyUp={(event: any) => {
                      const zpinValue = event.target.value;
                      handleZpin(
                        zpinValue,
                        index,
                        confirm_inputRef,
                        confirmZpin,
                        setConfirmZpin,
                        currentActiveInputForConfirm
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="px-1 d-flex align-items-center justify-content-center flex-column">
            {(errorForConfirm || errorForBoth) && (
              <ErrorMessage>
                {errorForConfirm ? errorForConfirm : errorForBoth}
              </ErrorMessage>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center py-4 px-4">
          <div className="px-2">
            <Button
              type="submit"
              style={
                validZpin ? primaryButtonStylePopup : disabledButtonStylePopup
              }
              hoveredStyle={
                validZpin ? primaryButtonStylePopup : disabledButtonStylePopup
              }
              isLoading={isLoading}
              disabled={!validZpin}
              onClick={(e: any) => {
                compareZpins(e);
              }}
              size={MEDIUM}
            >
              Send OTP
            </Button>
          </div>
        </div>

        <div className="py-3 d-flex justify-content-center">
          <div className="d-inline-flex">
            <p
              className={`mb-0 ${Styles.cancelText}`}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateZpin;
