import React from "react";

function DocsSVG({ classes }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      width="20"
      height="20"
      viewBox="0 0 27.324 34.914"
    >
      <g id="docs_icon" data-name="docs icon" transform="translate(-55.652)">
        <g
          id="Group_101"
          data-name="Group 101"
          transform="translate(73.868 .667)"
        >
          <g id="Group_100" data-name="Group 100">
            <path
              id="Path_402"
              d="M322.783 9.783v8.441h8.441z"
              data-name="Path 402"
              transform="translate(-322.783 -9.783)"
            />
          </g>
        </g>
        <g id="Group_103" data-name="Group 103" transform="translate(55.652)">
          <g id="Group_102" data-name="Group 102">
            <path
              id="Path_403"
              d="M72.729 11.385a1.139 1.139 0 0 1-1.139-1.139V0h-14.8a1.139 1.139 0 0 0-1.139 1.139v32.636a1.139 1.139 0 0 0 1.139 1.139h25.047a1.139 1.139 0 0 0 1.139-1.139v-22.39zm-2.277 16.7h-9.108a1.139 1.139 0 1 1 0-2.277h9.108a1.139 1.139 0 0 1 0 2.277zm6.831-4.554H61.344a1.139 1.139 0 1 1 0-2.277h15.939a1.139 1.139 0 0 1 0 2.277zm0-4.554H61.344a1.139 1.139 0 1 1 0-2.277h15.939a1.139 1.139 0 0 1 0 2.277z"
              data-name="Path 403"
              transform="translate(-55.652)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DocsSVG;
