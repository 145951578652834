import crypto from "crypto";
const algorithm = "aes-256-cbc";
const iv = Buffer.from(process.env.REACT_APP_IV_KEY || "", "hex");
const secretkey = Buffer.from(process.env.REACT_APP_SECRET_KEY || "", "hex");

function encryptObject(data: any) {
  const cipher = crypto.createCipheriv(algorithm, secretkey, iv);
  let encrypted = cipher.update(JSON.stringify(data), "utf8", "hex");
  encrypted += cipher.final("hex");
  return encrypted;
}

function encryptField(data: any) {
  const cipher = crypto.createCipheriv(algorithm, secretkey, iv);
  let encrypted = cipher.update(data, "utf8", "hex");
  encrypted += cipher.final("hex");
  return encrypted;
}

export { encryptField, encryptObject };
