//UI cards rendered on manageApps screen to show short info about all the apps
// before user wanted to access more info regarding a particular app.
import React from "react";
import Styles from "./manageAppCard.module.scss";
import Tooltip from "../../../../Shared/tooltip/tooltip";
import { SUCCESS, WARNING } from "../../../../Shared/colors";
import TrialButton from "../../trial-center/Shared/trialButton/trialButton";
import Button from "../../../../Shared/button/button";
import { MEDIUM } from "../../../../Shared/buttonSize";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../Shared/buttonStyles";
function ManageAppCard({ click, app }: any) {
  function handelMoreInfoClicked() {
    click();
    return;
  }
  return (
    <div className={`${Styles.card} ${Styles.cardAnimation}`}>
      <div className="py-2 px-1">
        <div className="py-2" style={{ margin: "0 auto" }}>
          <div className={Styles.appLogo}>
            <p className={`mb-0 ${Styles.appInitial}`}>
              {app.name.substr(0, 1).toUpperCase()}
            </p>
            <div
              className={Styles.env}
              style={{ background: app.is_test_app ? WARNING : SUCCESS }}
            >
              <p className={`mb-0 ${Styles.env_text}`}>
                {app.is_test_app ? "Test" : "Live"}
              </p>
            </div>
          </div>
        </div>
        <div className="py-2 text-center">
          <Tooltip placement="bottom" title={app.name}>
            <p className={`mb-0 ${Styles.appName} title_bold_text`}>
              {app.name}
            </p>
          </Tooltip>
          <p className={`mb-0 mt-1 ${Styles.createdAt}`}>{app.createdAt}</p>
        </div>

        <div className="py-2 d-flex align-items-center justify-content-around px-3 mt-4">
          <div className={"px-2" + " " + Styles.moreInfoBtn}>
            <p className={`mb-0 ${Styles.sectionHeader}`}>Keys</p>
            <p className={`mb-0 ${Styles.sectionValue} py-1`}>{app.keys}</p>
          </div>

          <div className={"px-2" + " " + Styles.moreInfoBtn}>
            <p className={`mb-0 ${Styles.sectionHeader}`}>Services</p>
            <p className={`mb-0 ${Styles.sectionValue} py-1`}>
              {app.service_access}
            </p>
          </div>
        </div>

        <div className="py-2 px-3 d-flex align-items-center justify-content-center mt-2">
          <div
            className={`${Styles.buttonContainer} pl-2 d-flex align-items-center justify-content-around 
            
            `}
          >
            {app.is_test_app ? (
              <TrialButton
                linkTo="/application/trial-center"
                className={Styles.tryNowButton}
                style={{
                  width: "100px",
                  borderRadius: "8px",
                  fontWeight: "500",
                }}
              >
                Trial
              </TrialButton>
            ) : (
              <Tooltip
                placement="bottom"
                title={"Trial is not available on Live app"}
                className={"customTooltip"}
              >
                <Button
                  size={MEDIUM}
                  onClick={app.is_test_app && handelMoreInfoClicked}
                  hoveredStyle={primaryButtonHoverStyle}
                  style={primaryButtonStyle}
                  type="button"
                  className={"bg-diable-color" + " " + Styles.moreInfoBtn}
                  // disabled={!app.is_test_app}
                >
                  Trial
                </Button>
              </Tooltip>
            )}

            <Button
              size={MEDIUM}
              onClick={handelMoreInfoClicked}
              hoveredStyle={primaryButtonHoverStyle}
              style={primaryButtonStyle}
              type="submit"
              className={"bg-lite-accent-color" + " " + Styles.moreInfoBtn}
              btnTextClass="primary-color"
            >
              More info
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageAppCard;
