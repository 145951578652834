import React, { useEffect, useRef } from "react";
import Styles from "./inputDropdown.module.scss";
import { SECONDARYCOLOR } from "../colors";
import ArrowDown from "../svg/arrowDown";

function InputDropdown(props: any) {
  const {
    labelname,
    hasError,
    optionsArray,
    click,
    defaultValue = "",
    no_shadow = "false",
    isValid = null,
    default_value = "",
    disabled = false,
    resetValue,
    customInputPlaceHolder,
    className,
    id,
    borderRadius = "false",
    isMandatory = false,
  } = props;

  const [selectedOption, setSelectedOption] = React.useState<any>();
  const [toggleArrow, setToggleArrow] = React.useState<boolean>(false);

  useEffect(() => {
    setSelectedOption(default_value);
  }, [default_value]);

  if (resetValue) setSelectedOption("");

  const dropDownRef: any = useRef();

  const disabledState = (
    <div
      className={`${
        hasError !== ""
          ? Styles.dropdownError
          : isValid
          ? Styles.validDropdown
          : Styles.dropdown
      } ${no_shadow === "true" ? "shadow-none" : ""} d-flex align-items-center`}
      style={{ cursor: "no-drop", opacity: 0.5 }}
    >
      <div className="flex-grow-1">
        <p className={`mb-0 ${Styles.selectedText}`}>
          {defaultValue ? (
            defaultValue
          ) : (
            <span
              style={{
                color: SECONDARYCOLOR,
              }}
            >
              Select Option
            </span>
          )}
        </p>
      </div>
      <div className="ml-auto">
        <div>
          <ArrowDown color={SECONDARYCOLOR} />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    try {
      const observer = new MutationObserver(function (event) {
        const eve: any = event[0];
        if (eve.target.className.includes("show")) {
          setToggleArrow(true);
        } else {
          setToggleArrow(false);
        }
      });

      observer.observe(dropDownRef?.current, {
        attributes: true,
        attributeFilter: ["class"],
        childList: false,
        characterData: false,
      });

      return () => {
        observer.disconnect();
      };
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      {disabled ? (
        disabledState
      ) : (
        <div className="w-100">
          <p className={`${Styles.formLabel} m-0 pb-2`}>
            {labelname}
            {isMandatory && (
              <span className={`${Styles.mandatoryField}`}> *</span>
            )}
          </p>
          <div
            className="dropdown"
            style={{ position: "relative" }}
            id={
              id || "z-input-" + labelname?.toLowerCase()?.split(" ")?.join("-")
            }
            ref={dropDownRef}
          >
            <div
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div
                style={{ borderRadius: borderRadius === "true" ? "8px" : "" }}
                className={`${
                  hasError !== ""
                    ? Styles.dropdownError
                    : isValid
                    ? Styles.validDropdown
                    : Styles.dropdown
                } ${
                  no_shadow === "true" ? "shadow-none" : ""
                } d-flex align-items-center ${className || ""}`}
              >
                <div className="flex-grow-1">
                  <p className={`mb-0 ${Styles.selectedText}`}>
                    {selectedOption ? (
                      customInputPlaceHolder ||
                      selectedOption?.label ||
                      selectedOption
                    ) : (
                      <span
                        style={{ color: SECONDARYCOLOR }}
                        className={Styles.placeholder}
                      >
                        {props.placeholder
                          ? props.placeholder
                          : "Select Option"}
                      </span>
                    )}
                  </p>
                </div>
                <div className={Styles.indicator}>
                  <div className="px-2">
                    <ArrowDown
                      color={SECONDARYCOLOR}
                      style={
                        toggleArrow
                          ? { transform: "rotate(180deg)" }
                          : { transform: "rotate(0deg)" }
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                borderRadius: "8px",
                padding: "10px",
                margin: "5px 0",
                border: 0,
              }}
              className={`dropdown-menu dropdown-menu-right ${Styles.dropdownMenu}`}
              aria-labelledby="dropdownMenuButton"
            >
              {optionsArray.length > 0 ? (
                optionsArray.map((option: any, index: number) => {
                  return (
                    <div key={"Dropdown_Array_" + index}>
                      <div
                        className={
                          selectedOption === option
                            ? Styles.active
                            : Styles.dropdownLink
                        }
                        onClick={() => {
                          setSelectedOption(option);
                          click(option);
                        }}
                      >
                        {typeof option === "object" ? option.label : option}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{ height: "200px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h6 className="mb-0">No option found</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InputDropdown;
