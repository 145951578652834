import React from "react";

function appsSVGNew(props: any) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color}
      {...props}
    >
      <path d="M2.77778 0C2.04107 0 1.33453 0.292658 0.813592 0.813592C0.292658 1.33453 0 2.04107 0 2.77778V11.1111C0 11.8478 0.292658 12.5544 0.813592 13.0753C1.33453 13.5962 2.04107 13.8889 2.77778 13.8889H11.1111C11.8478 13.8889 12.5544 13.5962 13.0753 13.0753C13.5962 12.5544 13.8889 11.8478 13.8889 11.1111V2.77778C13.8889 2.04107 13.5962 1.33453 13.0753 0.813592C12.5544 0.292658 11.8478 0 11.1111 0H2.77778ZM18.0556 4.16667C17.3188 4.16667 16.6123 4.45932 16.0914 4.98026C15.5704 5.50119 15.2778 6.20773 15.2778 6.94444V11.1111C15.2778 11.8478 15.5704 12.5544 16.0914 13.0753C16.6123 13.5962 17.3188 13.8889 18.0556 13.8889H22.2222C22.9589 13.8889 23.6655 13.5962 24.1864 13.0753C24.7073 12.5544 25 11.8478 25 11.1111V6.94444C25 6.20773 24.7073 5.50119 24.1864 4.98026C23.6655 4.45932 22.9589 4.16667 22.2222 4.16667H18.0556ZM15.2778 18.0556C15.2778 17.3188 15.5704 16.6123 16.0914 16.0914C16.6123 15.5704 17.3188 15.2778 18.0556 15.2778H22.2222C22.9589 15.2778 23.6655 15.5704 24.1864 16.0914C24.7073 16.6123 25 17.3188 25 18.0556V22.2222C25 22.9589 24.7073 23.6655 24.1864 24.1864C23.6655 24.7073 22.9589 25 22.2222 25H18.0556C17.3188 25 16.6123 24.7073 16.0914 24.1864C15.5704 23.6655 15.2778 22.9589 15.2778 22.2222V18.0556ZM5.55556 15.2778C4.81884 15.2778 4.1123 15.5704 3.59137 16.0914C3.07044 16.6123 2.77778 17.3188 2.77778 18.0556V22.2222C2.77778 22.9589 3.07044 23.6655 3.59137 24.1864C4.1123 24.7073 4.81884 25 5.55556 25H9.72222C10.4589 25 11.1655 24.7073 11.6864 24.1864C12.2073 23.6655 12.5 22.9589 12.5 22.2222V18.0556C12.5 17.3188 12.2073 16.6123 11.6864 16.0914C11.1655 15.5704 10.4589 15.2778 9.72222 15.2778H5.55556Z" />
    </svg>
  );
}

export default appsSVGNew;
