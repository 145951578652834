import React, { useEffect } from "react";
import Select from "react-select";
import ErrorMessage from "../../../../../Shared/error-message/errorMessage";
import Styles from "../../Shared/sharedStyles.module.scss";
import { days, months } from "../../../../../constants/data";
import dropDownStyles from "../../../../../Shared/dropDownStyles";
import InputField from "../../Shared/inputField/inputField";
import Button from "../../../../../Shared/button/button";
import {
  primaryButtonHoverStylePopup,
  primaryButtonStylePopup,
} from "../../../../../Shared/buttonStyles";
import { MEDIUM } from "../../../../../Shared/buttonSize";
import { validDLNumber, validNameCheck } from "../../../../../utils/validation";
import { getYears } from "../../../../../utils/getYears";

function DlServicePopup({ handleResponse, name }: any) {
  const [day, setDay] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const nameMatchRef = React.useRef<any>("");
  const [isNameMatchEnabled, setIsNameMatchEnabled] = React.useState(false);

  const errorObject = {
    dlNumberError: false,
    consentError: false,
    dobDayError: false,
    dobMonthError: false,
    dobYearError: false,
    nameToMatchError: false,
  };
  const [error, setError] = React.useState({ ...errorObject });
  const [isChecked, setIsChecked] = React.useState(false);

  const dlNumberRef = React.useRef<any>("");
  const yearOptions = getYears(1950);

  useEffect(() => {
    if (isNameMatchEnabled) {
      setIsDisabled(true);
    }
    if (!isNameMatchEnabled) {
      if (dlNumberRef.current.value) {
        setIsDisabled(false);
      }
    }
  }, [isNameMatchEnabled]);

  function isValid() {
    if (validDLNumber(dlNumberRef.current.value.trim().toUpperCase())) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    setError(() => ({
      ...error,
      dlNumberError: true,
    }));
    return setIsDisabled(true);
  }

  function isValidNameMatch() {
    setError({ ...errorObject });
    if (
      validDLNumber(dlNumberRef.current.value.trim().toUpperCase()) &&
      validNameCheck(nameMatchRef.current.value)
    ) {
      setError(errorObject);
      return setIsDisabled(false);
    }
    if (!validNameCheck(nameMatchRef.current.value)) {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
    }
    return setIsDisabled(true);
  }

  function handleCheck() {
    const dlNumber = dlNumberRef.current.value.trim().toUpperCase();
    const dobNeed = ["AP", "TS"];
    const stateNeedDob = dobNeed.some((state) => dlNumber.includes(state));

    if (isNameMatchEnabled && nameMatchRef.current.value === "") {
      setError(() => ({
        ...error,
        nameToMatchError: true,
      }));
      return setIsDisabled(true);
    }

    if (!day && stateNeedDob) {
      setError(() => ({
        ...error,
        dobDayError: true,
      }));
      return false;
    }
    if (!month && stateNeedDob) {
      setError(() => ({
        ...error,
        dobMonthError: true,
      }));
      return false;
    }
    if (!year && stateNeedDob) {
      setError(() => ({
        ...error,
        dobYearError: true,
      }));
      return false;
    } else if (!isChecked) {
      setError(() => ({
        ...error,
        consentError: true,
      }));
      return false;
    }

    const dob = `${day}-${month}-${year}`;
    const consent = isChecked === true ? "Y" : "N";
    setLoading(true);
    if (nameMatchRef && nameMatchRef.current && nameMatchRef.current.value) {
      const nameToMatch = nameMatchRef.current.value;
      handleResponse({ dlNumber, nameToMatch, dob, consent }, setLoading);
    } else if (!isNameMatchEnabled) {
      handleResponse({ dlNumber, dob, consent }, setLoading);
    }
  }
  return (
    <div className={`${Styles.content}`}>
      <div className={`${Styles.trialText} pl-2`}>Runnning Trial on</div>
      <div className={`${Styles.headingText} pl-2`}>{name} </div>
      <div className={`${Styles.infoForm} pl-2`}>
        <hr />
        <p className="mt-4">Fill in the below details to run verification</p>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-4 ">
            <label className={`${Styles.label} flex-grow-1`}>DL Number</label>
            <InputField
              onChange={isValid}
              maxLength={20}
              minLength={10}
              inputRef={dlNumberRef}
              isError={error.dlNumberError}
              purpose="forDl"
              id="id-input-dl"
            />
            {error.dlNumberError && (
              <ErrorMessage>Please enter a valid DL number</ErrorMessage>
            )}
          </div>
          <div>
            <label className={`${Styles.label}`}>DOB</label>
            <div className="d-flex mr-1">
              <div className="mr-2 d-flex">
                <div className="d-flex flex-column">
                  <Select
                    id="id-input-day"
                    onChange={(e: any) => setDay(e.value)}
                    options={days}
                    styles={
                      !error.dobDayError
                        ? dropDownStyles.customStylesForDays
                        : dropDownStyles.customStylesForDaysError
                    }
                    placeholder="DD"
                  />
                </div>
                <div className="ml-2 d-flex flex-column">
                  <Select
                    id="id-input-month"
                    onChange={(e: any) => setMonth(e.value)}
                    options={months}
                    styles={
                      !error.dobMonthError
                        ? dropDownStyles.customStylesForMonths
                        : dropDownStyles.customStylesForMonthsError
                    }
                    placeholder="MMM"
                  />
                </div>
                <div className="ml-2 d-flex flex-column">
                  <Select
                    onChange={(e: any) => setYear(e.value)}
                    id="id-input-year"
                    placeholder="YYYY"
                    options={yearOptions}
                    styles={
                      !error.dobYearError
                        ? dropDownStyles.customStylesForYears
                        : dropDownStyles.customStylesForYearsError
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pl-4 pb-2 pt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => setIsNameMatchEnabled(e.target.checked)}
              />
              <p className={`${Styles.ackText}`}>is name match required ??</p>
            </div>
          </div>
          {isNameMatchEnabled && (
            <div className="d-flex flex-column mb-4 ">
              <label className={`${Styles.label} flex-grow-1`}>
                Name To Match
              </label>
              <InputField
                onChange={isValidNameMatch}
                inputRef={nameMatchRef}
                isError={error.nameToMatchError}
                purpose="forPassport"
                text="lowerCase"
              />
              {error.nameToMatchError && (
                <ErrorMessage>Please enter a valid Name</ErrorMessage>
              )}
            </div>
          )}

          <div className="pt-4 pl-4 pb-4 mt-4 d-flex flex-column">
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e: any) => {
                  setIsChecked(e.target.checked);
                  isNameMatchEnabled ? isValidNameMatch() : isValid();
                }}
              />
              <p className={`${Styles.ackText}`}>
                I hearby agree, to let zoop.one verify my data for verification
              </p>
            </div>
            {error.consentError && (
              <ErrorMessage>Please provide consent!</ErrorMessage>
            )}
          </div>
          <div className={`${Styles.buttonGroup}`}>
            <Button
              id="id-submit-button-dl"
              hoveredStyle={primaryButtonHoverStylePopup}
              disabled={isDisabled}
              size={MEDIUM}
              isLoading={loading}
              style={primaryButtonStylePopup}
              onClick={() => {
                setTimeout(() => setError({ ...errorObject }), 2000);
                handleCheck();
              }}
            >
              Run Verification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DlServicePopup;
