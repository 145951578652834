import React from "react";

function darkLogoSVG() {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.192 12.5666C25.1165 19.4553 19.4993 25 12.596 25C5.69275 25 0.0755041 19.4553 0 12.5666C0 12.3771 0 12.1892 0.0120557 12.0012L5.29851 17.2768C7.48951 19.4631 11.0417 19.4631 13.2327 17.2768L23.7695 6.76172C24.7065 8.55366 25.1945 10.5454 25.192 12.5666Z"
        fill="black"
      />
      <path
        d="M20.8203 3.049L10.4644 13.3836C10.1461 13.7014 9.71432 13.88 9.2641 13.88C8.81381 13.88 8.38202 13.7014 8.06381 13.3836L1.42407 6.75753C3.20377 3.35302 6.44496 0.946811 10.2241 0.224533C14.0033 -0.497747 17.906 0.543103 20.8203 3.05051V3.049Z"
        fill="black"
      />
    </svg>
  );
}

export default darkLogoSVG;
