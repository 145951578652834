import React, { useEffect, useState } from "react";
import style from "./paymentStatusCallback.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { callApi } from "../../../../api/fetch";
import Loading from "../../../../Shared/loading/loading";
import { ACCENTCOLOR } from "../../../../Shared/colors";
import { PaymentStatus } from "../../../../constants/orderTypes";
import { ReactComponent as SuccessIcon } from "../../../../assets/images/success_ico.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/error_state.svg";
import { ReactComponent as Processing } from "../../../../assets/images/processing.svg";

const PaymentStatusCallback = () => {
  const history = useHistory();
  const location = useLocation();

  const [paymentData, setPaymentData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const onGoBack = () => {
    history.replace("/application/billing");
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const linkId = query.get("link_id");
    if (linkId) {
      fetchData(linkId);
    }
  }, [location]);

  const fetchData = async (linkId: string) => {
    try {
      const response = await callApi("/payment/fetch/" + linkId, {
        method: "GET",
      });

      if (response.generated_link_id) {
        setPaymentData(response);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    return;
  };

  const renderCompoenents = () => {
    if (paymentData?.status === PaymentStatus.PAID) {
      return <SuccessTxn onGoBack={onGoBack} paymentData={paymentData} />;
    }

    if (
      paymentData?.status == PaymentStatus.ERROR ||
      paymentData?.status == PaymentStatus.CANCELLED ||
      paymentData?.status == PaymentStatus.EXPIRED
    ) {
      return (
        <ErrorTxn
          onGoBack={onGoBack}
          paymentData={paymentData}
          message="Transaction failed"
          subMessage="Your transaction failed at the server end. Please try to make the payment again"
        />
      );
    }

    if (
      paymentData?.status == PaymentStatus.ACTIVE ||
      paymentData?.generated_link_id
    ) {
      return <ActiveTxn onGoBack={onGoBack} paymentData={paymentData} />;
    }

    return onGoBack();
  };

  return (
    <main
      className={
        "container d-flex justify-content-center align-items-center vh-100"
      }
    >
      <div className={"text-center " + style.response_container}>
        {loading ? (
          <Loading loadingColor={ACCENTCOLOR} width="14px" height="14px" />
        ) : (
          renderCompoenents()
        )}
      </div>
    </main>
  );
};

export default PaymentStatusCallback;

const SuccessTxn = ({ onGoBack, paymentData }: any) => {
  return (
    <>
      <div>
        <SuccessIcon></SuccessIcon>
      </div>
      <div className={style.txn_title_text + " my-4 " + style.success}>
        Transaction Successful
      </div>
      <div className={style.txn_success_sub + " my-4"}>
        Your recharge of{" "}
        <span className="font-weight-bold">₹{paymentData?.amount}</span> was
        processed successfully
      </div>
      <div className={style.txn_id + " my-4"}>
        Transaction ID: {paymentData?.order_id}
      </div>
      <button className={style.go_back} onClick={onGoBack}>
        GO BACK TO BILLING
      </button>
    </>
  );
};

const ErrorTxn = ({ onGoBack, message, subMessage }: any) => {
  return (
    <>
      <div>
        <ErrorIcon></ErrorIcon>
      </div>
      <div className={style.txn_title_text + " my-4 " + style.error}>
        {message}
      </div>
      <div className={style.txn_success_sub + " my-4"}>{subMessage}</div>
      <button className={style.go_back} onClick={onGoBack}>
        GO BACK TO BILLING
      </button>
    </>
  );
};

const ActiveTxn = ({ onGoBack }: any) => {
  return (
    <>
      <div>
        <Processing></Processing>
      </div>
      <div className={style.txn_title_text + " my-4 " + style.processing}>
        Fetching your transaction status
      </div>
      <div className={style.processing_sub + " my-4"}>
        It usually takes 2-3 seconds to update the status. Kindly wait till we
        get an update
      </div>
      <button className={style.go_back} onClick={onGoBack}>
        GO BACK TO BILLING
      </button>
    </>
  );
};
