import React from "react";

function questionMarkSVG(classes: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path
        d="M10.2467 6.57896C9.24492 6.31955 8.22255 6.92133 7.96313 7.9231C7.91136 8.12373 8.03203 8.32835 8.23267 8.38013C8.4333 8.4319 8.63792 8.31123 8.6897 8.1106C8.78473 7.74068 9.06104 7.44427 9.42336 7.32347C10.0127 7.127 10.6497 7.44551 10.8461 8.03479C11.0426 8.62411 10.7241 9.26109 10.1348 9.45752C9.68204 9.62575 9.38628 10.0631 9.39868 10.5459V11.0173C9.39877 11.2243 9.56668 11.392 9.77368 11.3918H9.77409C9.98109 11.3917 10.1488 11.2238 10.1487 11.0168V10.5459C10.1385 10.3848 10.2292 10.2342 10.3765 10.168C10.9756 9.96564 11.4323 9.47469 11.5908 8.86247C11.8502 7.86075 11.2484 6.83838 10.2467 6.57896ZM9.77354 12.048C9.51463 12.048 9.30479 12.2579 9.30479 12.5168C9.30479 12.7757 9.51463 12.9855 9.77354 12.9855C10.0324 12.9855 10.2423 12.7757 10.2423 12.5168C10.2423 12.2579 10.0324 12.048 9.77354 12.048ZM9.80005 2C5.65793 2 2.30005 5.35788 2.30005 9.5C2.30005 13.6421 5.65793 17 9.80005 17C13.9402 16.9951 17.2952 13.6401 17.3 9.5C17.3 5.35788 13.9422 2 9.80005 2ZM9.80005 16.25C6.07211 16.25 3.05005 13.2279 3.05005 9.5C3.05005 5.77206 6.07211 2.75 9.80005 2.75C13.5263 2.75407 16.546 5.77376 16.55 9.5C16.55 13.2279 13.528 16.25 9.80005 16.25Z"
        fill="#5E5E5E"
        stroke="#5E5E5E"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default questionMarkSVG;
