import React from "react";

const DownloadSvg = ({ fillBackgroundColor }: any) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9997 8.83334C12.8229 8.83334 12.6533 8.90358 12.5283 9.02861C12.4032 9.15363 12.333 9.3232 12.333 9.50001V12.1667C12.333 12.3435 12.2628 12.5131 12.1377 12.6381C12.0127 12.7631 11.8432 12.8333 11.6663 12.8333H2.33301C2.1562 12.8333 1.98663 12.7631 1.8616 12.6381C1.73658 12.5131 1.66634 12.3435 1.66634 12.1667V9.50001C1.66634 9.3232 1.5961 9.15363 1.47108 9.02861C1.34605 8.90358 1.17649 8.83334 0.999674 8.83334C0.822863 8.83334 0.653294 8.90358 0.52827 9.02861C0.403246 9.15363 0.333008 9.3232 0.333008 9.50001V12.1667C0.333008 12.6971 0.543722 13.2058 0.918794 13.5809C1.29387 13.956 1.80257 14.1667 2.33301 14.1667H11.6663C12.1968 14.1667 12.7055 13.956 13.0806 13.5809C13.4556 13.2058 13.6663 12.6971 13.6663 12.1667V9.50001C13.6663 9.3232 13.5961 9.15363 13.4711 9.02861C13.3461 8.90358 13.1765 8.83334 12.9997 8.83334ZM6.52634 9.97334C6.58974 10.034 6.66451 10.0816 6.74634 10.1133C6.82614 10.1486 6.91243 10.1668 6.99967 10.1668C7.08692 10.1668 7.17321 10.1486 7.25301 10.1133C7.33484 10.0816 7.40961 10.034 7.47301 9.97334L10.1397 7.30668C10.2652 7.18114 10.3357 7.01088 10.3357 6.83334C10.3357 6.65581 10.2652 6.48555 10.1397 6.36001C10.0141 6.23447 9.84387 6.16395 9.66634 6.16395C9.48881 6.16395 9.31854 6.23447 9.19301 6.36001L7.66634 7.89334V1.50001C7.66634 1.3232 7.5961 1.15363 7.47108 1.02861C7.34605 0.903581 7.17649 0.833344 6.99967 0.833344C6.82286 0.833344 6.65329 0.903581 6.52827 1.02861C6.40325 1.15363 6.33301 1.3232 6.33301 1.50001V7.89334L4.80634 6.36001C4.74418 6.29785 4.67039 6.24854 4.58917 6.2149C4.50796 6.18126 4.42091 6.16395 4.33301 6.16395C4.2451 6.16395 4.15806 6.18126 4.07684 6.2149C3.99563 6.24854 3.92183 6.29785 3.85967 6.36001C3.79752 6.42217 3.74821 6.49596 3.71457 6.57718C3.68093 6.65839 3.66361 6.74544 3.66361 6.83334C3.66361 6.92125 3.68093 7.0083 3.71457 7.08951C3.74821 7.17072 3.79752 7.24452 3.85967 7.30668L6.52634 9.97334Z"
      fill={fillBackgroundColor}
    />
  </svg>
);

export default DownloadSvg;
//"#DDDCDD"
//#747474
//"#3272EC"
