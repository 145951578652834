import React from "react";

function bargraphSVG(classes: any) {
  return (
    <svg
      width="30"
      height="36"
      viewBox="0 0 54 60"
      xmlns="http://www.w3.org/2000/svg"
      {...classes}
    >
      <path
        d="M13.4049 57.5H4.47628C3.88428 57.5 3.31653 57.2366 2.89792 56.7678C2.47931 56.2989 2.24414 55.663 2.24414 55V35C2.24414 34.337 2.47931 33.7011 2.89792 33.2322C3.31653 32.7634 3.88428 32.5 4.47628 32.5H13.4049C13.9969 32.5 14.5646 32.7634 14.9832 33.2322C15.4018 33.7011 15.637 34.337 15.637 35V55C15.637 55.663 15.4018 56.2989 14.9832 56.7678C14.5646 57.2366 13.9969 57.5 13.4049 57.5Z"
        fill="#9ECDFF"
      />
      <path
        d="M31.7977 57.5H22.8691C22.2771 57.5 21.7094 57.2366 21.2907 56.7678C20.8721 56.2989 20.637 55.663 20.637 55V5C20.637 4.33696 20.8721 3.70107 21.2907 3.23223C21.7094 2.76339 22.2771 2.5 22.8691 2.5H31.7977C32.3897 2.5 32.9574 2.76339 33.376 3.23223C33.7946 3.70107 34.0298 4.33696 34.0298 5V55C34.0298 55.663 33.7946 56.2989 33.376 56.7678C32.9574 57.2366 32.3897 57.5 31.7977 57.5Z"
        fill="#0075F3"
      />
      <path
        d="M50.1905 57.5H41.2619C40.6699 57.5 40.1022 57.2366 39.6836 56.7678C39.265 56.2989 39.0298 55.663 39.0298 55V25C39.0298 24.337 39.265 23.7011 39.6836 23.2322C40.1022 22.7634 40.6699 22.5 41.2619 22.5H50.1905C50.7825 22.5 51.3503 22.7634 51.7689 23.2322C52.1875 23.7011 52.4226 24.337 52.4226 25V55C52.4226 55.663 52.1875 56.2989 51.7689 56.7678C51.3503 57.2366 50.7825 57.5 50.1905 57.5Z"
        fill="#66B0FF"
      />
    </svg>
  );
}

export default bargraphSVG;
