import React from "react";
import Styles from "./signUp.module.scss";
import AuthSidebar from "../authSidebar/authSidebar";
import Button from "../../../Shared/button/button";
import Input from "../../../Shared/input/input";
import { useHistory } from "react-router-dom";
import InformativeText from "../informativeText/informativeText";
import Illustration from "../../../assets/images/Verify_login_dashboard_illustration.svg";
import ErrorMessage from "../../../Shared/error-message/errorMessage";
import {
  checkIsEmpty,
  validEmail,
  isValidName,
  isvalidEmailFirstChar,
} from "../../../utils/validation";
import {
  // borderButtonStyle,
  // borderButtonHoverStyle,
  primaryButtonStyle,
  primaryButtonHoverStyle,
} from "../../../Shared/buttonStyles";
import { callApi } from "../../../api/fetch";
import { publicEmail } from "../../../constants/publicEmail";
import Toast from "../../../Shared/toast/toast";
import { LARGE } from "../../../Shared/buttonSize";
import { DANGER } from "../../../Shared/colors";
import { useTitle } from "../../../Shared/useTitle";

function SignUp() {
  const [email, setEmail] = React.useState("");
  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [error, setError] = React.useState({
    firstnameError: "",
    lastnameError: "",
    emailError: "",
    privateEmailError: false,
  });
  const history = useHistory();
  const [isChecked, setIsChecked] = React.useState(false);
  const [consentError, setConsentError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const [validSignUpData, setValidSignUpData] = React.useState({
    validFirstName: false,
    validLastName: false,
    validEmail: false,
  });
  const redirect = (
    <span>
      <a
        href="https://webforms.pipedrive.com/f/1wBPSEtkMEKlaZoOUTCw1vIBLschVjFU88EPXrSuG3ttLO6TjJzKkQVWpenLdbtQf"
        target="_blank"
        rel="noopener noreferrer"
        className={`mb-0 ${Styles.redirectText}`}
      >
        here
      </a>
    </span>
  );

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
  }

  function checkFirstName() {
    if (checkIsEmpty(firstname)) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validFirstName: false,
      }));
      setError((error) => ({
        ...error,
        firstnameError: "First name cannot be empty",
      }));
      return false;
    }
    if (isValidName(firstname, false)) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validFirstName: false,
      }));
      setError((error) => ({
        ...error,
        firstnameError: "should contain alphabets only!",
      }));
      return false;
    }
    // Razorpay validation: 3 - 50 characters
    if (firstname.length < 3) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validFirstName: false,
      }));
      setError((error) => ({
        ...error,
        firstnameError: "must be at least 3 characters long",
      }));
      return false;
    }
    return true;
  }
  function checkLastName() {
    if (checkIsEmpty(lastname)) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validLastName: false,
      }));
      setError((error) => ({
        ...error,
        lastnameError: "Last name cannot be empty",
      }));
      return false;
    }
    if (isValidName(lastname, false)) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validLastName: false,
      }));
      setError((error) => ({
        ...error,
        lastnameError: "should contain alphabets only!",
      }));
      return false;
    }
    // Razorpay validation: 3 - 50 characters
    if (lastname.length < 3) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validLastName: false,
      }));
      setError((error) => ({
        ...error,
        lastnameError: "must be at least 3 characters long",
      }));
      return false;
    }
    return true;
  }
  function checkEmail() {
    if (checkIsEmpty(email)) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validEmail: false,
      }));
      setError((error) => ({
        ...error,
        emailError: "Email cannot be empty",
      }));
      return false;
    }
    if (!validEmail(email)) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validEmail: false,
      }));
      setError((error) => ({
        ...error,
        emailError: "Enter a valid email",
      }));
      return false;
    }
    if (isvalidEmailFirstChar(email)) {
      setValidSignUpData((validSignUpData) => ({
        ...validSignUpData,
        validEmail: false,
      }));
      setError((error) => ({
        ...error,
        emailError: "Email cannot start with a number",
      }));
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, domain] = email.split("@");
    if (publicEmail.includes(domain.toLocaleLowerCase())) {
      setError((error) => ({
        ...error,
        privateEmailError: true,
        emailError: "private email",
      }));
      return false;
    }
    return true;
  }
  const handleConsentChange = () => {
    setIsChecked(!isChecked);
    setConsentError("");
  };

  function checkConsent() {
    if (!isChecked) {
      setConsentError("Please provide consent!");
      return false;
    }
    return true;
  }

  async function handelSignUp(event: any) {
    event.preventDefault();
    const allCheckPassed = [
      checkFirstName(),
      checkLastName(),
      checkEmail(),
      checkConsent(),
    ].every(Boolean);
    if (!allCheckPassed) {
      return;
    }
    setLoading(true);
    try {
      await callApi("/user", {
        method: "POST",
        data: {
          name: `${firstname} ${lastname}`,
          email,
          success_redirect: `${window.location.origin}/create-password`,
          failure_redirect: `${window.location.origin}/email-verification-failed`,
          is_consent_provided: "Y",
        },
      });
      setLoading(false);
      localStorage.setItem("email", email);
      localStorage.setItem("firstname", firstname);
      localStorage.setItem("lastname", lastname);
      return history.replace("/email-verification");
    } catch (err) {
      setLoading(false);
      toastTimeout(true, "error", (err as Error).message);
    }
    return;
  }
  useTitle("Zoop Customer Platform | Sign Up");
  return (
    <div className="container-fluid p-0">
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className="row no-gutters">
        <div className="col-xl-5 d-none d-xl-block">
          <AuthSidebar
            img={<img src={Illustration} alt="illustration" width="200px" />}
            info={<InformativeText headertext="One Identity Platform." />}
          />
        </div>
        <div className="col-xl-7 col-lg-12">
          <button
            onClick={() => (window.location.href = "https://zoop.one")}
            className={`${Styles.visitBtn} ml-2 mt-2 d-flex justify-content-center align-items-center`}
          >
            Visit Zoop.One
          </button>
          <div
            className={`d-flex align-items-center justify-content-center ${Styles.authActionsBackground}`}
          >
            <div className={Styles.formWidth}>
              <form onSubmit={handelSignUp}>
                <div className="form-group mb-0 py-1">
                  <Input
                    type="text"
                    name="firstname"
                    id="firstname-signup-create"
                    autoComplete="off"
                    placeholder="First Name"
                    hasError={error.firstnameError}
                    labelname="First Name"
                    isValid={validSignUpData.validFirstName}
                    maxLength={20}
                    onChange={(event: any) => {
                      setFirstName(event.target.value);
                      setValidSignUpData((validSignUpData) => ({
                        ...validSignUpData,
                        validFirstName: true,
                      }));
                      setError((error) => ({
                        ...error,
                        firstnameError: "",
                      }));
                    }}
                    onBlur={checkFirstName}
                    clear={false}
                  />
                  {error.firstnameError && (
                    <ErrorMessage>{error.firstnameError}</ErrorMessage>
                  )}
                </div>
                <div className="form-group mb-0 py-1">
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname-signup-create"
                    autoComplete="off"
                    placeholder="Last Name"
                    hasError={error.lastnameError}
                    labelname="Last Name"
                    isValid={validSignUpData.validLastName}
                    maxLength={20}
                    onChange={(event: any) => {
                      setLastName(event.target.value);
                      setValidSignUpData((validSignUpData) => ({
                        ...validSignUpData,
                        validLastName: true,
                      }));
                      setError((error) => ({
                        ...error,
                        lastnameError: "",
                      }));
                    }}
                    onBlur={checkLastName}
                    clear={false}
                  />
                  {error.lastnameError && (
                    <ErrorMessage>{error.lastnameError}</ErrorMessage>
                  )}
                </div>
                <div className="form-group mb-0 py-1">
                  <Input
                    type="email"
                    name="email"
                    id="email-signup-create"
                    maxLength={64} // Razorpay validation: max 64 characters
                    autoComplete="off"
                    placeholder="Enter your work email"
                    hasError={error.emailError}
                    isValid={
                      validSignUpData.validEmail && !error.privateEmailError
                    }
                    labelname="Email"
                    onChange={(event: any) => {
                      setEmail(event.target.value);
                      setValidSignUpData((validSignUpData) => ({
                        ...validSignUpData,
                        validEmail: true,
                      }));
                      setError((error) => ({
                        ...error,
                        emailError: "",
                        privateEmailError: false,
                      }));
                    }}
                    onBlur={checkEmail}
                    clear={false}
                  />
                  {error.privateEmailError ? (
                    <p
                      className="mb-0 p-2"
                      style={{ fontSize: "12px", color: DANGER }}
                    >
                      Only work emails are allowed. Submit your request{" "}
                      {redirect}
                    </p>
                  ) : (
                    error.emailError && (
                      <ErrorMessage>{error.emailError}</ErrorMessage>
                    )
                  )}
                </div>
                <div className="pl-3  ml-1 d-flex flex-column">
                  <div>
                    <input
                      style={{
                        position: "relative",
                        top: "22px",
                      }}
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleConsentChange}
                    />
                    <p className={`${Styles.text} mb-0`}>
                      I agree to the{" "}
                      <a
                        href="https://zoop.one/terms-conditions/"
                        target="_blank"
                        className={`${Styles.text2} mb-0`}
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://zoop.one/privacy-policy-2"
                        target="_blank"
                        className={`${Styles.text2} mb-0`}
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </div>
                  {consentError && <ErrorMessage>{consentError}</ErrorMessage>}
                </div>
                <div className="pt-3">
                  <div className="py-2">
                    <Button
                      size={LARGE}
                      hoveredStyle={primaryButtonHoverStyle}
                      type="submit"
                      disabled={loading}
                      isLoading={loading}
                      style={primaryButtonStyle}
                      onClick={() => {
                        checkConsent();
                      }}
                    >
                      Next
                    </Button>
                  </div>
                  {/* <div className="py-3">
                    <Button
                      size={LARGE}
                      hoveredStyle={borderButtonHoverStyle}
                      type="submit"
                      style={borderButtonStyle}
                    >
                      Signup with GSuite Id
                    </Button>
                  </div> */}
                </div>
                <hr />
                <div className="pt-3">
                  <p className={`${Styles.text} mb-0`}>
                    Already have an account ?
                  </p>
                  <div className="d-flex justify-content-center">
                    <p
                      className={`${Styles.signUpText} mb-0`}
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Sign In
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
